import { ClientError } from '../PlutoError';
import { ResponseHeader } from '../Headers';
import { iByteStream } from '../Binary';

class ResolveReject {
  resolve: CallableFunction;
  reject: (reason: Error) => void;

  constructor(resolve: CallableFunction, reject: (reason: Error) => void) {
    this.resolve = resolve;
    this.reject = reject;
  }
}

export class ResponseQueue<Key, Resolve extends CallableFunction> {
  private readonly _queue: Map<Key, ResolveReject> = new Map<Key, ResolveReject>();
  private readonly _activeTimeouts: Map<Key, () => void> = new Map<Key, () => void>();

  constructor(private readonly _timeout: number) {}

  destroy() {
    this._activeTimeouts.forEach((cb) => cb());
  }

  pend(key: Key, resolve: Resolve, reject: (reason: Error) => void) {
    const entry = new ResolveReject((header: ResponseHeader, body: iByteStream) => {
      const closeTimeoutFn = this._activeTimeouts.get(key);
      if (closeTimeoutFn) {
        closeTimeoutFn();
      }
      resolve(header, body);
    }, (x: any) => {
      const closeTimeoutFn = this._activeTimeouts.get(key);
      if (closeTimeoutFn) {
        closeTimeoutFn();
      }
      reject(x);
    });
    this._queue.set(key, entry);
    if (this._timeout !== 0) {
      const timeout = setTimeout(() => {
        this._activeTimeouts.delete(key);
        reject(new ClientError('ResponseQueue timed out'));
      }, this._timeout);
      this._activeTimeouts.set(key, () => clearTimeout(timeout));
    }
  }

  private _removePended() {

  }

  resolve(key: Key, responseHeader: ResponseHeader, ibs: iByteStream): boolean {
    if (!this._queue.has(key)) {
      return false;
    }
    this._queue.get(key)!.resolve(responseHeader, ibs);
    return true;
  }

  reject(key: Key, reason: Error): boolean {
    if (!this._queue.has(key)) {
      return false;
    }
    this._queue.get(key)!.reject(reason);
    return true;
  }
}
