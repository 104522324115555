/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConfirmEmailResultResource = {
  $resourceType: ConfirmEmailResultResource.$resourceType;
  /**
   * Email
   */
  email: string;
  /**
   * Is confirmed?
   */
  confirmed: boolean;
};

export namespace ConfirmEmailResultResource {

  export enum $resourceType {
    CONFIRM_EMAIL_RESULT_RESOURCE = 'ConfirmEmailResultResource',
  }


}

