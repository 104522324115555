import {
  Stack,
  Typography,
} from '@mui/material';
import { BackendService } from './BackendService/BackendService';
import { Clients } from './Clients/Clients';
import { Auth } from "./auth/auth";
import { WsProvider } from "../contexts/ws-context";

export function ControlPanel() {
  return <Stack gap={2}>
    <WsProvider>
      <BackendService/>
    </WsProvider>
    <Typography variant="h4">
      Clients
    </Typography>
    <Clients/>
    <Typography variant="h4">
      Auth
    </Typography>
    <Auth/>
  </Stack>;
}
