import { iByteStream, oByteStream, Uint8 } from '../Binary';
import * as DI from '../DI/Logger';
import { TransportError } from '../PlutoError';
import { Transport } from './Transport';
import { TransportAction } from './TransportAction';
import {
  DEFAULT_DC_CONNECTION_TIMEOUT,
  DEFAULT_WS_CONNECTION_TIMEOUT,
  PcConfig,
  TransportConfig,
  WsConfig,
} from './TransportConfig';

export class TransportBuilder {
  static async create(conf: TransportConfig): Promise<Transport> {
    const ws = await this._buildWS(conf.ws);
    const pc = this._buildPC(ws, conf.pc);
    const dc = await this._buildDC(ws, pc, conf);
    return new Transport(ws, pc, dc);
  }

  private static _buildWS(conf: WsConfig) {
    return new Promise<WebSocket>((resolve, reject) => {
      const to = setTimeout(() => {
        reject(new TransportError('TransportBuilder: WS connection timed out'));
      }, conf.connectionTimeout ?? DEFAULT_WS_CONNECTION_TIMEOUT);
      const ws = new WebSocket(conf.url, conf.protocols);
      ws.binaryType = 'arraybuffer';
      ws.onerror = (event) => {
        DI.logger().error(`TransportBuilder: WS error:`);
        DI.logger().error(event);
        if (to) {
          clearTimeout(to);
        }
        reject(new TransportError('WS got error'));
      };
      ws.onclose = () => {
        DI.logger().error(`TransportBuilder: WS closed`);
        if (to) {
          clearTimeout(to);
        }
        reject(new TransportError('WS is closed'));
      };
      ws.onopen = () => {
        if (to) {
          clearTimeout(to);
        }
        resolve(ws);
      };
    });
  }

  private static _buildPC(ws: WebSocket, pcConfig?: PcConfig): RTCPeerConnection {
    const pc = new RTCPeerConnection(pcConfig);
    pc.onicecandidate = (event) => {
      if (event.candidate) {
        const obs = new oByteStream();
        obs.write(new Uint8(TransportAction.newIceCandidate));
        obs.writeString(event.candidate.candidate);
        ws.send(obs.bytes());
      }
    };
    pc.onicecandidateerror = (event) => { DI.logger().error(event); };
    return pc;
  }

  private static async _buildDC(ws: WebSocket, pc: RTCPeerConnection, conf: TransportConfig): Promise<RTCDataChannel> {
    return new Promise<RTCDataChannel>(async (resolve, reject) => {
      const to = setTimeout(() => {
        reject(new TransportError('TransportBuilder: DC connection timed out'));
      }, conf.dc?.connectionTimeout ?? DEFAULT_DC_CONNECTION_TIMEOUT);
      ws.onmessage = this._makeOnWsMessageCallback(pc);
      const dc = pc.createDataChannel(conf.dcLabel ?? 'default', conf.dc);
      dc.binaryType = 'arraybuffer';
      dc.onerror = (event) => {
        DI.logger().error(event);
        if (to) {
          clearTimeout(to);
        }
        reject(new TransportError('DC got an error'));
      };
      dc.onclose = (event) => {
        DI.logger().warn(`DC closed`);
        DI.logger().warn(event);
        if (to) {
          clearTimeout(to);
        }
        reject(new TransportError('DC is closed'));
      };
      dc.onopen = () => {
        DI.logger().debug('DC opened');
        if (to) {
          clearTimeout(to);
        }
        resolve(dc);
      };
      await pc.setLocalDescription(await pc.createOffer());
      const offer = pc.localDescription;
      if (offer === null) {
        throw new TransportError('Unable to create an offer for RTCPeerConnection');
      }
      const obs = new oByteStream();
      obs.write(new Uint8(TransportAction.offer))
        .writeString(offer.sdp)
        .writeString(offer.type);
      ws.send(obs.bytes());
    });
  }

  private static _addNewIceCandidate(pc: RTCPeerConnection, candidate: RTCIceCandidateInit) {
    pc.addIceCandidate(candidate)
      // .then((success: any) => DI.logger().debug({ success, candidate }))
      .catch((error: any) => DI.logger().error({ error, candidate }));
  }

  private static _onRemoteAnswer(pc: RTCPeerConnection, ibs: iByteStream) {
    const remoteDescription: RTCSessionDescriptionInit = {
      sdp: ibs.readString(),
      type: ibs.readString() as RTCSdpType,
    };
    return pc.setRemoteDescription(remoteDescription);
  }

  private static _onRemoteIceCandidate(
    pc: RTCPeerConnection,
    ibs: iByteStream,
    creationAnswerReceived: boolean,
    awaitingCandidates: RTCIceCandidateInit[],
  ) {
    const candidate: RTCIceCandidateInit = {
      candidate: ibs.readString(),
      sdpMid: ibs.readString(),
    };
    // DI.logger().debug({ message: 'New ice candidate', candidate });
    if (!creationAnswerReceived) {
      awaitingCandidates.push(candidate);
    } else {
      this._addNewIceCandidate(pc, candidate);
    }
  }

  private static _makeOnWsMessageCallback(pc: RTCPeerConnection): (event: MessageEvent) => (any | undefined) {
    let creationAnswerReceived = false;
    const awaitingCandidates: RTCIceCandidateInit[] = [];
    return ({ data }: MessageEvent) => {
      if (!(data instanceof ArrayBuffer)) {
        DI.logger().error('TransportBuilder: WS got unsupported textual message. Only binary is supported.');
        return;
      }
      try {
        const ibs = new iByteStream(data);
        const action = ibs.read(Uint8).value as TransportAction;
        switch (action) {
          case TransportAction.offer:
            break;
          case TransportAction.answer:
            this._onRemoteAnswer(pc, ibs).then(() => {
              creationAnswerReceived = true;
              for (const awaitingCandidate of awaitingCandidates) {
                this._addNewIceCandidate(pc, awaitingCandidate);
              }
            });
            return;
          case TransportAction.newIceCandidate:
            return this._onRemoteIceCandidate(
              pc,
              ibs,
              creationAnswerReceived,
              awaitingCandidates,
            );
          case TransportAction.error: {
            const error = ibs.readString();
            // noinspection ExceptionCaughtLocallyJS
            throw new TransportError(error);
          }
          default:
            // noinspection UnnecessaryLocalVariableJS
            const unreachable: never = action;
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Unhandled TransportAction ${unreachable}`);
        }
        DI.logger().error(`WS got bad action: ${action}`);
      } catch (err) {
        DI.logger().error(err);
        throw err;
      }
    };
  }
}
