/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListServersFilterDto } from '../models/ListServersFilterDto';
import type { ServersConfigResource } from '../models/ServersConfigResource';
import type { ServersResource } from '../models/ServersResource';
import type { ServersStateResource } from '../models/ServersStateResource';
import type { ServerStateResource } from '../models/ServerStateResource';
import type { SuccessResource } from '../models/SuccessResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServerApi {

  /**
   * List servers
   * @param page Page number
   * @param limit Number of elements per page
   * @param filter
   * @returns ServersResource Servers
   * @throws ApiError
   */
  public static listServers(
    page: number = 1,
    limit: number = 25,
    filter?: ListServersFilterDto,
  ): CancelablePromise<ServersResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/servers',
      query: {
        'page': page,
        'limit': limit,
        'filter': filter,
      },
    });
  }

  /**
   * Reconnect the server
   * @param serverId
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static reconnect(
    serverId: string,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/servers/{serverId}/queue-reconnect-task',
      path: {
        'serverId': serverId,
      },
      errors: {
        400: `Bad request`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Schedule the server shutdown
   * @param serverId
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static scheduleShutdown(
    serverId: string,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/servers/{serverId}/schedule-shutdown',
      path: {
        'serverId': serverId,
      },
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Server not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Cancel the server shutdown
   * @param serverId
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static cancelShutdown(
    serverId: string,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/servers/{serverId}/cancel-shutdown',
      path: {
        'serverId': serverId,
      },
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Server not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Returns servers configuration
   * @returns ServersConfigResource Configuration
   * @throws ApiError
   */
  public static getConfig(): CancelablePromise<ServersConfigResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/servers-config',
    });
  }

  /**
   * Get overall servers state
   * @returns ServersStateResource Overall servers state
   * @throws ApiError
   */
  public static getServersState(): CancelablePromise<ServersStateResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/servers-state',
      errors: {
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Get server state
   * @param serverId
   * @returns ServerStateResource Server state
   * @throws ApiError
   */
  public static getServerState(
    serverId: string,
  ): CancelablePromise<ServerStateResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/servers-state/{serverId}',
      path: {
        'serverId': serverId,
      },
      errors: {
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Delete the server
   * @param serverId
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static deleteServer(
    serverId: string,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/servers/{serverId}',
      path: {
        'serverId': serverId,
      },
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Server not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

}
