import {
  Stack,
  Typography,
  Card,
  IconButton,
  Box,
  Tooltip,
  Modal,
  CardContent
} from "@mui/material";
import {
  ServerResource,
  SessionResource,
  SessionApi,
  RoomResource,
  SessionTypeGameLiftPayloadResource,
  SessionPayloadResource,
} from "../../../generated/api-client";
import { useBackendServiceContext } from "../../../contexts/backend-service-context";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { useState, useEffect, Fragment } from "react";
import { useMsg } from "../../../contexts/msg-context";
import type = SessionResource.type;
import ConnectionIdChip from "../../Common/ConnectionIdChip";
import { ConnectionId } from "@own/pluto_client";
import DataObjectIcon from "@mui/icons-material/DataObject";
import Uuid from "../../Common/Uuid";
import { SessionLogs } from "./SessionLogs";
import { Rooms } from "./Rooms";
import { UserSessions } from "./UserSessions";
import { AddClientForm } from "./AddClientForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListIcon from '@mui/icons-material/List';
import CachedIcon from '@mui/icons-material/Cached';

export type BackendServiceSessionProps = {
  server: ServerResource;
  session: SessionResource;
  onBack: () => void;
  onReload: () => void;
};

export function BackendServiceSession({ server, session, onBack, onReload }: BackendServiceSessionProps) {
  const { currentSessionClient, clients } = useBackendServiceContext();
  const { showMessage, showApiError } = useMsg();
  const [typeGameLiftPayload, setTypeGameLiftPayload] = useState<SessionTypeGameLiftPayloadResource | null>(null);
  const [sessionClients, setSessionClients] = useState<ConnectionId[]>([]);
  const [isSessionPayloadBtnAvailable, setIsSessionPayloadBtnAvailable] = useState(false);
  const [rooms, setRooms] = useState<RoomResource[]>([]);
  const [isAddClientFormOpen, setIsAddClientFormOpen] = useState(false);

  useEffect(() => {
    const x = session.rooms;
    x.sort((a, b) => (a.remoteRoomId > b.remoteRoomId ? 1 : -1));
    setRooms(x);
  }, [session.rooms]);

  useEffect(() => {
    setIsSessionPayloadBtnAvailable(!!currentSessionClient && currentSessionClient.sessionId === session.sessionId);
  }, [
    currentSessionClient,
    session.sessionId,
    setIsSessionPayloadBtnAvailable,
  ]);

  useEffect(() => {
    setSessionClients(clients.filter((x) => x.sessionId === session.sessionId).map((x) => x.connectionId));
  }, [clients, session?.sessionId, setSessionClients]);

  const closeUserSession = () => {
    SessionApi.closeSession(session.sessionId)
      .then(() => showMessage(`Session closed`, "success"))
      .catch(showApiError);
  };

  useEffect(() => {
    if (session.type === type.GAME_LIFT) {
      setTypeGameLiftPayload(session.typePayload as SessionTypeGameLiftPayloadResource);
    } else {
      setTypeGameLiftPayload(null);
    }
  }, [session, setTypeGameLiftPayload]);

  const onAddClient = () => {
    setIsAddClientFormOpen(true);
  };

  const onGetSessionPayload = () => {
    if (!currentSessionClient) {
      showMessage("No current SessionClient found", "error");
      return;
    }
    setIsSessionPayloadModalOpened(false);
    setSessionPayload(null);
    currentSessionClient.sessionClient
      .getSessionPayload()
      .then((x) => {
        if (x.isRight()) {
          setSessionPayload(x.value);
          setIsSessionPayloadModalOpened(true);
        } else {
          showApiError(x.value);
        }
      })
      .catch(showApiError);
  };

  const [isSessionPayloadModalOpened, setIsSessionPayloadModalOpened] = useState(false);
  const [sessionPayload, setSessionPayload] = useState<SessionPayloadResource | null>(null);
  const handleSessionPayloadModalClose = () => {
    setIsSessionPayloadModalOpened(false);
  };

  const [isSessionLogsModalOpened, setIsSessionLogsModalOpened] = useState(false);
  const onShowLogs = () => {
    setIsSessionLogsModalOpened(true);
  };
  const handleSessionLogsModalClose = () => setIsSessionLogsModalOpened(false);

  return (
    <Card variant="outlined">
      <AddClientForm
        serverId={server.id}
        sessionId={session.sessionId}
        isOpen={isAddClientFormOpen}
        onClose={() => {
          setIsAddClientFormOpen(false);
        }}
      />

      <Stack m={2} fontSize="0.85em">
        <Stack direction="row" alignItems="center">
          <Box>
            <Tooltip title="Go back">
              <IconButton size="small" onClick={onBack}>
                <ArrowBackIcon fontSize="inherit"/>
              </IconButton>
            </Tooltip>
          </Box>
          <Typography flex={1} variant="subtitle1">
            Session <Uuid id={session.sessionId}/>
          </Typography>
          <Stack
            direction="row"
          >
            <Tooltip title="Show logs">
              <span>
              <IconButton
                disabled={!isSessionPayloadBtnAvailable}
                size="small"
                onClick={onShowLogs}
              >
                <ListIcon fontSize="inherit"/>
              </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Show the SessionPayload">
              <span>
              <IconButton
                disabled={!isSessionPayloadBtnAvailable}
                size="small"
                onClick={onGetSessionPayload}
              >
                <DataObjectIcon fontSize="inherit"/>
              </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Close this Session">
              <IconButton size="small" color="error" onClick={closeUserSession}>
                <ClearIcon fontSize="inherit"/>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>

        <Stack>
          {typeGameLiftPayload ? (
            <Fragment>
              <div>
                GameSessionId: <span style={{ color: "gray" }}>{typeGameLiftPayload.gameSessionId}</span>
              </div>
            </Fragment>
          ) : null}
        </Stack>

        <Stack direction="row" alignItems="center">
          <div>
            Clients ({sessionClients.length}):
          </div>
          {sessionClients.map((x) => (
            <ConnectionIdChip key={x.value} small={true} connectionId={x.value}/>
          ))}
          <Tooltip title="Add a Client in this Session">
            <IconButton size="small" onClick={onAddClient}>
              <AddIcon fontSize="inherit"/>
            </IconButton>
          </Tooltip>
        </Stack>

        <Stack direction="row" gap={2}>
          <Rooms
            session={session}
            rooms={rooms}
          />
          <UserSessions
            server={server}
            session={session}
            onReload={onReload}
          />
        </Stack>
      </Stack>
      <Modal
        open={isSessionPayloadModalOpened}
        onClose={handleSessionPayloadModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          style={{
            margin: "auto",
            width: 600,
            maxHeight: 800,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CardContent>
            <Typography variant="h5" mb={2}>
              Session <Uuid color="inherit" id={session.sessionId}/> payload
            </Typography>
            <pre
              style={{
                maxHeight: 400,
                overflow: "auto",
                fontSize: "0.85em",
              }}
            >
              {sessionPayload ? JSON.stringify(sessionPayload, undefined, " ") : "no payload"}
            </pre>
          </CardContent>
        </Card>
      </Modal>
      <Modal
        open={isSessionLogsModalOpened}
        onClose={handleSessionLogsModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          style={{
            margin: "auto",
            width: 800,
            maxHeight: 800,
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", flexDirection: "column", maxHeight: "100%" }}>
              <SessionLogs sessionId={session.sessionId}/>
            </Box>
          </CardContent>
        </Card>
      </Modal>
    </Card>
  );
}
