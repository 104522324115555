import { BaseClient } from '../BaseClient';
import { iByteStream, Numeric, oByteStream, Uint8 } from '../Binary';
import {
  ClientConfig,
  makeFilteringAndBatchingConfigForDC,
  makeFilteringAndBatchingConfigForWS,
  makeTimeSyncConfig,
  TimeSyncConfig,
} from './client-config';
import { ClientEventTypes } from './client-event-types';
import * as DI from '../DI/Logger';
import { readResponseHeader, RequestHeader, ResponseHeader } from '../Headers';
import { ConnectionId, idEqual, MessageId, QueueId, RoomId } from '../Id';
import { ClientError, PlutoServerError } from '../PlutoError';
import { Route } from '../Route';
import { FBRMessagesService } from '../Services/FBRMessagesService';
import { PingTimings, TimeInfo, TimeSyncService } from '../TimeSyncService';
import { ChannelType, DEFAULT_WS_CONNECTION_TIMEOUT, Transport, TransportInterface } from '../Transport';
import { FilterBatchTransportWrapper } from '../TransportWrappers/FilterBatchTransportWrapper';

export interface ClientMsgOptArgs {
  queueId?: QueueId,
  messageId?: MessageId,
}

interface ClientRequestInternalOptArgsA extends ClientMsgOptArgs {
  timeInfo?: TimeInfo,
  bodyByteLen?: number,
}

type ClientRequestInternalOptArgs = {
  channel?: ChannelType;
  data?: ArrayBuffer;
  roomId?: RoomId;
  checkJoinedRoom?: boolean;
};

function findIdIndex<T extends Numeric>(ids: { value: T }[], id: { value: T }) {
  return ids.findIndex((v) => idEqual(v, id));
}

function headerToPingTimings(header: ResponseHeader) {
  return {
    clientSentTimestamp: Number(header.clientSentTimestamp.value),
    serverReceivedTimestamp: Number(header.serverReceivedTimestamp.value),
    serverSentTimestamp: Number(header.serverSentTimestamp.value),
    clientReceivedTimestamp: Number(header.clientReceivedTimestamp.value),
  };
}

export class Client extends BaseClient<ResponseHeader, ClientEventTypes> {
  readonly id: ConnectionId;
  private readonly _filterBatch: FilterBatchTransportWrapper;
  private _joinedRooms: RoomId[] = [];
  private _timeSync = new Map<ChannelType, TimeSyncService>();

  static async create(config: ClientConfig): Promise<Client> {
    const transport = config.transport || (await Transport.create(config));
    DI.logger().debug(`Send an ID request`);
    const id = await this._sendIdRequest(config, transport);
    DI.logger().debug(`Got id ${id.value}`);
    const fbrConfigWS = makeFilteringAndBatchingConfigForWS(config.filteringAndBatchingConfigWS);
    const fbrConfigDC = makeFilteringAndBatchingConfigForDC(config.filteringAndBatchingConfigDC);
    return new Client(id, transport,
      config.requestTimeout ?? 0,
      makeTimeSyncConfig(config.timeSyncConfig),
      new FBRMessagesService(id, fbrConfigWS),
      new FBRMessagesService(id, fbrConfigDC),
    );
  }

  private static async _sendIdRequest(config: ClientConfig, transport: TransportInterface): Promise<ConnectionId> {
    return new Promise((resolve, reject) => {
      let attempts = 2;
      const doRequest = () => {
        const to = setTimeout(() => {
          attempts--;
          if (attempts) {
            console.log(`Attempts left ${attempts}`);
            doRequest();
          } else {
            reject(new ClientError(`Failed to get an id!`));
          }
        }, config.ws.connectionTimeout ?? DEFAULT_WS_CONNECTION_TIMEOUT);
        transport.onMessage(ChannelType.websocket, (message) => {
          if (to) {
            clearTimeout(to);
          }
          resolve(new iByteStream(message).read(ConnectionId));
        });
        const obs = new oByteStream(Uint8.byteLen());
        const getIdRoute = 0;
        obs.write(new Uint8(getIdRoute));
        transport.send(ChannelType.websocket, obs.bytes());
      };
      doRequest();
    });
  }

  timeInfo(channelType: ChannelType): TimeInfo {
    return (this._timeSync.get(channelType) as TimeSyncService).getTimeInfo();
  }

  listConnections(): Promise<ConnectionId[]> {
    return this.makeRequest(Route.listConnections, (header, body) => {
      return body.readArray(ConnectionId);
    });
  }

  pushConnectionUserData(data: ArrayBuffer): Promise<void> {
    return this.makeRequest(Route.pushConnectionUserData, () => {
    }, { data });
  }

  pullConnectionUserData(): Promise<ArrayBuffer> {
    return this.makeRequest(Route.pullConnectionUserData, (header, body) => body.rest());
  }

  registerJoinedRoom(roomId: RoomId) {
    console.log('registerJoinedRoom', roomId);
    const index = findIdIndex(this._joinedRooms, roomId);
    if (index === -1) {
      this._joinedRooms.push(roomId);
    }
  }

  unregisterJoinedRoom(roomId: RoomId) {
    console.log('unregisterJoinedRoom', roomId);
    const index = findIdIndex(this._joinedRooms, roomId);
    if (index !== -1) {
      this._joinedRooms.splice(index, 1);
    }
  }

  listRooms(): Promise<RoomId[]> {
    return this.makeRequest(Route.listRooms, (header, body) => {
      return body.readArray(RoomId);
    });
  }

  listRoomConnections(roomId: RoomId): Promise<ConnectionId[]> {
    return this.makeRequest(Route.listRoomConnections, (header, body) => {
      return body.readArray(ConnectionId);
    }, { roomId });
  }

  pushRoomUserData(roomId: RoomId, data: ArrayBuffer): Promise<{ roomId: RoomId, data: ArrayBuffer }> {
    return this.makeRequest(Route.pushRoomUserData,
      () => {
        return { roomId, data };
      },
      { roomId, data });
  }

  pullRoomUserData(roomId: RoomId): Promise<{ roomId: RoomId, data: ArrayBuffer }> {
    return this.makeRequest(Route.pullRoomUserData, (header, body) => {
      const roomId = body.read(RoomId);
      return { roomId, data: body.rest() };
    }, { roomId });
  }

  message(
    channel: ChannelType,
    msg: ArrayBuffer,
    recipients: ConnectionId[],
    opts: ClientMsgOptArgs = {},
  ): RequestHeader {
    const bodyByteLen = recipients.length * ConnectionId.byteLen() + msg.byteLength;
    const timeInfo = this.getTimeInfo(channel);
    const { header, message } = this.makeMessageWithHeader(Route.message, { ...opts, bodyByteLen, timeInfo });
    message.writeArray(recipients);
    message.writeArrayBuffer(msg);
    this.sendQueued(channel, message.bytes(), timeInfo);
    return header;
  }

  serverwideBroadcast(channel: ChannelType, msg: ArrayBuffer, opts: ClientMsgOptArgs = {}): RequestHeader {
    const bodyByteLen = msg.byteLength;
    const timeInfo = this.getTimeInfo(channel);
    const { header, message } = this.makeMessageWithHeader(Route.serverwideBroadcast,
      { ...opts, bodyByteLen, timeInfo });
    message.writeArrayBuffer(msg);
    this.sendQueued(channel, message.bytes(), timeInfo);
    return header;
  }

  roomwideBroadcast(
    channel: ChannelType,
    msg: ArrayBuffer,
    roomId: RoomId,
    opts: ClientMsgOptArgs = {},
  ): RequestHeader {
    const index = findIdIndex(this._joinedRooms, roomId);
    if (index === -1) {
      const channelStr = channel === ChannelType.websocket ? 'ws' : 'dc';
      throw new ClientError(`Can't broadcast ${channelStr} to room without joining`);
    }

    const bodyByteLen = RoomId.byteLen() + msg.byteLength;
    const timeInfo = this.getTimeInfo(channel);
    const { header, message } = this.makeMessageWithHeader(Route.roomwideBroadcast, {
      ...opts,
      bodyByteLen,
      timeInfo,
    });
    message.write(roomId);
    message.writeArrayBuffer(msg);
    this.sendQueued(channel, message.bytes(), timeInfo);
    return header;
  }

  ping(channel: ChannelType, data?: ArrayBuffer) {
    return this.makeRequest(
      Route.ping,
      (header, body) => {
        return { header, data: body.rest() };
      },
      { channel },
    );
  }

  private constructor(
    id: ConnectionId,
    transport: TransportInterface,
    timeout: number,
    timeSyncConfig: TimeSyncConfig,
    fbrServiceWS: FBRMessagesService,
    fbrServiceDC: FBRMessagesService,
  ) {
    const filterBatch = new FilterBatchTransportWrapper(transport, fbrServiceWS, fbrServiceDC);
    super(filterBatch, timeout);

    this.id = id;
    this._filterBatch = filterBatch;

    this.onMessage(ChannelType.websocket, this._makeOnMessageHandler(ChannelType.websocket));
    this.onMessage(ChannelType.datachannel, this._makeOnMessageHandler(ChannelType.datachannel));

    this.events.on('close', (event) => {
      for (const [channel, service] of this._timeSync) {
        service.close();
      }
    });

    for (const channel of [ChannelType.websocket, ChannelType.datachannel]) {
      const service = new TimeSyncService(timeSyncConfig, () => {
        const header = new RequestHeader(Route.ping, this.id);
        const headerBytes = new oByteStream().write(header).bytes();
        return this.request<PingTimings>(channel,
          headerBytes,
          this.messageId(header),
          headerToPingTimings);
      });
      const eventName = channel === ChannelType.websocket ? 'wsTimeSync' : 'dcTimeSync';
      service.onTimeInfoUpdated((timeInfo) => this.events.emit(eventName, timeInfo));
      service.run();
      this._timeSync.set(channel, service);
    }
  }

  protected readHeader(channelType: ChannelType, message: iByteStream) {
    return readResponseHeader(message, this.getTimeInfo(channelType));
  }

  protected readError(header: ResponseHeader, body: iByteStream): string | null {
    return header.route === Route.error ? body.readString() : null;
  }

  protected messageId(header: RequestHeader | ResponseHeader): bigint {
    return (BigInt(header.senderId.value) << BigInt(8 * MessageId.byteLen())) | BigInt(header.messageId.value);
  }

  protected onClose(event: CloseEvent | Event): any {
    this.events.emit('close', event);
  }

  private _makeOnMessageHandler(channelType: ChannelType) {
    return (header: ResponseHeader, body: iByteStream) => {
      const eventType = Route[header.route] as keyof ClientEventTypes;
      try {
        switch (header.route) {
          case Route.connectionJoined:
          case Route.connectionClosed: {
            this.events.emit(eventType, { header, connectionId: header.senderId });
            return;
          }
          case Route.createRoom:
          case Route.joinRoom:
          case Route.leaveRoom:
          case Route.joinRoomThroughBackend:
          case Route.leaveRoomThroughBackend: {
            const roomId = body.read(RoomId);
            this.events.emit(eventType, { header, roomId });
            return;
          }
          case Route.closeRoom: {
            const roomId = body.read(RoomId);
            const index = findIdIndex(this._joinedRooms, roomId);
            if (index !== -1) {
              this._joinedRooms.splice(index, 1);
            }
            this.events.emit(eventType, { header, roomId });
            return;
          }
          case Route.pushRoomUserData: {
            const roomId = body.read(RoomId);
            this.events.emit(eventType, { header, roomId, body: body.rest() });
            return;
          }
          case Route.message:
          case Route.serverwideBroadcast: {
            if (header.senderId.value === 0) {
              this._onBackendMessage(header, body, channelType);
              return;
            }
            this.events.emit(eventType, { header, body: body.rest(), channelType });
            return;
          }
          case Route.roomwideBroadcast: {
            if (header.senderId.value === 0) {
              this._onBackendRoomWideMessage(header, body, channelType);
              return;
            }
            const roomId = body.read(RoomId);
            this.events.emit(eventType, { header, roomId, body: body.rest(), channelType });
            return;
          }
          case Route.error: {
            const error = new PlutoServerError(body.readString());
            this.events.emit(eventType, { header, error });
            return;
          }
          /**
           * Ignored messages
           */
          case Route.listConnections:
          case Route.ping:
          case Route.batch:
          case Route.listRooms:
          case Route.listRoomConnections:
          case Route.pullConnectionUserData:
          case Route.pullRoomUserData:
          case Route.pushConnectionUserData:
            return;
          default:
            const unreachable: never = header.route;
            DI.logger().warn(`Client: Got unknown route ${unreachable}`);
        }
      } catch (e: any) {
        console.error(`Client: ${eventType} message handler error`, e);
        return;
      }
    };
  }

  private _onBackendMessage(header: ResponseHeader, body: iByteStream, channelType: ChannelType) {
    const decoded = body.readString();
    this.events.emit('messageBackend', { header, body: decoded, channelType });
  }

  private _onBackendRoomWideMessage(header: ResponseHeader, body: iByteStream, channelType: ChannelType) {
    const roomId = body.read(RoomId);
    const decoded = body.readString();
    this.events.emit('roomwideBroadcastBackend', { header, roomId, body: decoded, channelType });
  }

  private sendQueued(channel: ChannelType, message: ArrayBuffer, timeInfo: TimeInfo) {
    this._filterBatch.sendQueued(channel, message, timeInfo);
  }

  private getTimeInfo(channel: ChannelType, timeInfo?: TimeInfo) {
    if (timeInfo) {
      return timeInfo;
    }
    return this.timeInfo(channel);
  }

  private makeRequest<T>(
    route: Route,
    parseResponse: (header: ResponseHeader, msg: iByteStream) => T,
    {
      channel = ChannelType.websocket,
      roomId,
      data,
      checkJoinedRoom = true,
    }: ClientRequestInternalOptArgs = {},
  ): Promise<T> {
    if (roomId && checkJoinedRoom) {
      const index = findIdIndex(this._joinedRooms, roomId);
      if (index === -1) {
        return new Promise<T>((resolve, reject) => {
          reject(new ClientError(`Can't ${Route[route]} room without joining`));
        });
      }
    }

    const { header, message } = this.makeMessageWithHeader(route, { timeInfo: this.getTimeInfo(channel) });
    if (roomId) {
      message.write(roomId);
    }
    if (data) {
      message.writeArrayBuffer(data);
    }
    return this.request<T>(channel, message.bytes(), this.messageId(header), parseResponse);
  }

  private makeRequestHeader(
    route: Route, {
      queueId,
      messageId,
      timeInfo,
    }: ClientRequestInternalOptArgsA = {},
  ) {
    return new RequestHeader(
      route,
      this.id,
      {
        queueId,
        messageId,
        timeOffset: timeInfo?.offset,
      },
    );
  }

  private makeMessageWithHeader(route: Route, opts: ClientRequestInternalOptArgsA = {}) {
    const header = this.makeRequestHeader(route, opts);
    const obs = new oByteStream(RequestHeader.byteLen() + (opts.bodyByteLen ?? 0));
    obs.write(header);
    return { header, message: obs };
  }
}
