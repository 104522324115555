import { Box, Stack, Typography } from '@mui/material';
import {
  ServerResource,
  SessionApi,
  CreateSessionCommand,
  ServerApi,
  ServersStateResource, ServersConfigResource
} from '../../../generated/api-client';
import React, { useCallback, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { useMsg } from '../../../contexts/msg-context';
import status = ServerResource.status;
import type = CreateSessionCommand.type;
import { useBackendServiceContext } from '../../../contexts/backend-service-context';
import serverType = ServerResource.type;
import { BackendServiceConfig } from "./BackendServiceConfig";
import { ServerListItem } from "./ServerListItem";
import { useNavigate } from "react-router-dom";
import { ServersInfo } from "./servers-info";
import { useWs } from '../../../contexts/ws-context';

export function Servers() {
  const { servers, setServers } = useBackendServiceContext();
  const [hasActiveGLServers, setHasActiveGLServers] = useState(false);
  const [isCreateGLSessionActive, setIsCreateGLSessionActive] = useState(false);
  const [isCreateOwnSessionActive, setIsCreateOwnSessionActive] = useState(false);
  const [config, setConfig] = useState<ServersConfigResource | null>(null);
  const [serversState, setServersState] = useState<ServersStateResource | null>(null);
  const { showMessage, showApiError } = useMsg();
  const navigate = useNavigate();
  const { on } = useWs();

  useEffect(() => {
    ServerApi.getConfig().then(setConfig).catch(showApiError);
  }, [setConfig, showApiError]);

  const loadServers = useCallback(() => {
    ServerApi.getServersState().then(setServersState).catch(showApiError);
    ServerApi.listServers().then(setServers).catch(showApiError);
  }, [setServers, setServersState, showApiError]);

  useEffect(() => {
    loadServers();
  }, [loadServers]);

  useEffect(() => on('server.connected', (payload: any) => {
    loadServers();
  }), [loadServers, on]);

  useEffect(() => on('server.disconnected', (payload: any) => {
    loadServers();
  }), [loadServers, on]);

  useEffect(() => on('server.scheduled-for-shutdown', (payload: any) => {
    loadServers();
  }), [loadServers, on]);


  useEffect(() => on('server.shutdown-cancelled', (payload: any) => {
    loadServers();
  }), [loadServers, on]);

  useEffect(() => on('server.shutdown', (payload: any) => {
    loadServers();
  }), [loadServers, on]);

  useEffect(() => on('server.removed', (payload: any) => {
    loadServers();
  }), [loadServers, on]);

  useEffect(() => {
    setHasActiveGLServers(servers ? servers.data.some((x) => {
      return x.type === type.GAME_LIFT && x.status === status.CONNECTED;
    }) : false);
  }, [servers]);

  const createSession = () => {
    setIsCreateOwnSessionActive(true);
    SessionApi.createSession({
      maxUserSessions: 2,
      maxRooms: 10,
      type: serverType.OWN,
    })
      .then(() => showMessage('Session created', 'success'))
      .catch(showApiError)
      .finally(() => setIsCreateOwnSessionActive(false));
  };

  const createGameLiftSession = () => {
    setIsCreateGLSessionActive(true);
    SessionApi.createSession({
      maxUserSessions: 2,
      maxRooms: 10,
      type: CreateSessionCommand.type.GAME_LIFT,
    })
      .then((data) => {
        console.log('Create session result', data);
        showMessage('Session created', 'success');
      })
      .catch(showApiError)
      .finally(() => setIsCreateGLSessionActive(false));
  };

  const onServerOpen = (server: ServerResource) => {
    navigate(`/servers/${server.id}`)
  }

  return <Stack
    margin={2}
    gap={2}
    fontSize=".85em"
  >
    {serversState && config ? <ServersInfo
      serversState={serversState}
      config={config}
    /> : null}
    <Stack
      direction="row"
      alignItems="center"
    >
      <LoadingButton
        variant="text"
        size="small"
        onClick={createSession}
        startIcon={<AddIcon/>}
        loadingPosition="start"
        loading={isCreateOwnSessionActive}
        disabled={isCreateOwnSessionActive}
      >
        Create a Session
      </LoadingButton>
      {hasActiveGLServers ?
        <LoadingButton
          variant="text"
          size="small"
          onClick={createGameLiftSession}
          startIcon={<AddIcon/>}
          loadingPosition="start"
          loading={isCreateGLSessionActive}
          disabled={isCreateGLSessionActive}
        >
          Create a GameLift Session
        </LoadingButton>
        : null}
      <Typography variant="caption">
        The Server will be selected automatically
      </Typography>
      <Box flex={1}/>
      <Box>
        {config ? <BackendServiceConfig config={config}/> : null}
      </Box>
    </Stack>

    {servers ? servers.data.map((server: ServerResource) => (
      <ServerListItem
        key={server.id}
        server={server}
        onServerOpen={onServerOpen}
      />
    )) : null}
    <br/>
  </Stack>;
}
