import {idCompare, idEqual, QueueId} from "../Id";
import {unique} from "../NoSTD";
import {HeaderMessage} from "./HeaderMessage";

const UNFILTERED_QUEUE_ID = new QueueId();

const requestHeaderComparator = (a: HeaderMessage, b: HeaderMessage) => {
    if (a.header.route !== b.header.route) {
        return Number(a.header.route - b.header.route);
    }
    if (idEqual(a.header.queueId, b.header.queueId)) {
        return idCompare(a.header.queueId, b.header.queueId);
    }
    // Put last messages first, so that when `unique` called they will stay
    return Number(b.header.clientSentTimestamp.value - a.header.clientSentTimestamp.value);
};
const requestHeaderEquality = (a: HeaderMessage, b: HeaderMessage) => {
    return a.header.route === b.header.route
        && idEqual(a.header.queueId, b.header.queueId)
        && !idEqual(a.header.queueId, UNFILTERED_QUEUE_ID);
};

export function filterMessages(messages: HeaderMessage[]) {
    messages.sort(requestHeaderComparator);
    return unique(messages, requestHeaderEquality);
}