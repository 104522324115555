/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateServerCommand } from '../models/CreateServerCommand';
import type { ServerResource } from '../models/ServerResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalServerApi {

  /**
   * Create a Server
   * @param requestBody
   * @returns ServerResource Created Server
   * @throws ApiError
   */
  public static createServer(
    requestBody: CreateServerCommand,
  ): CancelablePromise<ServerResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/internal/servers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Group not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

}
