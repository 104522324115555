import { Box } from '@mui/material';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useServersContext } from "../../../contexts/servers-context";
import { useLoaderData, useNavigate } from "react-router-dom";
import { BackendServiceSession } from "./BackendServiceSession";
import { SessionApi, SessionResource } from "../../../generated/api-client";
import { useMsg } from "../../../contexts/msg-context";
import { useWs } from "../../../contexts/ws-context";

export async function sessionLoader({ params }: any): Promise<{ session: SessionResource | null }> {
  const data = await SessionApi.getSession(params.sessionId);
  const session = data.session.sessionId === params.sessionId ? data.session : null;
  return { session };
}

export function Session() {
  const { session } = useLoaderData() as { session: SessionResource | null };
  const { selectedServer, selectedSession, setSelectedSession } = useServersContext();
  const navigate = useNavigate();
  const { showMessage, showApiError } = useMsg();
  const { on } = useWs();

  const onBack = useCallback(() => {
    navigate(`/servers/${selectedServer?.id}`);
  }, [navigate, selectedServer?.id]);

  const reloadSession = useCallback(() => {
    if (!session) {
      return;
    }
    SessionApi.getSession(session.sessionId)
      .then((data) => setSelectedSession(data.session))
      .catch(showApiError);
  }, [showApiError, setSelectedSession, session]);

  useEffect(() => on('session.closed', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      showMessage('This session was closed', 'warning');
      onBack();
    }
  }), [reloadSession, selectedSession, onBack, showMessage, on]);

  useEffect(() => on('session.host.changed', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      reloadSession();
    }
  }), [reloadSession, selectedSession, on]);

  useEffect(() => on('session.user-session.created', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      reloadSession();
    }
  }), [reloadSession, selectedSession, on]);

  useEffect(() => on('session.user-session.removed', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      reloadSession();
    }
  }), [reloadSession, selectedSession, on]);

  useEffect(() => on('session.user-session.connection-id-changed', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      reloadSession();
    }
  }), [reloadSession, selectedSession, on]);

  useEffect(() => on('session.room.added', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      reloadSession();
    }
  }), [reloadSession, selectedSession, on]);

  useEffect(() => on('session.room.removed', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      reloadSession();
    }
  }), [reloadSession, selectedSession, on]);

  useEffect(() => on('session.room.joined', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      reloadSession();
    }
  }), [reloadSession, selectedSession, on]);

  useEffect(() => on('session.room.left', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      reloadSession();
    }
  }), [reloadSession, selectedSession, on]);

  useEffect(() => on('session.room.host.changed', (payload: any) => {
    if (payload.sessionId === selectedSession?.sessionId) {
      reloadSession();
    }
  }), [reloadSession, selectedSession, on]);

  useEffect(() => setSelectedSession(session), [session, setSelectedSession]);

  return <Fragment>
    <Box>
      {selectedSession && selectedServer ? <BackendServiceSession
        server={selectedServer}
        session={selectedSession}
        onBack={onBack}
        onReload={reloadSession}
      /> : null}
    </Box>
  </Fragment>;
}
