import * as DI from '../DI/Logger';
import { PcSignallingState, WsReadyState } from '../enums';
import { TransportBuilder } from './TransportBuilder';
import { TransportConfig } from './TransportConfig';
import { ChannelType, TransportInterface } from './TransportInterface';

export class Transport implements TransportInterface {
  private _onWsMessageCallback?: (message: ArrayBuffer) => any;
  private _onDcMessageCallback?: (message: ArrayBuffer) => any;
  private _onCloseCallback?: (event: CloseEvent | Event) => any;
  private _isClosed = false;

  get isClosed() {
    return this._isClosed;
  }

  constructor(
    private readonly _ws: WebSocket,
    private readonly _pc: RTCPeerConnection,
    private readonly _dc: RTCDataChannel,
  ) {
    if (_ws.readyState !== WsReadyState.OPEN) {
      throw new Error(`Transport error: WebSocket should have the readyState ${WsReadyState.OPEN}`);
    }
    if (_pc.signalingState !== PcSignallingState.STABLE) {
      throw new Error(
        `Transport error: RTCPeerConnection should have the signalingState "${PcSignallingState.STABLE}"`,
      );
    }
    if (_dc.readyState !== 'open') {
      throw new Error(`Transport error: RTCDataChannel should have the readyState "open"`);
    }
    _ws.onclose = (event) => {
      DI.logger().warn(`Transport WS is closed: ${event.reason}`);
      DI.logger().warn(event);
      this._onCloseCallback?.(event);
      this.close();
    };
    _ws.onerror = (event) => {
      DI.logger().error('Transport WS error:');
      DI.logger().error(event);
      this.close();
    };
    _ws.onmessage = (event: MessageEvent) => {
      if (!(event.data instanceof ArrayBuffer)) {
        DI.logger().error('WS got unsupported textual message. Only binary is supported.');
        return;
      }
      const messageData: ArrayBuffer = event.data;
      this._onWsMessageCallback?.(messageData);
    };
    _dc.onmessage = (event) => {
      try {
        this._onDcMessageCallback?.(event.data);
      } catch (err) {
        DI.logger().error('Transport DC error:');
        DI.logger().error(err);
      }
    };
    _dc.onerror = (event) => {
      DI.logger().error(event);
    };
    _dc.onclose = (event) => {
      DI.logger().warn(`DC closed`);
      DI.logger().warn(event);
      this._onCloseCallback?.(event);
      this.close();
    };
  }

  static create(conf: TransportConfig): Promise<Transport> {
    return TransportBuilder.create(conf);
  }

  close() {
    this._isClosed = true;
    this._ws.close();
    this._dc.close();
    this._pc.close();
  }

  /**
   * @deprecated
   */
  closed(): boolean { return this._isClosed; }

  onClose(listener: (event: CloseEvent | Event) => any) {
    this._onCloseCallback = listener;
  }

  onMessage(type: ChannelType, listener: (message: ArrayBuffer) => any) {
    switch (type) {
      case ChannelType.websocket:
        this._onWsMessageCallback = listener;
        break;
      case ChannelType.datachannel:
        this._onDcMessageCallback = listener;
        break;
      default:
        // noinspection UnnecessaryLocalVariableJS
        const unreachable: never = type;
        throw new Error(`Unhandled ChannelType ${unreachable}`);
    }
  }

  send(channelType: ChannelType, data: ArrayBuffer) {
    switch (channelType) {
      case ChannelType.websocket: {
        if (this._ws.readyState !== WsReadyState.OPEN) {
          DI.logger().warn(`Skip WS message send: the readyState is ${this._ws.readyState}`);
          return;
        }
        return this._ws.send(data);
      }
      case ChannelType.datachannel:
        if (this._dc.readyState !== 'open') {
          DI.logger().warn(`Skip DC message send: the readyState is ${this._dc.readyState}`);
          return;
        }
        return this._dc.send(data);
    }
  }
}
