import { createContext, ReactNode, useContext, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { config } from '../config/config';

const socket: Socket = io(`${config.backendServiceUrl}/v1`);

export type WsContextProps = {
  on: (event: string, handler: (data: unknown) => void) => () => void;
  wsSend: (message: string) => void;
};

const WsContext = createContext<WsContextProps>({
  on: () => () => undefined,
  wsSend: () => undefined,
});

export const WsProvider = ({ children }: { children: ReactNode }) => {
  const wsSend = (message: string) => {
    socket.emit(message);
  };

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
      socket.close();
    };
  }, []);

  const on = (event: string, handler: (data: unknown) => void) => {
    socket.on(event, handler);
    return () => {
      socket.off(event, handler);
    };
  };

  return (<WsContext.Provider
    value={{
      on,
      wsSend,
    }}
  >
    {children}
  </WsContext.Provider>);
};

export const useWs = () => useContext(WsContext);
