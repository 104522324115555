/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GroupResource } from './GroupResource';

export type GroupsResource = {
  $resourceType: GroupsResource.$resourceType;
  /**
   * Current page
   */
  page: number;
  /**
   * Total entries
   */
  total: number;
  /**
   * Page size
   */
  limit: number;
  /**
   * Total pages
   */
  totalPages: number;
  data: Array<GroupResource>;
};

export namespace GroupsResource {

  export enum $resourceType {
    GROUPS_RESOURCE = 'GroupsResource',
  }


}

