import { Chip } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { ServerResource } from "../../../generated/api-client";

export type ServerShutdownStatusProps = {
  status: ServerResource.shutdownStatus;
}

const ServerShutdownStatus: FC<ServerShutdownStatusProps> = ({ status }: ServerShutdownStatusProps) => {
  return <Chip
    label={status}
    style={{ userSelect: 'none' }}
    color={status === ServerResource.shutdownStatus.SHUTDOWN ? 'error' : status === ServerResource.shutdownStatus.SCHEDULED_FOR_SHUTDOWN ? 'warning' : 'default'}
    size="small"
  />;
};

export default React.memo(ServerShutdownStatus);
