/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserResource } from './UserResource';

export type MeResource = {
  $resourceType: MeResource.$resourceType;
  user: UserResource;
};

export namespace MeResource {

  export enum $resourceType {
    ME_RESOURCE = 'MeResource',
  }


}

