import { ChannelType, TransportInterface } from '../Transport';
import { TimeInfo } from '../TimeSyncService';
import { FBRMessagesService, FBRMessageType } from '../Services/FBRMessagesService';

export class FilterBatchTransportWrapper implements TransportInterface {
    constructor(transport: TransportInterface,
                fbrServiceWS: FBRMessagesService,
                fbrServiceDC: FBRMessagesService) {
        this.transportImpl = transport;
        this.services = new Map([[ChannelType.websocket, fbrServiceWS], [ChannelType.datachannel, fbrServiceDC]]);
        for (const [channel, service] of this.services) {
            service.onReceiveMsgReady((messages) => {
                if (this.messageCallbacks.has(channel)) {
                    const cb = this.messageCallbacks.get(channel);
                    if (cb) {
                        messages.forEach(cb);
                    }
                }
            });
            service.onFilterBatchMsgReady(messages => {
                messages.forEach(message => this.send(channel, message));
            });
            this.transportImpl.onMessage(channel, (message) => service.queueMessage(FBRMessageType.receive, message));
        }
    }

    close(): void {
        this.transportImpl.close();
    }

    closed(): boolean {
        return this.transportImpl.closed();
    }

    onClose(listener: (event: (CloseEvent | Event)) => void): any {
        this.transportImpl.onClose(listener);
    }

    onMessage(channel: ChannelType, listener: (message: ArrayBuffer) => void) {
        this.messageCallbacks.set(channel, listener);
    }

    send(channelType: ChannelType, data: ArrayBuffer): any {
        this.transportImpl.send(channelType, data);
    }

    sendQueued(channelType: ChannelType, message: ArrayBuffer, timeInfo: TimeInfo): void {
        const service = this.services.get(channelType);
        if (service) {
            service.queueMessage(FBRMessageType.batch, message, timeInfo.offset);
        }
    }

    private readonly transportImpl: TransportInterface;
    private readonly services: Map<ChannelType, FBRMessagesService>;
    private readonly messageCallbacks = new Map<ChannelType, (message: ArrayBuffer) => any>();
}
