import { Tooltip } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

export type UuidProps = {
  id: string;
  color?: string;
}

const Uuid: FC<UuidProps> = ({ id, color }: UuidProps) => {
  return <Tooltip title={`${id}. Doubleclick to copy`}>
        <span
          style={{ userSelect: 'none', color: color ?? 'grey' }}
          onDoubleClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            navigator.clipboard.writeText(id);
          }
          }>
            {id.substring(0, 5)}
        </span>
  </Tooltip>;
};

export default React.memo(Uuid);
