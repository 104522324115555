/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SessionLogResource } from './SessionLogResource';

export type SessionLogsResource = {
  $resourceType: SessionLogsResource.$resourceType;
  /**
   * Current page
   */
  page: number;
  /**
   * Total entries
   */
  total: number;
  /**
   * Page size
   */
  limit: number;
  /**
   * Total pages
   */
  totalPages: number;
  data: Array<SessionLogResource>;
};

export namespace SessionLogsResource {

  export enum $resourceType {
    SESSION_LOGS_RESOURCE = 'SessionLogsResource',
  }


}

