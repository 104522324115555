import { unbatchMessages } from '../Utils/BatchMessages';
import { iByteStream } from '../Binary';
import { ResponseHeader } from '../Headers';
import { HeaderMessage } from '../Utils/HeaderMessage';
import { filterMessages } from '../Utils/FilterMessages';

export class FilterReceivedMessagesService {
    constructor(processingRate: number, enable: boolean = true) {
        this.enabled = enable;
        this.processingRate = processingRate;
        this.run();
    }

    run() {
        if (this.enabled) {
            this.intervalId = setInterval(this.doIncomingMessages.bind(this), this.processingRate);
        }
    }

    stop() {
        if (this.intervalId !== null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    queueMessage(message: ArrayBuffer) {
        if (!this.enabled) {
            const messages: ArrayBuffer[] = [];
            unbatchMessages(message, (message) => messages.push(message));
            this.messagesReadyCallback?.(messages);
            return;
        }
        unbatchMessages(message, (message) => {
            const ibs = new iByteStream(message);
            const header = ibs.read(ResponseHeader);
            this.queue.push({ header, data: message });
        });
    }

    onMessagesReady(callback: (messages: ArrayBuffer[]) => any) {
        this.messagesReadyCallback = callback;
    }

    private readonly enabled: boolean;
    private readonly processingRate: number;
    private messagesReadyCallback: ((messages: ArrayBuffer[]) => any) | null = null;
    private intervalId: ReturnType<typeof setInterval> | null = null;

    private queue: HeaderMessage[] = [];

    private doIncomingMessages() {
        if (this.queue.length === 0) {
            return;
        }
        const cmpInAscendingOrder = (a: HeaderMessage, b: HeaderMessage): number => {
            return Number(a.header.clientSentTimestamp.value - b.header.clientSentTimestamp.value);
        };
        const buffers = filterMessages(this.queue);
        buffers.sort(cmpInAscendingOrder);
        this.messagesReadyCallback?.(buffers.map((msg) => msg.data));
        this.queue = [];
    }
}
