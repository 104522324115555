import {
  Card,
  CardContent,
  Stack,
  Box,
  Chip,
  Tooltip,
  IconButton,
  CardActions,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  SessionResource,
  RoomResource, SessionApi,
} from '../../../generated/api-client';
import CloseIcon from '@mui/icons-material/Close';
import { useBackendServiceContext } from '../../../contexts/backend-service-context';
import { useMsg } from '../../../contexts/msg-context';
import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment/moment';
import ConnectionIdChip from '../../Common/ConnectionIdChip';
import { oByteStream, RoomId } from '@own/pluto_client';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import SendIcon from '@mui/icons-material/Send';
import Uuid from '../../Common/Uuid';

export type BackendServiceRoomProps = {
  session: SessionResource;
  room: RoomResource;
}

export function Room({ session, room }: BackendServiceRoomProps) {
  const { currentSessionClient } = useBackendServiceContext();
  const { showApiError, showMessage } = useMsg();
  const [connections, setConnections] = useState<number[]>([]);
  const [updatedAt, setUpdatedAt] = useState<Date | null>(null);
  const [isJoinDisabled, setIsJoinDisabled] = useState(false);
  const [isLeaveDisabled, setIsLeaveDisabled] = useState(false);
  const [isBroadcastDisabled] = useState(false);
  const [hostConnectionId, setHostConnectionId] = useState<number | null>(null);

  useEffect(() => {
    setConnections(session.userSessions.reduce((acc: number[], x) => {
      if (x.rooms.includes(room.roomId)) {
        acc.push(x.connectionId);
      }
      return acc;
    }, []));
    const roomInfo = session.rooms.find((x) => x.roomId === room.roomId);
    setUpdatedAt(roomInfo ? new Date(roomInfo.lastActivityAt) : null);
  }, [session, room, setConnections, setUpdatedAt]);

  useEffect(() => {
    if (!currentSessionClient) {
      setIsJoinDisabled(false);
      setIsLeaveDisabled(true);
      return;
    }
    setIsJoinDisabled(connections.find((x) => x === currentSessionClient.connectionId.value) !== undefined);
    setIsLeaveDisabled(connections.find((x) => x === currentSessionClient.connectionId.value) === undefined);
  }, [currentSessionClient, connections, setIsJoinDisabled, setIsLeaveDisabled]);

  useEffect(() => {
    if (room.hostUserSessionId) {
      const hostSession = session.userSessions.find((x) => x.userSessionId === room.hostUserSessionId);
      setHostConnectionId(hostSession?.connectionId ?? 0);
    } else {
      setHostConnectionId(null);
    }
  }, [room.hostUserSessionId, session, setHostConnectionId]);

  const joinRoom = () => {
    if (!currentSessionClient) {
      showMessage(`No current SessionClient found`, 'error');
      return;
    }
    currentSessionClient.sessionClient
      .joinRoom(new RoomId(room.remoteRoomId))
      .then(() => showMessage('Joined!', 'success'))
      .catch(showApiError);
  };

  const leaveRoom = () => {
    if (!currentSessionClient) {
      showMessage(`No current SessionClient found`, 'error');
      return;
    }
    currentSessionClient.sessionClient.leaveRoom(new RoomId(room.remoteRoomId))
      .then(() => {
        showMessage('Left!', 'success');
      })
      .catch(showApiError);
  };

  const closeRoom = () => {
    if (!currentSessionClient?.sessionId) {
      showMessage(`No current SessionClient.sessionId found`, 'error');
      return;
    }
    currentSessionClient.sessionClient
      .closeRoom(room.roomId)
      .then((data) => {
        showMessage(`Room ${room.remoteRoomId} closed`, 'success');
        console.log(`Room ${room.remoteRoomId} closed`, data);
      })
      .catch(showApiError);
  };

  const closeRoomAsAdmin = () => {
    SessionApi.closeRoom(session.sessionId, room.roomId)
      .then((data) => {
        showMessage(`Room ${room.remoteRoomId} closed`, 'success');
        console.log(`Room ${room.remoteRoomId} closed`, data);
      })
      .catch(showApiError);
  };

  const broadcast = () => {
    if (!currentSessionClient?.sessionId) {
      showMessage(`No current SessionClient.sessionId found`, 'error');
      return;
    }
    const obs = new oByteStream();
    obs.writeString('test');
    currentSessionClient.sessionClient
      .roomWideBroadcastWS(obs.bytes(), new RoomId(room.remoteRoomId), {});
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onChangeHost = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onCancelChangeHost = () => {
    setAnchorEl(null);
  };
  const onChangeHostTo = (newHostConnectionId: number) => {
    SessionApi.transferRoomHost(session.sessionId, room.remoteRoomId.toString(), {
      newHostConnectionId,
    })
      .then(() => showMessage(`Room ${room.remoteRoomId} host changed to ${newHostConnectionId}`, 'success'))
      .catch(showApiError)
      .finally(onCancelChangeHost);
  };

  return <Card>
    <CardContent style={{ fontSize: '.85em' }}>
      <Box sx={{ position: 'relative' }}>
        <Tooltip title="Close the Room as admin">
          <span>
          <IconButton
            sx={{ position: 'absolute', top: -8, right: -8 }}
            size="small"
            color="error"
            onClick={closeRoomAsAdmin}
          >
            <CloseIcon fontSize="inherit"/>
          </IconButton>
            </span>
        </Tooltip>
      </Box>
      <Stack direction="row">
        <Box>
          <Chip
            label={room.remoteRoomId}
            color="primary"
            size="small"
          />
        </Box>
        <Box flex={1}>
          <div>
            Id: <Uuid id={room.roomId}/>
          </div>
          {room.tag ? <div>Tag: <span style={{ color: 'grey' }}>{room.tag}</span></div> : null}
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ minWidth: 200 }}
          >
            <span>
              Conn-s:
            </span>
            {connections.map((x) => <ConnectionIdChip
              key={x}
              connectionId={x}
              small={true}
            />)}
          </Stack>
          {room.hostUserSessionId ? <Stack
            direction="row"
            alignItems="center"
          >
            <span>
              Host:
            </span>
            {hostConnectionId !== null ? <ConnectionIdChip
              connectionId={hostConnectionId}
              small={true}
            /> : <span style={{ color: 'grey' }}>empty</span>}
            <Box sx={{ cursor: 'pointer', fontStyle: 'italic' }} onClick={onChangeHost}>
              Change&nbsp;to&#x2026;
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={onCancelChangeHost}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {connections
                .filter((x) => x !== hostConnectionId)
                .map((x) => <MenuItem key={x}
                                      onClick={() => onChangeHostTo(x)}>
                  {x}
                </MenuItem>)}
            </Menu>
          </Stack> : null}
          <div>
            Host loss mode: <span style={{ color: 'grey' }}>{room.hostLossMode}</span>
          </div>
          <div>
            Updated
            at: <span style={{ color: 'grey' }}>{updatedAt ? moment(updatedAt).fromNow() : null}</span>
          </div>
        </Box>
      </Stack>
    </CardContent>
    <CardActions>
      {currentSessionClient ? <Fragment>
        <Tooltip title="Join the Room">
                        <span>
                        <IconButton
                          disabled={isJoinDisabled}
                          size="small"
                          onClick={joinRoom}
                        >
                        <CallReceivedIcon fontSize="inherit"/>
                        </IconButton>
                        </span>
        </Tooltip>
        <Tooltip title="Leave the Room">
                        <span>
                        <IconButton
                          disabled={isLeaveDisabled}
                          size="small"
                          onClick={leaveRoom}
                        >
                        <CallMadeIcon fontSize="inherit"/>
                        </IconButton>
                        </span>
        </Tooltip>
        <Tooltip title="Broadcast room-wide">
                        <span>
                        <IconButton
                          disabled={isBroadcastDisabled}
                          size="small"
                          onClick={broadcast}
                        >
                         <SendIcon fontSize="inherit"/>
                        </IconButton>
                        </span>
        </Tooltip>
        <Box ml="auto">
          <Tooltip title="Close the Room">
            <span>
            <IconButton
              size="small"
              onClick={closeRoom}
            >
              <CloseIcon fontSize="inherit"/>
            </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Fragment> : null}
    </CardActions>
  </Card>;
};
