import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Servers } from "./servers/servers";
import { ServersPage } from "./servers/ServersPage";
import { ServersContextProvider } from "../../contexts/servers-context";
import { Server, serverLoader } from "./servers/Server";
import { Sessions } from "./servers/Sessions";
import { Session, sessionLoader } from "./servers/Session";
import { Groups } from "./groups/Groups";
import { Group, groupLoader } from "./groups/Group";
import { GroupsPage } from "./groups/GroupsPage";
import 'react-json-view-lite/dist/index.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <ServersContextProvider><ServersPage/></ServersContextProvider>,
    children: [
      {
        path: '/',
        element: <Servers/>,
      },
      {
        path: '/groups',
        element: <GroupsPage/>,
        children: [
          {
            path: '/groups',
            element: <Groups/>,
            loader: groupLoader,
          },
          {
            path: '/groups/:groupId',
            element: <Group/>,
            loader: groupLoader,
          },
        ],
      },
      {
        path: '/servers/:serverId',
        element: <Server/>,
        loader: serverLoader,
        children: [
          {
            path: '/servers/:serverId',
            element: <Sessions/>,
          },
          {
            path: '/servers/:serverId/sessions/:sessionId',
            element: <Session/>,
            loader: sessionLoader,
          },
          {
            path: '/servers/:serverId/other',
            element: <div>other</div>,
          },
        ],
      },
    ],
  },
]);

export function BackendService() {
  return <RouterProvider router={router}/>;
}
