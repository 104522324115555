/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TypeGameLiftPayloadResource = {
  $resourceType: TypeGameLiftPayloadResource.$resourceType;
  /**
   * FleetId
   */
  fleetId: string;
};

export namespace TypeGameLiftPayloadResource {

  export enum $resourceType {
    TYPE_GAME_LIFT_PAYLOAD_RESOURCE = 'TypeGameLiftPayloadResource',
  }


}

