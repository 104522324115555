export enum Route {
  connectionJoined = 0,
  connectionClosed = 1,
  listConnections = 2,
  pushConnectionUserData = 3,
  pullConnectionUserData = 4,

  createRoom = 5,
  closeRoom = 6,
  joinRoom = 7,
  leaveRoom = 8,
  listRooms = 9,
  listRoomConnections = 10,
  pushRoomUserData = 11,
  pullRoomUserData = 12,

  error = 13,

  message = 14,
  serverwideBroadcast = 15,
  roomwideBroadcast = 16,
  ping = 17,
  batch = 18,
  joinRoomThroughBackend = 19,
  leaveRoomThroughBackend = 20,
}
