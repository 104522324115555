import * as DI from '../DI/Logger';
import { Either, left, right } from '../either';
import { ErrorCode } from '../enums';
import {
  CanNotFindServerForSessionPlacement, ConflictError,
  ServerNotFoundError, SessionIsClosedError,
  SessionNotFoundError,
  UnexpectedError,
} from '../errors';
import { CreateSessionClientCommand, ExistedUserSessionHandlingType } from './create-session-client-command';
import { SessionClient, SessionId, UserSessionId } from './session-client';
import {
  OpenAPI,
  ServerResource,
  SessionApi,
  CreateSessionCommand,
  SessionResource,
  ApiError,
} from '../generated/backend-service-client';
import { Client } from '../client';
import status = SessionResource.status;

type BuildSessionResult = {
  server: ServerResource;
  session: SessionResource;
};

export class SessionClientBuilder {
  /**
   * Creates the SessionClient instance.
   *
   * If no SessionId is provided:
   *  - Creates a new Session.
   *  - Creates a new UserSession (ignore UserSessionId if passed)
   *
   * If the SessionId is provided:
   *  - Tries to get existed Session when sessionId is specified.
   *      - If no Session found:
   *        - Creates a new Session if cmd.createNewSessionIfExistedOneIsNotFound is true
   *        - Otherwise throw an error
   *      - If Session is closed:
   *        - Creates a new Session if cmd.createNewSessionIfExistedOneIsClosed is true
   *        - Otherwise throw an error
   *      - If Session found:
   *        - If UserSessionId is passed:
   *          - Try to update UserSession ConnectionId with current value
   *          - If update fails try to create a new UserSession
   *        - If UserSessionId is not passed: try to create a new UserSession
   */
  static async build(
    cmd: CreateSessionClientCommand,
  ): Promise<Either<
    UnexpectedError
    | SessionNotFoundError
    | ServerNotFoundError
    | ConflictError
    | CanNotFindServerForSessionPlacement
    | SessionIsClosedError,
    SessionClient
  >> {
    try {
      OpenAPI.BASE = cmd.backendServiceUrl;
      const createSessionResult = await (cmd.sessionId ? this._buildForSessionId(cmd) : this._buildNewSession(cmd));
      if (createSessionResult.isLeft()) {
        return left(createSessionResult.value);
      }
      const { session, server } = createSessionResult.value;
      const client = await Client.create(this._buildClientConfig(cmd, server));
      let sessionClient: SessionClient;

      /**
       * Check if the same ownerId exists already
       */
      const userSessionWithSameOwnerId = session.userSessions.find((x) => x.ownerId === cmd.ownerId);
      if (userSessionWithSameOwnerId) {
        DI.logger().debug(`Found the existed user session with same owner id ${cmd.ownerId}.` +
          `Use this user session as existed one.`);
        cmd.userSessionId = userSessionWithSameOwnerId.userSessionId;
      }

      /**
       * Saved UserSessionId is useless when no SessionId or wrong SessionId was passed
       */
      if (cmd.sessionId === session.sessionId && cmd.userSessionId) {
        const res = await this._handleExistedUserSession(cmd, session, server, client);
        if (res.isLeft()) {
          return left(res.value);
        }
        sessionClient = res.value;
      } else {
        const res = await this._handleNewUserSession(cmd, session, server, client);
        if (res.isLeft()) {
          return left(res.value);
        }
        sessionClient = res.value;
      }
      OpenAPI.HEADERS = {
        'x-session-id': session.sessionId,
        'x-user-session-id': sessionClient.userSessionId,
        'x-connection-id': client.id.value.toString(),
      };
      return right(sessionClient);
    } catch (e: any) {
      if (e instanceof SessionIsClosedError) {
        return left(e);
      }
      console.log(e);
      DI.logger().error(`Failed to build a SessionClient: ${e.message}`);
      return left(new UnexpectedError('Failed to build a SessionClient'));
    }
  }

  private static async _handleNewUserSession(
    cmd: CreateSessionClientCommand,
    session: SessionResource,
    server: ServerResource,
    client: Client,
  ): Promise<Either<UnexpectedError | SessionNotFoundError | ConflictError | CanNotFindServerForSessionPlacement, SessionClient>> {
    try {
      const userSession = await SessionApi.createUserSession(session.sessionId, {
        ownerId: cmd.ownerId,
        connectionId: client.id.value,
        tag: cmd.userSessionTag ?? null,
      });
      const sessionClient = new SessionClient(
        client,
        session.sessionId,
        server.id,
        server.publicWsEndpoint,
        userSession.userSessionId,
        cmd.ownerId,
      );
      return right(sessionClient);
    } catch (e: any) {
      switch (e.body?.code) {
        case ErrorCode.SERVER_NOT_FOUND_FOR_SESSION_PLACEMENT:
          return left(new CanNotFindServerForSessionPlacement(e.body?.message));
        case ErrorCode.SESSION_NOT_FOUND:
          return left(new SessionNotFoundError(e.body?.message));
        case ErrorCode.CONFLICT:
          return left(new ConflictError(e.body?.message));
        default:
          const msg = 'Failed to start a new UserSession';
          DI.logger().error(msg);
          DI.logger().error(`Error: ${e.body?.code ?? 'unknown code'}: ${e.body?.message}`);
          return left(new UnexpectedError(msg));
      }
    }
  }

  private static _existedUserSessionHandlingType({
                                                   existedUserSessionHandlingType,
                                                 }: CreateSessionClientCommand): () => Promise<ExistedUserSessionHandlingType> {
    return async () =>
      existedUserSessionHandlingType ? existedUserSessionHandlingType() : 'update-existed-or-create-new';
  }

  private static async _handleExistedUserSession(
    cmd: CreateSessionClientCommand,
    session: SessionResource,
    server: ServerResource,
    client: Client,
  ): Promise<Either<
    UnexpectedError | ConflictError | SessionNotFoundError | CanNotFindServerForSessionPlacement,
    SessionClient
  >> {
    DI.logger().debug(`Try to attach this client to existed UserSessionId ${cmd.userSessionId}`);
    const type = await this._existedUserSessionHandlingType(cmd)();
    switch (type) {
      case 'cancel':
        return left(new ConflictError(
          `Such UserSession ${cmd.userSessionId} already exists, can not start new one with the same id`,
        ));
      case 'create-new':
        DI.logger().warn(
          `It is not allowed to use the UserSession ${cmd.userSessionId}, create a new UserSession instead`,
        );
        return this._handleNewUserSession(cmd, session, server, client);
      case 'update-existed-or-create-new':
        try {
          const userSession = session.userSessions.find((x) => x.userSessionId === cmd.userSessionId);
          if (!userSession) {
            DI.logger().warn(`UserSession ${cmd.userSessionId} does not exist, create new one.`);
            return this._handleNewUserSession(cmd, session, server, client);
          }
          await SessionApi.updateUserSessionConnectionId(session.sessionId, cmd.userSessionId as UserSessionId, {
            connectionId: client.id.value,
          });
          const sessionClient = new SessionClient(
            client,
            session.sessionId,
            server.id,
            server.publicWsEndpoint,
            cmd.userSessionId as UserSessionId,
            cmd.ownerId,
          );
          return right(sessionClient);
        } catch (e: any) {
          DI.logger().warn(`Failed to use ${cmd.userSessionId}, create new UserSession instead`);
          DI.logger().error(`Error: ${e.body?.code ?? 'unknown code'}: ${e.body?.message}`);
          return this._handleNewUserSession(cmd, session, server, client);
        }
      default:
        // noinspection UnnecessaryLocalVariableJS
        const unreachable: never = type;
        return left(new UnexpectedError(`Unhandled ExistedUserSessionHandlingType ${unreachable}`));
    }
  }

  private static async _buildNewSession(cmd: CreateSessionClientCommand): Promise<Either<
    UnexpectedError | ServerNotFoundError | CanNotFindServerForSessionPlacement,
    BuildSessionResult
  >> {
    try {
      const result = await SessionApi.createSession({
        type: CreateSessionCommand.type.OWN,
        serverId: cmd.serverId,
        maxRooms: cmd.maxRooms,
        maxUserSessions: cmd.maxMembers,
      });
      return right({
        server: result.server,
        session: result.session,
      });
    } catch (e: any) {
      switch (e.body?.code) {
        case ErrorCode.SERVER_NOT_FOUND:
          return left(new ServerNotFoundError(e.body?.message));
        case ErrorCode.SERVER_NOT_FOUND_FOR_SESSION_PLACEMENT:
          return left(new CanNotFindServerForSessionPlacement(e.body?.message));
        default:
          const msg = 'Failed to create a new Session';
          DI.logger().error(msg);
          DI.logger().error(`Error: ${e.body?.code ?? 'unknown code'}: ${e.body?.message}`);
          return left(new UnexpectedError(msg));
      }
    }
  }

  private static async _buildForSessionId(cmd: CreateSessionClientCommand): Promise<Either<
    UnexpectedError | ServerNotFoundError | CanNotFindServerForSessionPlacement,
    BuildSessionResult
  >> {
    const sessionId = cmd.sessionId as SessionId;
    let server: ServerResource;
    let session: SessionResource;
    try {
      const result = await SessionApi.getSession(sessionId);
      server = result.server;
      session = result.session;
    } catch (e: any) {
      if (e instanceof ApiError && e.body.code === ErrorCode.SESSION_NOT_FOUND) {
        if (cmd.createNewSessionIfExistedOneIsNotFound) {
          DI.logger().warn(`Failed to get an existed Session ${sessionId}, try to create a new Session`);
          return this._buildNewSession(cmd);
        } else {
          DI.logger().error(`Failed to get an existed Session ${sessionId}`);
          DI.logger().error(e);
          throw new Error(`The Session ${sessionId} not found`);
        }
      }
      throw new Error(`Failed to get an existed Session ${sessionId}`);
    }
    if (session.status === status.CLOSED) {
      if (cmd.createNewSessionIfExistedOneIsClosed) {
        return this._buildNewSession(cmd);
      } else {
        throw new SessionIsClosedError();
      }
    }
    return right({ server, session });
  }

  private static _buildClientConfig(cmd: CreateSessionClientCommand, server: ServerResource) {
    return {
      ws: {
        ...cmd.ws,
        url: server.publicWsEndpoint,
      },
      dcLabel: cmd.dcLabel,
      dc: {
        ...cmd.dc,
      },
      pc: {
        ...cmd.pc,
      },
      filteringAndBatchingConfigWS: {
        ...cmd.filteringAndBatchingConfigWS,
      },
      filteringAndBatchingConfigDC: {
        ...cmd.filteringAndBatchingConfigDC,
      },
    };
  }
}
