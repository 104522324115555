/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ServersStateResource = {
  $resourceType: ServersStateResource.$resourceType;
  /**
   * Int value >= 0
   */
  activeSessionsCount: number;
  /**
   * Int value >= 0
   */
  activeUserSessionsCount: number;
  /**
   * Sum of max(activeUserSessionsCount, reservedUserSessionsCount) per each Session
   */
  takenUserSessionsCount: number;
  /**
   * Int value > 0
   */
  maxUserSessionsCount: number;
  currentUserSessionsUsagePercent: number;
};

export namespace ServersStateResource {

  export enum $resourceType {
    SERVERS_STATE_RESOURCE = 'ServersStateResource',
  }


}

