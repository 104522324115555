/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserIdResource = {
  $resourceType: UserIdResource.$resourceType;
  /**
   * User id
   */
  userId: string;
};

export namespace UserIdResource {

  export enum $resourceType {
    USER_ID_RESOURCE = 'UserIdResource',
  }


}

