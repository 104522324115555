import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Pagination, Typography, Box,
} from '@mui/material';
import { SessionLogsResource, LogsApi, SessionLogResource } from '../../../generated/api-client';
import moment from 'moment';
import { SessionId } from '@own/pluto_client';
import { useEffect, useState, Fragment } from 'react';
import { useMsg } from '../../../contexts/msg-context';
import Uuid from '../../Common/Uuid';


export type BackendServiceSessionLogsProps = {
  sessionId: SessionId;
}

const sessionLogTagToColor = (tag: SessionLogResource.tag | null) => {
  switch (tag) {
    case SessionLogResource.tag.ROOM_ADDED:
      return 'success';
    case SessionLogResource.tag.ROOM_JOINED:
      return 'secondary';
    case SessionLogResource.tag.ROOM_LEFT:
      return 'warning';
    case SessionLogResource.tag.ROOM_REMOVED:
      return 'error';
    default:
      return 'default';
  }
};

export function SessionLogs({ sessionId }: BackendServiceSessionLogsProps) {
  const { showApiError } = useMsg();
  const [sessionLogs, setSessionLogs] = useState<SessionLogsResource | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    LogsApi.getSessionLogs(sessionId, page, 10).then((logs) => {
      setSessionLogs(logs);
    }).catch(showApiError);
  }, [page, sessionId, setSessionLogs, showApiError]);

  const onPageChange = (event: any, value: number) => {
    setPage(value);
  };

  return <Fragment>
    <Typography
      variant="h5"
      mb={1}
    >
      Session <Uuid id={sessionId} /> logs
    </Typography>
    <TableContainer>
      <Table size="small">
        <TableBody>
          {sessionLogs ? sessionLogs.data.map((log) => <TableRow
            key={log.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="right">
              {log.tag ? <Chip
                size="small"
                style={{ fontSize: '0.85em' }}
                color={sessionLogTagToColor(log.tag)}
                label={log.tag}
              /> : null}
            </TableCell>
            <TableCell style={{ fontSize: '0.85em' }}>
              {log.content}
            </TableCell>
            <TableCell style={{ fontSize: '0.85em', width: 160 }}>
              {moment(log.createdAt).fromNow()}
            </TableCell>
          </TableRow>) : null}
        </TableBody>
      </Table>
    </TableContainer>
    <Box mt={2}>
      <Pagination
        size="small"
        count={sessionLogs?.totalPages ?? 0}
        page={page}
        onChange={onPageChange}
      />
    </Box>
  </Fragment>;
}
