/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SessionResource } from './SessionResource';

export type SessionsResource = {
  $resourceType: SessionsResource.$resourceType;
  /**
   * Current page
   */
  page: number;
  /**
   * Total entries
   */
  total: number;
  /**
   * Page size
   */
  limit: number;
  /**
   * Total pages
   */
  totalPages: number;
  data: Array<SessionResource>;
};

export namespace SessionsResource {

  export enum $resourceType {
    SESSIONS_RESOURCE = 'SessionsResource',
  }


}

