import {
  Box,
  Card,
  CardContent,
} from '@mui/material';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useServersContext } from "../../../contexts/servers-context";
import { ServerInfo } from "./ServerInfo";
import { Nav } from "../../Common/Nav";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { ServerApi, ServerResource } from "../../../generated/api-client";
import { useMsg } from "../../../contexts/msg-context";
import { useWs } from "../../../contexts/ws-context";

export async function serverLoader({ params }: any): Promise<{ server: ServerResource | null }> {
  /**
   * @todo Make getServer method
   */
  const data = await ServerApi.listServers();
  const server = data.data.find((x) => x.id === params.serverId) ?? null;
  return { server };
}

export function Server() {
  const navigate = useNavigate();
  const { server } = useLoaderData() as { server: ServerResource | null };
  const { selectedServer, setSelectedServer } = useServersContext();
  const { showMessage, showApiError } = useMsg();
  const { on } = useWs();

  useEffect(() => setSelectedServer(server), [server, setSelectedServer]);

  const onBack = () => {
    navigate('/');
  }

  const loadServer = useCallback(() => {
    if (!selectedServer) {
      showMessage('Nothing to reload', 'error');
      return;
    }
    /**
     * @todo Make getServer method
     */
    ServerApi.listServers()
      .then((data) => {
        const server = data.data.find((x) => x.id === selectedServer.id) ?? null;
        setSelectedServer(server);
      })
      .catch(showApiError);
  }, [setSelectedServer, showMessage, showApiError, selectedServer]);

  useEffect(() => on('server.connected', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadServer();
    }
  }), [loadServer, selectedServer, on]);

  useEffect(() => on('server.disconnected', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadServer();
    }
  }), [loadServer, selectedServer, on]);

  useEffect(() => on('server.shutdown', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadServer();
    }
  }), [loadServer, selectedServer, on]);

  useEffect(() => on('server.scheduled-for-shutdown', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadServer();
    }
  }), [loadServer, selectedServer, on]);

  useEffect(() => on('server.shutdown-cancelled', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadServer();
    }
  }), [loadServer, selectedServer, on]);

  useEffect(() => on('server.removed', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadServer();
    }
  }), [loadServer, selectedServer, on]);

  return <Box>
    {selectedServer ? (<Box>
      <Card>
        <CardContent>
          <Fragment>
            <ServerInfo
              server={selectedServer}
              onBack={onBack}
            />
            <Nav current={0} items={[
              { label: 'Sessions', href: `/servers/${selectedServer.id}` },
            ]}/>
            <br/>
            <Outlet/>
          </Fragment>
        </CardContent>
      </Card>
    </Box>) : null}
  </Box>;
}
