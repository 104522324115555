/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BalancerConfigDto } from './BalancerConfigDto';
import type { TypeGameLiftPayloadDto } from './TypeGameLiftPayloadDto';
import type { TypeOwnPayloadDto } from './TypeOwnPayloadDto';

export type CreateServerCommand = {
  /**
   * WS Endpoint
   */
  internalWsEndpoint: string;
  /**
   * WS Endpoint
   */
  publicWsEndpoint: string;
  /**
   * ServerName
   */
  name?: string | null;
  /**
   * ServerType
   */
  type: CreateServerCommand.type;
  /**
   * If not set, the Group config value will be used. If Group does not contain this value, the default value will be used
   */
  maxSessions?: number | null;
  /**
   * If not set, the Group config value will be used. If Group does not contain this value, the default value will be used
   */
  maxUserSessions?: number | null;
  /**
   * Type specific payload
   */
  typePayload: (TypeOwnPayloadDto | TypeGameLiftPayloadDto);
  /**
   * Balancer config
   */
  balancerConfig: BalancerConfigDto;
};

export namespace CreateServerCommand {

  /**
   * ServerType
   */
  export enum type {
    OWN = 'own',
    GAME_LIFT = 'game-lift',
  }


}

