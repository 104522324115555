/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRoomCommand } from '../models/CreateRoomCommand';
import type { CreateSessionCommand } from '../models/CreateSessionCommand';
import type { CreateSessionResultResource } from '../models/CreateSessionResultResource';
import type { CreateUserSessionCommand } from '../models/CreateUserSessionCommand';
import type { GetSessionResultResource } from '../models/GetSessionResultResource';
import type { JoinRoomCommand } from '../models/JoinRoomCommand';
import type { ListSessionsFilterDto } from '../models/ListSessionsFilterDto';
import type { RoomResource } from '../models/RoomResource';
import type { RoomsResource } from '../models/RoomsResource';
import type { SessionPayloadResource } from '../models/SessionPayloadResource';
import type { SessionResource } from '../models/SessionResource';
import type { SessionsResource } from '../models/SessionsResource';
import type { SuccessResource } from '../models/SuccessResource';
import type { TransferRoomHostCommand } from '../models/TransferRoomHostCommand';
import type { TransferSessionHostCommand } from '../models/TransferSessionHostCommand';
import type { UpdateSessionCommand } from '../models/UpdateSessionCommand';
import type { UpdateSessionPayloadCommand } from '../models/UpdateSessionPayloadCommand';
import type { UpdateUserSessionCommand } from '../models/UpdateUserSessionCommand';
import type { UpdateUserSessionPayloadCommand } from '../models/UpdateUserSessionPayloadCommand';
import type { UserSessionPayloadResource } from '../models/UserSessionPayloadResource';
import type { UserSessionResource } from '../models/UserSessionResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SessionApi {

  /**
   * Create a session
   * @param requestBody
   * @returns CreateSessionResultResource Created session
   * @throws ApiError
   */
  public static createSession(
    requestBody: CreateSessionCommand,
  ): CancelablePromise<CreateSessionResultResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/sessions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Server not found; Can not find a server for the new session placement`,
        409: `No free session slots were found on this server; Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * List sessions
   * @param page Page number
   * @param limit Number of elements per page
   * @param filter Filtering options
   * @param orderBy Sorting field
   * @param orderDirection Sorting direction
   * @returns SessionsResource Sessions
   * @throws ApiError
   */
  public static listSessions(
    page: number = 1,
    limit: number = 25,
    filter?: ListSessionsFilterDto,
    orderBy?: 'createdAt',
    orderDirection?: 'ASC' | 'DESC',
  ): CancelablePromise<SessionsResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/sessions',
      query: {
        'page': page,
        'limit': limit,
        'filter': filter,
        'orderBy': orderBy,
        'orderDirection': orderDirection,
      },
      errors: {
        400: `Bad request`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Close a session
   * @param sessionId
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static closeSession(
    sessionId: string,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/sessions/{sessionId}',
      path: {
        'sessionId': sessionId,
      },
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Session not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Get a session
   * @param sessionId
   * @returns GetSessionResultResource Get Session result
   * @throws ApiError
   */
  public static getSession(
    sessionId: string,
  ): CancelablePromise<GetSessionResultResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/sessions/{sessionId}',
      path: {
        'sessionId': sessionId,
      },
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Session not found; Server not found`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Update a session
   * @param sessionId
   * @param requestBody
   * @returns SessionResource Updated session
   * @throws ApiError
   */
  public static updateSession(
    sessionId: string,
    requestBody: UpdateSessionCommand,
  ): CancelablePromise<SessionResource> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/sessions/{sessionId}',
      path: {
        'sessionId': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted; Operation not permitted`,
        404: `Session not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Create a room
   * @param sessionId
   * @param requestBody
   * @returns RoomResource Created room
   * @throws ApiError
   */
  public static createRoom(
    sessionId: string,
    requestBody: CreateRoomCommand,
  ): CancelablePromise<RoomResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/sessions/{sessionId}/rooms',
      path: {
        'sessionId': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted; Session maximum rooms count reached`,
        404: `Server not found; UserSession not found; Session not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
        502: `Server client timeout`,
      },
    });
  }

  /**
   * Close a room
   * @param sessionId
   * @param roomId RoomId or RemoteRoomId
   * @returns RoomsResource Closed rooms
   * @throws ApiError
   */
  public static closeRoom(
    sessionId: string,
    roomId: string,
  ): CancelablePromise<RoomsResource> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/sessions/{sessionId}/rooms/{roomId}',
      path: {
        'sessionId': sessionId,
        'roomId': roomId,
      },
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Room not found; Server not found; Session not found`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Create a user session
   * @param sessionId
   * @param requestBody
   * @returns UserSessionResource Created user session
   * @throws ApiError
   */
  public static createUserSession(
    sessionId: string,
    requestBody: CreateUserSessionCommand,
  ): CancelablePromise<UserSessionResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/sessions/{sessionId}/user-sessions',
      path: {
        'sessionId': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted; Session maximum user sessions count reached; Server maximum user sessions count reached`,
        404: `Server not found; Session not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Remove the user session
   * @param sessionId
   * @param connectionIdToRemove
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static removeUserSession(
    sessionId: string,
    connectionIdToRemove: string,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/sessions/{sessionId}/user-sessions/{connectionIdToRemove}',
      path: {
        'sessionId': sessionId,
        'connectionIdToRemove': connectionIdToRemove,
      },
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted; Session maximum user sessions count reached; Server maximum user sessions count reached`,
        404: `Server not found; Session not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Update a user session ConnectionId
   * @param sessionId
   * @param userSessionId
   * @param requestBody
   * @returns SessionResource Updated session
   * @throws ApiError
   */
  public static updateUserSessionConnectionId(
    sessionId: string,
    userSessionId: string,
    requestBody: UpdateUserSessionCommand,
  ): CancelablePromise<SessionResource> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/sessions/{sessionId}/user-sessions/{userSessionId}',
      path: {
        'sessionId': sessionId,
        'userSessionId': userSessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Session not found; UserSession not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Update Session payload content
   * @param sessionId
   * @param requestBody
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static updateSessionPayload(
    sessionId: string,
    requestBody: UpdateSessionPayloadCommand,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/sessions/{sessionId}/payload',
      path: {
        'sessionId': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        404: `Session not found`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Get Session payload content
   * @param sessionId
   * @returns SessionPayloadResource Payload content
   * @throws ApiError
   */
  public static getSessionPayload(
    sessionId: string,
  ): CancelablePromise<SessionPayloadResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/sessions/{sessionId}/payload',
      path: {
        'sessionId': sessionId,
      },
      errors: {
        400: `Bad request`,
        404: `Session not found`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Update UserSession payload content
   * @param userSessionId
   * @param requestBody
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static updateUserSessionPayload(
    userSessionId: string,
    requestBody: UpdateUserSessionPayloadCommand,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/user-sessions/{userSessionId}/payload',
      path: {
        'userSessionId': userSessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        404: `Session not found; UserSession not found`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Get UserSession payload content
   * @param userSessionId
   * @returns UserSessionPayloadResource Payload content
   * @throws ApiError
   */
  public static getUserSessionPayload(
    userSessionId: string,
  ): CancelablePromise<UserSessionPayloadResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/user-sessions/{userSessionId}/payload',
      path: {
        'userSessionId': userSessionId,
      },
      errors: {
        400: `Bad request`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Join a room
   * @param sessionId
   * @param requestBody
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static joinRoom(
    sessionId: string,
    requestBody: JoinRoomCommand,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/sessions/{sessionId}/user-sessions/join-room',
      path: {
        'sessionId': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `UserSession not found; Room not found; Server not found; Session not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
        502: `Server client timeout`,
      },
    });
  }

  /**
   * Leave a room
   * @param sessionId
   * @param remoteRoomId
   * @param userSessionId
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static leaveRoom(
    sessionId: string,
    remoteRoomId: string,
    userSessionId: string,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/sessions/{sessionId}/user-sessions/{userSessionId}/rooms/{remoteRoomId}',
      path: {
        'sessionId': sessionId,
        'remoteRoomId': remoteRoomId,
        'userSessionId': userSessionId,
      },
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Room not found; Server not found; Session not found; UserSession not found`,
        500: `Unexpected error`,
        502: `Server client timeout`,
      },
    });
  }

  /**
   * Transfer the Room host
   * @param sessionId
   * @param remoteRoomId
   * @param requestBody
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static transferRoomHost(
    sessionId: string,
    remoteRoomId: string,
    requestBody: TransferRoomHostCommand,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/sessions/{sessionId}/rooms/{remoteRoomId}/transfer-host',
      path: {
        'sessionId': sessionId,
        'remoteRoomId': remoteRoomId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Session not found; Room not found; UserSession not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Transfer the Session host
   * @param sessionId
   * @param requestBody
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static transferHost(
    sessionId: string,
    requestBody: TransferSessionHostCommand,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/sessions/{sessionId}/transfer-host',
      path: {
        'sessionId': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Session not found; UserSession not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

}
