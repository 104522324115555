import React, { Fragment, useEffect, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { Nav } from "../../Common/Nav";

export function ServersPage() {
  const location = useLocation();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setCurrent(location.pathname.startsWith('/groups') ? 1 : 0);
  }, [location.pathname]);

  return <Fragment>
    <Nav current={current} items={[
      { label: 'Servers', href: '/' },
      { label: 'Groups', href: '/groups' },
    ]}/>
    <Outlet/>
  </Fragment>;
}
