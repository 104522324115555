import {ClientError, DI, PlutoServerError} from "@own/pluto_client";
import {useSnackbar} from "notistack";
import {useCallback} from "react";

export function useErrorHandler() {
    const {enqueueSnackbar} = useSnackbar();
    return useCallback((error: any) => {
        if (error instanceof ClientError || error instanceof PlutoServerError) {
            DI.logger().warn(error);
            enqueueSnackbar(error.message, {variant: "warning"});
            return;
        }
        DI.logger().error(error);
        const message = (() => {
            if (error instanceof Error) { return error.message; }
            if (error instanceof CloseEvent) { return error.reason; }
            if (error instanceof Event) { return `Got event: ${error.type}`; }
            return error;
        })();
        if (message) {
            enqueueSnackbar(message, {variant: "error"});
        }
    }, [enqueueSnackbar]);
}
