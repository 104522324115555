/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RoomResource } from './RoomResource';

export type RoomsResource = {
  $resourceType: RoomsResource.$resourceType;
  data: Array<RoomResource>;
};

export namespace RoomsResource {

  export enum $resourceType {
    ROOMS_RESOURCE = 'RoomsResource',
  }


}

