/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GroupTaskResource } from './GroupTaskResource';

export type GroupTasksResource = {
  $resourceType: GroupTasksResource.$resourceType;
  /**
   * Current page
   */
  page: number;
  /**
   * Total entries
   */
  total: number;
  /**
   * Page size
   */
  limit: number;
  /**
   * Total pages
   */
  totalPages: number;
  data: Array<GroupTaskResource>;
};

export namespace GroupTasksResource {

  export enum $resourceType {
    GROUP_TASKS_RESOURCE = 'GroupTasksResource',
  }


}

