import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack
} from '@mui/material';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { SessionResource } from "../../../generated/api-client";
import SessionStatus from "./session-status";

export type SessionFilterValue = {
  statuses: SessionResource.status[];
};

export type SessionsFilterProps = {
  onChange: (value: SessionFilterValue) => void;
};

const allStatuses = Object.values(SessionResource.status);

const SessionsFilter: FC<SessionsFilterProps> = ({ onChange }: SessionsFilterProps) => {
  const [statuses, setStatuses] = useState<SessionResource.status[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof statuses>) => {
    const {
      target: { value },
    } = event;
    setStatuses(
      typeof value === 'string' ? value.split(',') as any : value,
    );
  };

  useEffect(() => {
    onChange({ statuses });
  }, [statuses]);

  return <Stack direction="row">
    <FormControl
      sx={{ minWidth: 110 }}
      size="small"
    >
      <InputLabel>Status</InputLabel>
      <Select
        id="demo-multiple-chip"
        multiple
        value={statuses}
        onChange={handleChange}
        input={<OutlinedInput label="Status"/>}
        renderValue={(selected) => {
          return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <SessionStatus key={value} status={value}/>
            ))}
          </Box>
        }}
      >
        {allStatuses.map((x) => (
          <MenuItem key={x} value={x}>{x}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </Stack>
};

export default SessionsFilter;
