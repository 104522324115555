/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ServerResource } from './ServerResource';
import type { SessionResource } from './SessionResource';

export type GetSessionResultResource = {
  $resourceType: GetSessionResultResource.$resourceType;
  session: SessionResource;
  server: ServerResource;
};

export namespace GetSessionResultResource {

  export enum $resourceType {
    GET_SESSION_RESULT_RESOURCE = 'GetSessionResultResource',
  }


}

