import { EventEmitter } from 'eventemitter3';
import ts from 'typescript/lib/tsserverlibrary';
import {
  BackendServiceMessage,
  BackendServiceMessageHostRoomChanged,
  BackendServiceMessageSessionHostChanged,
  BackendServiceMessageSessionPayloadChanged,
  BackendServiceMessageUserSessionDropConnectionId,
  BackendServiceMessageUserSessionPayloadChanged,
} from '../backend-service';
import { Client, ClientMsgOptArgs, IBackendMessageEvent, IBackendRoomMessageEvent } from '../client';
import * as DI from '../DI/Logger';
import { Either, Left, left, right } from '../either';
import { BackendServiceMessageType, ErrorCode } from '../enums';
import {
  CanNotFindServerForSessionPlacement,
  ConflictError, OperationNotPermittedError,
  RoomNotFoundError,
  ServerClientTimeoutError,
  ServerNotFoundError, SessionIsClosedError,
  SessionNotFoundError,
  UnexpectedError,
  UserSessionNotFoundError,
} from '../errors';
import { ApiError, CreateRoomCommand, SessionApi, SuccessResource } from '../generated/backend-service-client';
import { RequestHeader } from '../Headers';
import { ConnectionId, RoomId } from '../Id';
import { CreateSessionClientCommand, SessionClientStatus, SessionClientEvents } from './';
import { SessionClientBuilder } from './session-client-builder';
import { TimeInfo } from '../TimeSyncService';
import { ChannelType } from '../Transport';
import Session = ts.server.Session;

export type OwnerId = string;
export type SessionId = string;
export type UserSessionId = string;
export type ServerId = string;

type Class<T> = new (message?: string) => T;

export class SessionClient {
  private readonly _events = new EventEmitter<SessionClientEvents>();
  private _status: SessionClientStatus = SessionClientStatus.DISCONNECTED;

  get timeInfoWS(): TimeInfo {
    return this.client.timeInfo(ChannelType.websocket);
  }

  get timeInfoDC(): TimeInfo {
    return this.client.timeInfo(ChannelType.datachannel);
  }

  get events() {
    return this._events;
  }

  close() {
    this._client.close();
  }

  get status() {
    return this._status;
  }

  get client() {
    return this._client;
  }

  get sessionId() {
    return this._sessionId;
  }

  get userSessionId() {
    return this._userSessionId;
  }

  get connectionId(): ConnectionId {
    return this._client.id;
  }

  get serverId(): string {
    return this._serverId;
  }

  /**
   * @param _client The pluto Client instance
   * @param _sessionId The SessionId from BackendService
   * @param _serverId The ServerId from BackendService
   * @param publicWsEndpoint The URL of the public ws endpoint.
   * @param _userSessionId
   * @param _ownerId
   */
  constructor(
    private readonly _client: Client,
    private readonly _sessionId: SessionId,
    private readonly _serverId: ServerId,
    readonly publicWsEndpoint: string,
    private readonly _userSessionId: UserSessionId,
    private readonly _ownerId: OwnerId,
  ) {
    _client.events.on('messageBackend', (e) => this._onBackendMessage(e));
    _client.events.on('roomwideBroadcastBackend', (e) => this._onBackendMessage(e));
    _client.events.on('close', () => {
      this._events.emit('connectionClosed');
      this._setStatus(SessionClientStatus.CLOSED);
    });
    _client.events.on('error', () => {
      this._events.emit('connectionFailed');
      this._setStatus(SessionClientStatus.ERROR);
    });
    this._events.emit('connected');
    this._setStatus(SessionClientStatus.CONNECTED);
  }

  /**
   * If no SessionId is provided:
   *  - Creates a new Session.
   *  - Creates a new UserSession (ignore UserSessionId if passed)
   *
   * If the SessionId is provided:
   *  - Tries to get existed Session when sessionId is specified.
   *      - If no Session found, creates a new Session
   *  - If UserSessionId is passed:
   *      - Try to get use this UserSession (???)
   *  - If UserSessionId is empty -- create a new UserSession
   */
  static async create(
    cmd: CreateSessionClientCommand,
  ): Promise<Either<
    UnexpectedError
    | SessionNotFoundError
    | ServerNotFoundError
    | ConflictError
    | CanNotFindServerForSessionPlacement
    | SessionIsClosedError,
    SessionClient
  >> {
    return SessionClientBuilder.build(cmd);
  }

  /**
   * This method creates a new UserSession on BackendService.
   *
   * @param connectionId The ConnectionId who starts the UserSession
   * @param ownerId The OwnerId to identify the user/initiator of this action
   * @param tag The optional string tag for the UserSession
   */
  async createUserSession(connectionId: ConnectionId, ownerId: string, tag: string | null = null) {
    return this._apiRequest(
      SessionApi.createUserSession(this._sessionId, {
        connectionId: connectionId.value,
        ownerId,
        tag,
      }),
      [SessionNotFoundError, ConflictError],
      `Failed to create the user session`,
    );
  }

  /**
   * This method creates a new UserSession on BackendService.
   *
   * @param connectionId The ConnectionId who starts the UserSession
   * @param ownerId The OwnerId to identify the user/initiator of this action
   * @param tag The optional string tag for the UserSession
   */
  async removeUserSession(connectionId: ConnectionId) {
    return this._apiRequest(
      SessionApi.removeUserSession(this._sessionId, connectionId.value.toString()),
      [SessionNotFoundError, UserSessionNotFoundError, OperationNotPermittedError, ConflictError],
      `Failed to remove the user session`,
    );
  }

  async getSessionPayload() {
    return this._apiRequest(
      SessionApi.getSessionPayload(this._sessionId),
      [SessionNotFoundError],
      `Failed to get the session payload`,
    );
  }

  async updateSessionPayload(content: Record<string, any>) {
    return this._apiRequest(
      SessionApi.updateSessionPayload(this._sessionId, { content }),
      [SessionNotFoundError],
      `Failed to update the session payload`,
    );
  }

  async getUserSessionPayload(userSessionId: string) {
    return this._apiRequest(
      SessionApi.getUserSessionPayload(userSessionId),
      [UnexpectedError],
      `Failed to get the user session payload`,
    );
  }

  async updateUserSessionPayload(userSessionId: string, content: Record<string, any>) {
    return this._apiRequest(
      SessionApi.updateUserSessionPayload(userSessionId, { content }),
      [SessionNotFoundError, UserSessionNotFoundError],
      `Failed to update the user session payload`,
    );
  }

  async roomExistsOnServer(roomId: RoomId | number) {
    const rooms = await this._client.listRooms();
    const id = roomId instanceof RoomId ? roomId.value : roomId;
    return !!rooms.find((x) => x.value === id);
  }

  async createRoom(cmd?: CreateRoomCommand) {
    return this._apiRequest(
      SessionApi.createRoom(
        this._sessionId,
        cmd ?? {
          tag: undefined,
        },
      ),
      [SessionNotFoundError, ServerNotFoundError, ConflictError],
      `Failed to create the room`,
    );
  }

  async joinRoom(roomId: RoomId | number) {
    return this._apiRequest(
      new Promise<SuccessResource>(async (resolve, reject) => {
        const id = roomId instanceof RoomId ? roomId : new RoomId(roomId);
        /**
         * Register immediately to be ready to accept the ws event 'joinRoom'
         * earlier than this HTTP request will be resolved
         */
        this.client.registerJoinedRoom(id);
        DI.logger().debug(`Join room ${id.value} by ${this.connectionId.value}, ownerId ${this._ownerId}`);
        SessionApi.joinRoom(this._sessionId, {
          remoteRoomId: id.value,
          connectionId: this.connectionId.value,
          ownerId: this._ownerId,
        })
          .then(resolve)
          .catch((e: any) => {
            this.client.unregisterJoinedRoom(id);
            reject(e);
          });
      }),
      [ServerClientTimeoutError, ConflictError, ServerNotFoundError, SessionNotFoundError, RoomNotFoundError],
      'Failed to join the room',
    );
  }

  async leaveRoom(roomId: RoomId | number) {
    const id = roomId instanceof RoomId ? roomId : new RoomId(roomId);
    DI.logger().debug(`Leave room ${id.value} by ${this.connectionId.value}`);
    return this._apiRequest(
      SessionApi.leaveRoom(this._sessionId, id.value.toString(), this.userSessionId)
        .then(() => this.client.unregisterJoinedRoom(id)),
      [ServerClientTimeoutError, ConflictError, ServerNotFoundError, SessionNotFoundError, RoomNotFoundError],
      'Failed to leave the room',
    );
  }

  /**
   * @param roomId RoomId or RemoteRoomId
   */
  closeRoom(roomId: string | number) {
    return this._apiRequest(
      SessionApi.closeRoom(this._sessionId, roomId.toString()),
      [ServerClientTimeoutError, ConflictError, ServerNotFoundError, SessionNotFoundError, RoomNotFoundError],
      'Failed to close the room',
    );
  }

  async getSession() {
    return this._apiRequest(
      SessionApi.getSession(this.sessionId),
      [SessionNotFoundError, ServerNotFoundError],
      'Failed to close the room',
    );
  }

  roomWideBroadcastWS(message: ArrayBuffer, roomId: RoomId, opts: ClientMsgOptArgs): RequestHeader {
    return this.client.roomwideBroadcast(ChannelType.websocket, message, roomId, opts);
  }

  roomWideBroadcastDC(message: ArrayBuffer, roomId: RoomId, opts: ClientMsgOptArgs): RequestHeader {
    return this.client.roomwideBroadcast(ChannelType.datachannel, message, roomId, opts);
  }

  messageWS(message: ArrayBuffer, recipients: ConnectionId[], opts: ClientMsgOptArgs): RequestHeader {
    return this.client.message(ChannelType.websocket, message, recipients, opts);
  }

  messageDC(message: ArrayBuffer, recipients: ConnectionId[], opts: ClientMsgOptArgs): RequestHeader {
    return this.client.message(ChannelType.datachannel, message, recipients, opts);
  }

  listRoomConnections(roomId: RoomId) {
    return this.client.listRoomConnections(roomId);
  }

  // noinspection JSUnusedLocalSymbols
  private _onRoomHostChanged(payload: BackendServiceMessageHostRoomChanged) {
    /**
     * @todo update hosts info or something
     */
  }

  // noinspection JSUnusedLocalSymbols
  private _onSessionHostChanged(payload: BackendServiceMessageSessionHostChanged) {
    /**
     * @todo update hosts info or something
     */
  }

  async _apiRequest<T, E extends Class<any>>(
    request: Promise<T>,
    expectedErrors: E[],
    message: string,
  ): Promise<Either<UnexpectedError | InstanceType<E>, T>> {
    try {
      const result = await request;
      return right(result);
    } catch (e: unknown) {
      return this._handleError(e, expectedErrors, message);
    }
  }

  private _onBackendMessage(event: IBackendMessageEvent | IBackendRoomMessageEvent) {
    try {
      const decoded = JSON.parse(event.body) as BackendServiceMessage;
      switch (decoded.type) {
        case BackendServiceMessageType.SESSION_HOST_CHANGED: {
          const payload = decoded.payload as BackendServiceMessageSessionHostChanged;
          this._onSessionHostChanged(payload);
          this._events.emit('sessionHostChanged', payload);
          break;
        }
        case BackendServiceMessageType.ROOM_HOST_CHANGED: {
          const payload = decoded.payload as BackendServiceMessageHostRoomChanged;
          this._onRoomHostChanged(payload);
          this._events.emit('roomHostChanged', payload);
          break;
        }
        case BackendServiceMessageType.SESSION_PAYLOAD_CHANGED: {
          const payload = decoded.payload as BackendServiceMessageSessionPayloadChanged;
          this._events.emit('sessionPayloadChanged', payload);
          break;
        }
        case BackendServiceMessageType.USER_SESSION_PAYLOAD_CHANGED: {
          const payload = decoded.payload as BackendServiceMessageUserSessionPayloadChanged;
          this._events.emit('userSessionPayloadChanged', payload);
          break;
        }
        case BackendServiceMessageType.USER_SESSION_DROP_CONNECTION_ID: {
          const payload = decoded.payload as BackendServiceMessageUserSessionDropConnectionId;
          this._events.emit('userSessionDropConnectionId', payload);
          break;
        }
        default:
          const unreachable: never = decoded.type;
          DI.logger().warn(`Unhandled backend message type ${unreachable}`);
      }
    } catch (e: any) {
      DI.logger().error(`SessionClient on backend message: Failed to decode received message`);
      DI.logger().error(e);
    }
  }

  private _setStatus(status: SessionClientStatus) {
    if (this._status !== status) {
      this._status = status;
      this._events.emit('connectionStatusChanged', status);
    }
  }

  private _handleError<T extends Class<any>>(
    e: unknown,
    expectedErrors: T[],
    message?: string,
  ): Left<InstanceType<T> | UnexpectedError, any> {
    if (e instanceof ApiError) {
      for (const expectedError of expectedErrors) {
        const code = e.body.code as ErrorCode;
        if (code === ((expectedError as any).code as ErrorCode)) {
          return left(new expectedError(e.body.message));
        }
      }
      if (e.body.code === ErrorCode.UNEXPECTED_ERROR) {
        return left(new UnexpectedError(e.body.message));
      }
    }
    return this._unexpectedError(e, message);
  }

  private _unexpectedError(e: unknown, message?: string): Left<UnexpectedError, any> {
    if (message) {
      DI.logger().error(message);
    }
    DI.logger().error(e);
    return left(new UnexpectedError(message));
  }
}
