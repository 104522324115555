/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GroupInfoResource = {
  $resourceType: GroupInfoResource.$resourceType;
  desiredCapacity?: number;
  maxSize?: number;
  minSize?: number;
  status?: string;
};

export namespace GroupInfoResource {

  export enum $resourceType {
    GROUP_INFO_RESOURCE = 'GroupInfoResource',
  }


}

