import React, { Fragment, useState } from 'react';
import { ServersConfigResource } from '../../../generated/api-client';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

export function BackendServiceConfig({ config }: { config: ServersConfigResource }) {
  const [isOpen, setIsOpen] = useState(false);

  return <Stack sx={{ fontSize: '0.85em' }}>
    <Box>
      <Tooltip title="Show config">
        <IconButton
          size="small"
          onClick={() => setIsOpen(true)}
        >
          <SettingsIcon fontSize="inherit"/>
        </IconButton>
      </Tooltip>
    </Box>
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        style={{
          margin: 'auto',
          width: 600,
          maxHeight: 800,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            mb={2}
          >
            Servers configuration
          </Typography>
          <Fragment>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Property
                    </TableCell>
                    <TableCell align="right">
                      Value
                    </TableCell>
                    <TableCell align="right">
                      Unit
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Closed Session lifetime
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      {config.closedSessionLifetimeMinutes}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      minutes
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Empty Rooms lifetime
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      {config.emptyRoomLifetimeSeconds}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      seconds
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      UserSession expiration time
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      {config.userSessionExpirationTimeSeconds}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      seconds
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Overall ActiveUserSessions threshold (bottom)
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      {config.overallActiveUserSessionsThresholdBottom}%
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      percent
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Overall ActiveUserSessions threshold (up)
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      {config.overallActiveUserSessionsThresholdUp}%
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      percent
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        </CardContent>
      </Card>
    </Modal>
  </Stack>;
}
