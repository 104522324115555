import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  BalancerApi,
  GroupResource,
  GroupTasksResource,
  ServerApi, ServerResource,
  ServersResource
} from "../../../generated/api-client";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent, Chip,
  IconButton,
  Paper,
  Stack,
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Uuid from "../../Common/Uuid";
import Gray from "../../Common/Gray";
import moment from "moment";
import { useMsg } from "../../../contexts/msg-context";
import GroupTaskStatus from './GroupTaskStatus';
import { JsonView, darkStyles } from 'react-json-view-lite';
import { StyleProps } from "react-json-view-lite/dist/DataRenderer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GroupInfo } from "./group-info";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useWs } from "../../../contexts/ws-context";

export const JsonStyle: StyleProps = {
  ...darkStyles,
  container: 'transparent'
}

export async function groupLoader({ params }: any): Promise<{ group: GroupResource | null }> {
  /**
   * @todo Get group method
   */
  const data = await BalancerApi.listGroups();
  const group = data.data.find((x) => x.id === params.groupId) ?? null;
  return { group };
}

export function Group() {
  const navigate = useNavigate();
  const { group: loaderGroup } = useLoaderData() as { group: GroupResource | null };
  const [group] = useState<GroupResource | null>(loaderGroup);
  const [groupTasks, setGroupTasks] = useState<GroupTasksResource | null>(null);
  const [groupServers, setGroupServers] = useState<ServersResource | null>(null);
  const { showMessage, showApiError } = useMsg();
  const { on } = useWs();

  const onBack = useCallback(() => navigate(`/groups`), [navigate]);

  const load = useCallback(() => {
    if (group) {
      BalancerApi.listGroupTasks(group.id).then(setGroupTasks).catch(showApiError)
      ServerApi.listServers(1, 100, {
        groupId: group.id,
      }).then(setGroupServers).catch(showApiError);
    } else {
      setGroupTasks(null);
      setGroupServers(null);
    }
  }, [group, showApiError]);

  useEffect(() => load(), [load]);

  useEffect(() => on('balancer.group.deleted', (payload: any) => {
    if (payload.groupId === group?.id) {
      showMessage('Group was deleted', 'warning');
      onBack();
    }
  }), [onBack, showMessage, on, group?.id]);

  useEffect(() => on('balancer.group-task.created', (payload: any) => {
    if (payload.groupId === group?.id) {
      load();
    }
  }), [load, group?.id, on]);

  useEffect(() => on('balancer.group-task.deleted', (payload: any) => {
    if (payload.groupId === group?.id) {
      load();
    }
  }), [load, group?.id, on]);

  useEffect(() => on('balancer.group-task.cancelled', (payload: any) => {
    if (payload.groupId === group?.id) {
      load();
    }
  }), [load, group?.id, on]);

  useEffect(() => on('balancer.group-task.failed', (payload: any) => {
    if (payload.groupId === group?.id) {
      load();
    }
  }), [load, group?.id, on]);

  const onServerOpen = (server: ServerResource) => {
    navigate(`/servers/${server.id}`);
  };

  const onChangeCapacity = () => {
    if (!group) {
      showMessage('No group selected', 'warning');
      return;
    }
    const value = prompt('Enter the new capacity value:');
    if (!value) {
      showMessage('Empty value', 'warning');
      return;
    }
    const capacity = parseInt(value);
    if (isNaN(capacity) || capacity < 0 || !isFinite(capacity)) {
      showMessage('Wrong value', 'warning');
      return;
    }
    BalancerApi.setGroupCapacity(group.id, { capacity }).then(() => {
      showMessage('Capacity updated!', 'success');
      load();
    }).catch(showApiError);
  }

  return <Box>
    {group ? (<Box>
      <Card>
        <CardContent>
          <Fragment>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Tooltip title="Go back">
                <IconButton size="small" onClick={onBack}>
                  <ArrowBackIcon fontSize="inherit"/>
                </IconButton>
              </Tooltip>
              <Uuid id={group.id}/>
              <Chip
                size="small"
                label={group.code}
              />

              <Tooltip title="Change desired capacity">
                <IconButton
                  size="small"
                  onClick={onChangeCapacity}
                >
                  <UnfoldMoreIcon fontSize="inherit"/>
                </IconButton>
              </Tooltip>

              <GroupInfo group={group}/>

              <Box flex={1}/>
              <div>
                Created at: <Gray>{moment(group.createdAt).fromNow()}</Gray>
              </div>
              <Chip
                size="small"
                color="info"
                label={group.type}
              />
            </Stack>

            <Typography
              variant="h6"
              mt={2}
              mb={2}
            >
              Group Servers
            </Typography>

            <TableContainer component={Paper}>
              <Table
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Id
                    </TableCell>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupServers?.data.map((x) => <TableRow key={x.id}>
                    <TableCell>
                      <Uuid id={x.id}/>
                    </TableCell>
                    <TableCell>
                      {x.name}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="View the Server">
                        <IconButton size="small" onClick={() => onServerOpen(x)}>
                          <VisibilityIcon fontSize="inherit"/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography
              variant="h6"
              mt={2}
              mb={2}
            >
              Group tasks
            </Typography>

            <TableContainer component={Paper}>
              <Table
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell/>
                    <TableCell>
                      Type
                    </TableCell>
                    <TableCell>
                      Status
                    </TableCell>
                    <TableCell>
                      Payload
                    </TableCell>
                    <TableCell>
                      Result payload
                    </TableCell>
                    <TableCell>
                      Created&nbsp;at
                    </TableCell>
                    <TableCell>
                      Completed&nbsp;at
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupTasks ? groupTasks.data.map((x) => <TableRow key={x.id}>
                    <TableCell>
                      <Uuid id={x.id}/>
                    </TableCell>
                    <TableCell>
                      {x.type}
                    </TableCell>
                    <TableCell>
                      <GroupTaskStatus status={x.status}/>
                    </TableCell>
                    <TableCell>
                      {x.payload ? <JsonView data={x.payload} style={JsonStyle}/> : null}
                    </TableCell>
                    <TableCell>
                      {x.resultPayload ? <JsonView data={x.resultPayload} style={JsonStyle}/> : null}
                    </TableCell>
                    <TableCell>
                      {moment(x.createdAt).fromNow()}
                    </TableCell>
                    <TableCell>
                      {x.completedAt ? moment(x.completedAt).fromNow() : null}
                    </TableCell>
                  </TableRow>) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        </CardContent>
      </Card>
    </Box>) : null}
  </Box>;
}
