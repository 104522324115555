import {
  Alert, AlertColor,
  Snackbar,
} from '@mui/material';
import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {ApiError} from '../../generated/api-client';
import {apiErrorMessage} from '../../helpers/api';
import {AbstractSessionClientError} from "@own/pluto_client";

export type SnackProps = {
  isActive: boolean;
  message: string;
  type?: AlertColor;
}

const Snack: FC<SnackProps> = (props: SnackProps) => {
  const {isActive, message, type} = props;
  return (
    <Snackbar
      anchorOrigin={{horizontal: 'right', vertical: 'top'}}
      open={isActive}
      message={message}
    >
      <Alert
        severity={type || 'info'}
        sx={{width: '100%'}}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export function useSnack() {
  const [isSnackActive, setIsSnackActive] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackType, setSnackType] = useState<AlertColor>('info');

  useEffect(() => {
    if (isSnackActive) {
      setTimeout(() => {
        setIsSnackActive(false);
      }, 6000);
    }
  }, [isSnackActive]);

  const openSnack = (msg: string, type: AlertColor = 'info') => {
    setSnackMessage(msg);
    setIsSnackActive(true);
    setSnackType(type);
  };

  const showApiError = (e: ApiError | AbstractSessionClientError, message?: string) => {
    openSnack(apiErrorMessage(e, message), 'error');
  };

  return {isSnackActive, snackMessage, snackType, openSnack, showApiError};
}

export default React.memo(Snack);
