/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SuccessResource = {
  $resourceType: SuccessResource.$resourceType;
  success: boolean;
};

export namespace SuccessResource {

  export enum $resourceType {
    SUCCESS_RESOURCE = 'SuccessResource',
  }


}

