import { Stack, Chip, Card, CardContent, Box, Tooltip, IconButton, Button } from '@mui/material';
import { useBackendServiceContext, TheClient, NewClientEntry } from '../../contexts/backend-service-context';
import { useEffect, useState, Fragment } from 'react';
import { SessionClientStatus } from '@own/pluto_client';
import { ServerResource } from '../../generated/api-client';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ConnectionIdChip from '../Common/ConnectionIdChip';
import { ClientMessaging } from './ClientMessaging';
import Uuid from '../Common/Uuid';

export type ClientCardProps = {
  client: TheClient;
};

export function ClientCard({ client }: ClientCardProps) {
  const {
    servers,
    currentSessionClient,
    getSessionClientByConnectionId,
    setCurrentSessionClientByConnectionId,
    removeClient,
  } = useBackendServiceContext();
  const [sessionClient, setSessionClient] = useState<NewClientEntry | null>(null);
  const [server, setServer] = useState<ServerResource | undefined>(undefined);
  const [isCurrent, setIsCurrent] = useState(false);


  useEffect(() => {
    const x = getSessionClientByConnectionId(client.connectionId);
    if (!x) {
      return;
    }
    setSessionClient(x);
    if (servers) {
      setServer(servers.data.find((x) => x.id === client.serverId));
    } else {
      setServer(undefined);
    }
  }, [servers, client.connectionId, client.serverId, getSessionClientByConnectionId, setSessionClient]);

  const onConnectionStatusChanged = (status: SessionClientStatus) => {
    console.log('onConnectionStatusChanged', status);
  };

  useEffect(() => {
    if (sessionClient) {
      sessionClient.sessionClient.events.on('connectionStatusChanged', onConnectionStatusChanged);
      return () => {
        if (sessionClient) {
          sessionClient.sessionClient.events.removeListener('connectionStatusChanged', onConnectionStatusChanged);
        }
      };
    }
  }, [sessionClient]);

  useEffect(() => {
    if (currentSessionClient) {
      setIsCurrent(currentSessionClient.sessionId === client.sessionId
        && currentSessionClient.connectionId.value === client.connectionId.value);
    } else {
      setIsCurrent(false);
    }
  }, [client.connectionId.value, client.sessionId, currentSessionClient, setIsCurrent]);

  const onRemoveClient = () => {
    removeClient(client.connectionId);
  };

  const onSelectAsCurrent = () => {
    setCurrentSessionClientByConnectionId(client.connectionId);
  };

  return <Card>
    <CardContent style={{ fontSize: '0.8em' }}>
      <Stack
        direction="row"
        gap={1}
      >
        {client.connectionId ? <ConnectionIdChip connectionId={client.connectionId.value} /> : null}
        <Stack flex={1}>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
          >
            <Stack
              direction="row"
              alignItems="center"
              flex={1}
            >
              <Box>
                Session <Uuid id={client.sessionId} />
              </Box>
              {server ? <Fragment>
                <div>
                  Server: {server.name || 'Empty Server name'}
                </div>
                <div>
                  {server.publicWsEndpoint}
                </div>
                <Chip
                  size="small"
                  label={sessionClient?.sessionClient.status}
                />
              </Fragment> : null}
            </Stack>
            <Box>
              <Tooltip title="Remove this client">
                <IconButton
                  size="small"
                  color="error"
                  onClick={onRemoveClient}
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
          {sessionClient?.sessionClient.status === 'connected' ?
            <Fragment>
              <Stack direction="row">
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={onSelectAsCurrent}
                  startIcon={isCurrent ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                >
                  {isCurrent ? 'Current connection' : 'Use as current client'}
                </Button>
              </Stack>

              {sessionClient ? <ClientMessaging currentSessionClient={sessionClient} /> : null}
            </Fragment>
            : null}
        </Stack>
      </Stack>
    </CardContent>
  </Card>;
};
