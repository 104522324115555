import { TransportConfig, TransportInterface } from '../Transport';

export interface FBRServiceConfig {
    enableFiltering: boolean;
    enableBatching: boolean;
    processingRate: number; // In milliseconds. Interval in which messages are filtered, batched and sent.
    maxMessageSize: number; // Maximum size of a batched message in bytes.
    enableIncomingFiltering: boolean;
    incomingProcessingRate: number;
}

export interface FilteringAndBatchingConfig extends FBRServiceConfig {
    worker: Worker | null;
}

export function makeFilteringAndBatchingConfigForDC({
                                                        enableFiltering = true,
                                                        enableBatching = true,
                                                        processingRate = 1000.0 / 15.0,
                                                        maxMessageSize = 1000,
                                                        enableIncomingFiltering = true,
                                                        incomingProcessingRate = 1000.0 / 60.0,
                                                        worker = null,
                                                    }: Partial<FilteringAndBatchingConfig> = {}) {
    return {
        enableFiltering,
        enableBatching,
        processingRate,
        maxMessageSize,
        enableIncomingFiltering,
        incomingProcessingRate,
        worker,
    };
}

export function makeFilteringAndBatchingConfigForWS({
                                                        enableFiltering = true,
                                                        enableBatching = true,
                                                        processingRate = 1000.0 / 15.0,
                                                        maxMessageSize = 1000,
                                                        enableIncomingFiltering = true,
                                                        incomingProcessingRate = 1000.0 / 60.0,
                                                        worker = null,
                                                    }: Partial<FilteringAndBatchingConfig> = {}) {
    return {
        enableFiltering,
        enableBatching,
        processingRate,
        maxMessageSize,
        enableIncomingFiltering,
        incomingProcessingRate,
        worker,
    };
}

export interface TimeSyncConfig {
    interval: number; // In milliseconds. Determines how often time is synchronized
    windowSize: number; // Integer. Determines the size of a window in a "median shift"-like statistical procedure.
}

export function makeTimeSyncConfig({ interval = 1000, windowSize = 5 }: Partial<TimeSyncConfig> = {}) {
    return { interval, windowSize };
}

export interface ClientConfig extends TransportConfig {
    transport?: TransportInterface;
    requestTimeout?: number;
    timeSyncConfig?: Partial<TimeSyncConfig>;
    filteringAndBatchingConfigWS?: Partial<FilteringAndBatchingConfig>;
    filteringAndBatchingConfigDC?: Partial<FilteringAndBatchingConfig>;
}
