import { ErrorCode } from '../enums';
import { AbstractSessionClientError } from './abstract-session-client.error';

export class SessionNotFoundError extends AbstractSessionClientError {
  readonly $isSessionNotFoundError = true;
  static code: ErrorCode = ErrorCode.SESSION_NOT_FOUND;

  constructor(message?: string) {
    super(message ?? 'Session not found');
  }

  getCode() {
    return SessionNotFoundError.code;
  }
}