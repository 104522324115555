/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserSessionTypeGameLiftPayloadResource = {
  $resourceType: UserSessionTypeGameLiftPayloadResource.$resourceType;
  playerSessionId: string;
};

export namespace UserSessionTypeGameLiftPayloadResource {

  export enum $resourceType {
    USER_SESSION_TYPE_GAME_LIFT_PAYLOAD_RESOURCE = 'UserSessionTypeGameLiftPayloadResource',
  }


}

