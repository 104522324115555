import { ErrorCode } from '../enums';
import { AbstractSessionClientError } from './abstract-session-client.error';

export class RoomNotFoundError extends AbstractSessionClientError {
  readonly $isRoomNotFoundError = true;
  static code: ErrorCode = ErrorCode.SESSION_ROOM_NOT_FOUND;

  constructor(message?: string) {
    super(message ?? 'Room not found');
  }

  getCode() {
    return RoomNotFoundError.code;
  }
}