import { Chip } from '@mui/material';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { GroupTaskResource } from "../../../generated/api-client";

export type GroupTaskStatusProps = {
  status: GroupTaskResource.status;
}

const GroupTaskStatus: FC<GroupTaskStatusProps> = ({ status }: GroupTaskStatusProps) => {
  const [color, setColor] = useState<any>('primary');

  useEffect(() => {
    setColor(status === 'error' ? 'error' : status === 'done' ? 'success' : status === 'cancelled' ? 'warning' : 'info');
  }, [status]);

  return <Chip
    label={status}
    style={{ userSelect: 'none' }}
    color={color}
    size="small"
  />;
};

export default React.memo(GroupTaskStatus);
