/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LoginResultResource = {
  $resourceType: LoginResultResource.$resourceType;
  /**
   * Access token
   */
  token: string;
  /**
   * Refresh token
   */
  refreshToken: string;
};

export namespace LoginResultResource {

  export enum $resourceType {
    LOGIN_RESULT_RESOURCE = 'LoginResultResource',
  }


}

