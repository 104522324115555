import * as pluto from '@own/pluto_client';
import { OptionalClient } from './useClient';

import { useCallback, useEffect, useState } from 'react';
import { IRoom } from './useRooms';
import { IConnection } from '../useConnections';
import { useImmer } from 'use-immer';
import { useErrorHandler } from '../useErrorHandler';

export function msg2Binary(message: string) {
  const obs = new pluto.Binary.oByteStream();
  obs.writeString(message);
  return obs.bytes();
}

function binary2msg(binary: ArrayBuffer) {
  try {
    const ibs = new pluto.Binary.iByteStream(binary);
    return ibs.readString();
  } catch (e: any) {
    console.error('binary2msg', e);
    return 'decode failed';
  }
}

export interface IInbox {
  messageId: number;
  message: string;
  sender: pluto.ConnectionId;
  source: 'ws' | 'dc';
  method: 'message' | 'roomwide' | 'serverwide' | 'backend-message' | 'backend-roomwide' | 'backend-serverwide';
}

export interface IMessagingAPI {
  inbox: IInbox[];
  inboxSize: number;
  backendInbox: IInbox[];
  backendInboxSize: number;
  sendToSelectedWS: (message: string, queueId?: number) => void;
  sendToSelectedDC: (message: string, queueId?: number) => void;
  roomwideBroadcastWS: (message: string, queueId?: number) => void;
  roomwideBroadcastDC: (message: string, queueId?: number) => void;
  serverwideBroadcastWS: (message: string, queueId?: number) => void;
  serverwideBroadcastDC: (message: string, queueId?: number) => void;
}

const clientUninitializedError = 'Client is not initialized';

function channelTypeToSource(channelType: pluto.ChannelType) {
  switch (channelType) {
    case pluto.ChannelType.websocket:
      return 'ws';
    case pluto.ChannelType.datachannel:
      return 'dc';
  }
}

export function useMessaging(client: OptionalClient,
                             selectedRoom: IRoom | undefined,
                             selectedConnections: IConnection[]) {
  const [inbox, setInbox] = useImmer<IInbox[]>([]);
  const [inboxSize, setInboxSize] = useState(0);
  const [backendInbox, setBackendInbox] = useImmer<IInbox[]>([]);
  const [backendInboxSize, setBackendInboxSize] = useState(0);
  const handleError = useErrorHandler();

  useEffect(() => {
    if (!client) {
      handleError(clientUninitializedError);
      return;
    }
    // @ts-ignore
    client.events.on('message', ({ header, body, channelType }) => {
      setInbox((x) => {
        x.push({
          messageId: header.messageId.value,
          message: binary2msg(body),
          sender: header.senderId,
          source: channelTypeToSource(channelType),
          method: 'message',
        });
      });
    });
    // @ts-ignore
    client.events.on('messageBackend', ({ header, body, channelType }) => {
      console.log('messageBackend', { header, body, channelType });
      setBackendInbox((x) => {
        x.push({
          messageId: header.messageId.value,
          message: body,
          sender: header.senderId,
          source: channelTypeToSource(channelType),
          method: 'backend-message',
        });
      });
    });
    // @ts-ignore
    client.events.on('roomwideBroadcast', ({ header, body, channelType }) => {
      console.log('roomwideBroadcast', header);
      setInbox((x) => {
        x.push({
          messageId: header.messageId.value,
          message: binary2msg(body),
          sender: header.senderId,
          source: channelTypeToSource(channelType),
          method: 'roomwide',
        });
      });
    });
    // @ts-ignore
    client.events.on('roomwideBroadcastBackend', ({ header, body, channelType }) => {
      console.log('>>ON roomwideBroadcastBackend', { header, body, channelType });
      setBackendInbox((x) => {
        x.push({
          messageId: header.messageId.value,
          message: body,
          sender: header.senderId,
          source: channelTypeToSource(channelType),
          method: 'backend-roomwide',
        });
      });
    });
    // client.events.on('serverwideBroadcastBackend', ({ header, body, channelType }) => {
    //   console.log('>>ON serverwideBroadcastBackend', { header, body, channelType });
    //   setBackendInbox((x) => {
    //     x.push({
    //       messageId: header.messageId.value,
    //       message: body,
    //       sender: header.senderId,
    //       source: channelTypeToSource(channelType),
    //       method: 'backend-serverwide',
    //     });
    //   });
    // });
    // @ts-ignore
    client.events.on('serverwideBroadcast', ({ header, body, channelType }) => {
      setInbox((x) => {
        x.push({
          messageId: header.messageId.value,
          message: binary2msg(body),
          sender: header.senderId,
          source: channelTypeToSource(channelType),
          method: 'serverwide',
        });
      });
    });
  }, [client, setInbox, handleError]);

  useEffect(() => {
    setInboxSize(inbox.length);
  }, [inbox, setInboxSize]);

  useEffect(() => {
    setBackendInboxSize(backendInbox.length);
  }, [backendInbox, setBackendInboxSize]);

  const sendToSelectedWS = useCallback((message: string, queueId: number = 0) => {
    if (!client) {
      handleError(clientUninitializedError);
      return;
    }
    client.message(pluto.ChannelType.websocket,
      msg2Binary(message),
      selectedConnections.map(c => c.id),
      { queueId: new pluto.QueueId(queueId) });
  }, [client, selectedConnections, handleError]);
  const sendToSelectedDC = useCallback((message: string, queueId: number = 0) => {
    if (!client) {
      handleError(clientUninitializedError);
      return;
    }
    client.message(pluto.ChannelType.datachannel,
      msg2Binary(message),
      selectedConnections.map(c => c.id),
      { queueId: new pluto.QueueId(queueId) });
  }, [client, selectedConnections, handleError]);
  const roomwideBroadcastWS = useCallback((message: string, queueId: number = 0) => {
    if (!client) {
      handleError(clientUninitializedError);
      return;
    }
    if (!selectedRoom) {
      handleError('To perform roomwide broadcast ws pick a room  from `Joined rooms` list');
      return;
    }
    client.roomwideBroadcast(pluto.ChannelType.websocket,
      msg2Binary(message),
      selectedRoom.id,
      { queueId: new pluto.QueueId(queueId) });
  }, [client, selectedRoom, handleError]);
  const roomwideBroadcastDC = useCallback((message: string, queueId: number = 0) => {
    if (!client) {
      handleError(clientUninitializedError);
      return;
    }
    if (!selectedRoom) {
      handleError('To perform roomwide broadcast dc pick a room  from `Joined rooms` list');
      return;
    }
    client.roomwideBroadcast(pluto.ChannelType.datachannel,
      msg2Binary(message),
      selectedRoom.id,
      { queueId: new pluto.QueueId(queueId) });
  }, [client, selectedRoom, handleError]);
  const serverwideBroadcastWS = useCallback((message: string, queueId: number = 0) => {
    if (!client) {
      handleError(clientUninitializedError);
      return;
    }
    client.serverwideBroadcast(pluto.ChannelType.websocket,
      msg2Binary(message),
      { queueId: new pluto.QueueId(queueId) });
  }, [client, handleError]);
  const serverwideBroadcastDC = useCallback((message: string, queueId: number = 0) => {
    if (!client) {
      handleError(clientUninitializedError);
      return;
    }
    client.serverwideBroadcast(pluto.ChannelType.datachannel,
      msg2Binary(message),
      { queueId: new pluto.QueueId(queueId) });
  }, [client, handleError]);

  return {
    inbox,
    inboxSize,
    backendInbox,
    backendInboxSize,
    sendToSelectedWS,
    sendToSelectedDC,
    roomwideBroadcastWS,
    roomwideBroadcastDC,
    serverwideBroadcastWS,
    serverwideBroadcastDC,
  };
}
