import { ErrorCode } from '../enums';
import { AbstractSessionClientError } from './abstract-session-client.error';

export class ServerNotFoundError extends AbstractSessionClientError {
  readonly $isServerNotFoundError = true;
  static code: ErrorCode = ErrorCode.SERVER_NOT_FOUND;

  constructor(message?: string) {
    super(message ?? 'Server not found');
  }

  getCode() {
    return ServerNotFoundError.code;
  }
}
