/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ServerResource } from './ServerResource';
import type { SessionResource } from './SessionResource';

export type CreateSessionResultResource = {
  $resourceType: CreateSessionResultResource.$resourceType;
  session: SessionResource;
  server: ServerResource;
};

export namespace CreateSessionResultResource {

  export enum $resourceType {
    CREATE_SESSION_RESULT_RESOURCE = 'CreateSessionResultResource',
  }


}

