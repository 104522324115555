/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserSessionTypeGameLiftPayloadResource } from './UserSessionTypeGameLiftPayloadResource';
import type { UserSessionTypeOwnPayloadResource } from './UserSessionTypeOwnPayloadResource';

export type UserSessionResource = {
  $resourceType: UserSessionResource.$resourceType;
  /**
   * UserSessionId
   */
  userSessionId: string;
  /**
   * OwnerId
   */
  ownerId: string | null;
  /**
   * Slug
   */
  tag: string | null;
  /**
   * ConnectionId
   */
  connectionId: number;
  /**
   * SessionType
   */
  type: UserSessionResource.type;
  typePayload: (UserSessionTypeGameLiftPayloadResource | UserSessionTypeOwnPayloadResource);
  isHost: boolean;
  createdAt: string;
  updatedAt: string;
  /**
   * RoomId
   */
  rooms: Array<string>;
};

export namespace UserSessionResource {

  export enum $resourceType {
    USER_SESSION_RESOURCE = 'UserSessionResource',
  }

  /**
   * SessionType
   */
  export enum type {
    OWN = 'own',
    GAME_LIFT = 'game-lift',
  }


}

