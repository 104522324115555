/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TypeOwnPayloadResource = {
  $resourceType: TypeOwnPayloadResource.$resourceType;
};

export namespace TypeOwnPayloadResource {

  export enum $resourceType {
    TYPE_OWN_PAYLOAD_RESOURCE = 'TypeOwnPayloadResource',
  }


}

