import { ChannelType, TransportInterface } from './Transport';
import { ResponseQueue } from './NoSTD/ResponseQueue';
import { iByteStream, Numeric } from './Binary';
import { PlutoServerError } from './PlutoError';
import { EventEmitter } from 'eventemitter3';

interface IClientEventTypes {
    close: (event: CloseEvent | Event) => any;
}

export abstract class BaseClient<ResponseHeader, ClientEventTypes extends IClientEventTypes> {
    public close() {
        this.transport.close();
    }

    public get events() {
        return this.eventEmitter;
    }

    // returns error string if message is error, else null
    protected abstract readError(header: ResponseHeader, body: iByteStream): string | null;

    protected abstract readHeader(channelType: ChannelType, message: iByteStream): ResponseHeader;

    protected abstract messageId(header: ResponseHeader): Numeric;

    protected constructor(transport: TransportInterface, requestTimeout: number) {
        this.transport = transport;
        this.queue = new ResponseQueue(requestTimeout);
        this.transport.onClose((event) => {
            // @ts-ignore
            this.eventEmitter.emit('close', event);
        });
    }

    protected send(channel: ChannelType, message: ArrayBuffer) {
        this.transport.send(channel, message);
    }

    protected request<T>(channel: ChannelType,
                         message: ArrayBuffer,
                         messageId: Numeric,
                         parseResponse: (header: ResponseHeader, body: iByteStream) => T): Promise<T> {
        const response = new Promise<T>((resolve, reject) => {
            this.queue.pend(messageId, (header: ResponseHeader, body: iByteStream) => {
                const errorString = this.readError(header, body);
                if (errorString !== null) {
                    reject(new PlutoServerError(errorString));
                    return;
                }
                const result: T = parseResponse(header, body);
                resolve(result);
            }, reject);
        });
        this.send(channel, message);
        return response;
    }

    protected onMessage(channelType: ChannelType,
                        listener: (header: ResponseHeader, body: iByteStream) => void) {
        this.transport.onMessage(channelType, (message: ArrayBuffer) => {
            const ibs = new iByteStream(message);
            const header = this.readHeader(channelType, ibs);
            const messageId = this.messageId(header);
            // First check queue, because error can reject response promise
            if (this.queue.resolve(messageId, header as any, ibs)) {
                return;
            }
            listener(header, ibs);
        });
    }


    private transport: TransportInterface;
    private queue: ResponseQueue<Numeric, (header: ResponseHeader, body: iByteStream) => any>;
    private eventEmitter = new EventEmitter<ClientEventTypes>();
}
