/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SessionTypeOwnPayloadResource = {
  $resourceType: SessionTypeOwnPayloadResource.$resourceType;
};

export namespace SessionTypeOwnPayloadResource {

  export enum $resourceType {
    SESSION_TYPE_OWN_PAYLOAD_RESOURCE = 'SessionTypeOwnPayloadResource',
  }


}

