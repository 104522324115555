export enum ChannelType {
    websocket,
    datachannel,
}

export interface TransportInterface {
    close(): void;

    closed(): boolean;

    onClose(listener: (event: CloseEvent | Event) => void): any;

    onMessage(channelType: ChannelType, listener: (message: ArrayBuffer) => void): any;

    send(channelType: ChannelType, data: ArrayBuffer): any;
}
