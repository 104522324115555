import {
  Card,
  CardContent,
  Modal,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio, Box, Stack,
} from '@mui/material';
import {SessionResource, CreateRoomCommand} from '../../../generated/api-client';
import {useBackendServiceContext} from '../../../contexts/backend-service-context';
import {useMsg} from '../../../contexts/msg-context';
import Uuid from '../../Common/Uuid';
import * as yup from 'yup';
import {useFormik} from 'formik';
import hostLossMode = CreateRoomCommand.hostLossMode;
import {formHelper} from '../../../helpers/form';

const validationSchema = yup.object({
  tag: yup.string().optional(),
  hostLossMode: yup.string().required(),
});

export type BackendServiceAddRoomProps = {
  session: SessionResource;
  isOpen: boolean;
  onClose: () => void;
}

const initialValues = {
  tag: undefined,
  hostLossMode: hostLossMode.TRANSFER_ALLOW_EMPTY,
};

export function AddRoomForm({session, isOpen, onClose}: BackendServiceAddRoomProps) {
  const {currentSessionClient} = useBackendServiceContext();
  const {showApiError, showMessage} = useMsg();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {...initialValues},
    validationSchema,
    onSubmit: () => addRoom(),
  });

  const handleOnClose = () => {
    onClose();
  };

  const onSubmit = () => {
    formik.submitForm().catch((e) => console.error('Submit error', e));
  };

  const addRoom = () => {
    if (!currentSessionClient) {
      showMessage(`No SessionClient found to perform this request`, 'error');
      return;
    }
    currentSessionClient.sessionClient.createRoom(formik.values)
      .then((data) => {
        if (data.isLeft()) {
          showApiError(data.value);
        } else {
          showMessage('Room created', 'success');
          onClose();
        }
      })
      .catch(showApiError);
  };

  const {textControl, field} = formHelper<typeof initialValues>(formik);

  return <Modal
    open={isOpen}
    onClose={handleOnClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Card
      style={{
        margin: 'auto',
        width: 600,
        maxHeight: 800,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          mb={2}
        >
          Add Room to the Session <Uuid
          color="inherit"
          id={session.sessionId}
        />
        </Typography>
        <Box
          component="form"
          onSubmit={(e) => {
            onSubmit();
            e.preventDefault();
          }}
        >
          {textControl('tag', 'Tag')}
          <RadioGroup {...field('hostLossMode', false)}>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography variant="body1">
                Host loss mode:
              </Typography>
              <FormControlLabel
                value={hostLossMode.CLOSE}
                control={<Radio size="small"/>}
                label={hostLossMode.CLOSE}
              />
              <FormControlLabel
                value={hostLossMode.TRANSFER}
                control={<Radio size="small"/>}
                label={hostLossMode.TRANSFER}
              />
              <FormControlLabel
                value={hostLossMode.TRANSFER_ALLOW_EMPTY}
                control={<Radio size="small"/>}
                label={hostLossMode.TRANSFER_ALLOW_EMPTY}
              />
            </Stack>
          </RadioGroup>

          <Stack
            direction="row"
            justifyContent="space-between"
            mt={2}
          >
            <Button
              color="primary"
              variant="text"
              type="submit"
            >
              Submit
            </Button>
            <Button
              color="warning"
              variant="text"
              onClick={() => handleOnClose()}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  </Modal>;
}
