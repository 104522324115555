import {Int32, Int64} from "./Binary";

export class Timestamp extends Int64 {}

export function currentTimestamp(offset: number = 0): Timestamp {
    return new Timestamp(BigInt(Date.now() + Math.round(offset)));
}

// TODO: potential overflow
export class TimeDiff extends Int32 {}

export function timeDifference(start: Timestamp, end: Timestamp) : TimeDiff {
    return new TimeDiff(Number(end.value - start.value));
}

export function offsetTime(t: Timestamp, offset: TimeDiff) : Timestamp {
    return new Timestamp(BigInt(Number(t.value) + offset.value));
}

export function sleep(ms: number) {
    return new Promise((resolve) => { setTimeout(resolve, ms); });
}
