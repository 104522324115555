export interface WsConfig {
  url: string | URL;
  protocols?: string | string[];
  connectionTimeout?: number;
}

export type DcConfig = RTCDataChannelInit & {
  connectionTimeout?: number;
};

export class PcConfig implements RTCConfiguration {}

export interface TransportConfig {
  ws: WsConfig;
  dc?: DcConfig;
  dcLabel?: string;
  pc?: PcConfig;
}

export const DEFAULT_WS_CONNECTION_TIMEOUT = 1000;
export const DEFAULT_DC_CONNECTION_TIMEOUT = 1000;
