import { currentTimestamp, offsetTime, TimeDiff, timeDifference, Timestamp } from './Time';
import { IByteSerializable, iByteStream, oByteStream, Uint16, Uint8 } from './Binary';
import { ConnectionId, MessageId, QueueId } from './Id';
import * as DI from './DI/Msg_id_gen';
import { Route } from './Route';
import { TimeInfo } from './TimeSyncService';

export interface RequestHeaderArgs {
  queueId?: QueueId,
  messageId?: MessageId,
  clientSentTimestamp?: Timestamp,
  timeOffset?: number,
}

export class RequestHeader implements IByteSerializable {
  route: Route;
  queueId: QueueId;
  senderId: ConnectionId;
  messageId: MessageId;
  clientSentTimestamp: Timestamp;

  constructor(
    route: Route,
    senderId: ConnectionId,
    {
      queueId,
      messageId,
      clientSentTimestamp,
      timeOffset,
    }: RequestHeaderArgs = {},
  ) {
    this.route = route;
    this.senderId = senderId;
    this.queueId = queueId ?? new QueueId();
    this.messageId = messageId ?? DI.newMsgId();
    this.clientSentTimestamp = clientSentTimestamp ?? currentTimestamp(timeOffset);
  }

  writeBytes(obs: oByteStream): void {
    obs.write(new Uint8(this.route))
      .write(this.queueId)
      .write(this.senderId)
      .write(this.messageId)
      .write(this.clientSentTimestamp);
  }

  static readBytes(ibs: iByteStream): RequestHeader {
    const route = ibs.read(Uint8).value as Route;
    const queueId = ibs.read(QueueId);
    const senderId = ibs.read(ConnectionId);
    const messageId = ibs.read(MessageId);
    const clientSentTimestamp = ibs.read(Timestamp);
    return new RequestHeader(
      route,
      senderId,
      { queueId, messageId, clientSentTimestamp },
    );
  }

  static byteLen() {
    return Uint8.byteLen() +
      QueueId.byteLen() +
      ConnectionId.byteLen() +
      MessageId.byteLen() +
      Timestamp.byteLen();
  }
}

export class ResponseHeader implements IByteSerializable {
  route: Route;
  queueId: QueueId;
  senderId: ConnectionId;
  messageId: MessageId;
  clientSentTimestamp: Timestamp;
  serverReceivedTimestamp: Timestamp;
  serverSentTimestamp: Timestamp;
  clientReceivedTimestamp: Timestamp;

  constructor(route: Route,
              queueId: QueueId,
              senderId: ConnectionId,
              messageId: MessageId,
              clientSentTimestamp: Timestamp,
              serverReceivedTimestamp: Timestamp,
              serverSentTimestamp: Timestamp,
              clientReceivedTimestamp: Timestamp) {
    this.route = route;
    this.queueId = queueId;
    this.senderId = senderId;
    this.messageId = messageId;
    this.clientSentTimestamp = clientSentTimestamp;
    this.serverReceivedTimestamp = serverReceivedTimestamp;
    this.serverSentTimestamp = serverSentTimestamp;
    this.clientReceivedTimestamp = clientReceivedTimestamp;
  }

  writeBytes(obs: oByteStream): void {
    obs.write(new Uint8(this.route))
      .write(this.queueId)
      .write(this.senderId)
      .write(this.messageId)
      .write(this.clientSentTimestamp)
      .write(timeDifference(this.clientSentTimestamp, this.serverReceivedTimestamp))
      .write(timeDifference(this.clientSentTimestamp, this.serverSentTimestamp));
  }

  static readBytes(ibs: iByteStream): ResponseHeader {
    const route = ibs.read(Uint8).value as Route;
    const queueId = ibs.read(QueueId);
    const senderId = ibs.read(ConnectionId);
    const messageId = ibs.read(MessageId);
    const clientSentTimestamp = ibs.read(Timestamp);
    const serverReceivedTimestamp = ibs.read(TimeDiff);
    const serverSentTimestamp = ibs.read(TimeDiff);
    return new ResponseHeader(
      route,
      queueId,
      senderId,
      messageId,
      clientSentTimestamp,
      offsetTime(clientSentTimestamp, serverReceivedTimestamp),
      offsetTime(clientSentTimestamp, serverSentTimestamp),
      currentTimestamp(),
    );
  }

  static byteLen(): number {
    return Uint8.byteLen() +
      QueueId.byteLen() +
      ConnectionId.byteLen() +
      MessageId.byteLen() +
      Timestamp.byteLen() +
      2 * TimeDiff.byteLen();
  }
}

export function readResponseHeader(ibs: iByteStream, { offset }: TimeInfo) {
  const header = ibs.read(ResponseHeader);
  header.clientReceivedTimestamp = currentTimestamp(offset);
  return header;
}

export class BatchMessageByteLengthType extends Uint16 {} // TODO: Maybe can use Uint8?