/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GroupServerConfigResource = {
  $resourceType: GroupServerConfigResource.$resourceType;
  /**
   * Int value > 0
   */
  maxUserSessions: number | null;
  /**
   * Int value > 0
   */
  maxSessions: number | null;
};

export namespace GroupServerConfigResource {

  export enum $resourceType {
    GROUP_SERVER_CONFIG_RESOURCE = 'GroupServerConfigResource',
  }


}

