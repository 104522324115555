import { createContext, ReactNode, useContext, useState, useEffect } from 'react';
import { OpenAPI, ServersResource } from '../generated/api-client';
import { SessionClient, ServerId, SessionId, ConnectionId, OwnerId } from '@own/pluto_client';
import { useMsg } from './msg-context';
import { config } from '../config/config';

OpenAPI.BASE = config.backendServiceUrl;

const noop = () => {
};

export type BackendServiceContextProps = {
  ownerId: string;
  sessionId: string | null;
  userSessionId: string | null;
  backendServiceUrl: string | null;
  connectionId: number | null;
  clients: TheClient[];
  currentClient: TheClient | undefined;
  currentSessionClient: NewClientEntry | undefined,
  servers: ServersResource | null;
  setSessionId: (x: string | null) => void;
  setUserSessionId: (x: string | null) => void;
  setOwnerId: (x: string) => void;
  setConnectionId: (x: number) => void;
  addClient: (x: AddClientRequest) => Promise<void>;
  createSessionClient: (
    ownerId: OwnerId,
    sessionId?: SessionId,
    serverId?: ServerId,
  ) => Promise<SessionClient>;
  getSessionClientByConnectionId: (connectionId: ConnectionId) => NewClientEntry | undefined;
  setCurrentSessionClientByConnectionId: (connectionId: ConnectionId) => void;
  removeClient: (connectionId: ConnectionId) => void,
  setServers: (servers: ServersResource | null) => void;
};

const BackendServiceContext = createContext<BackendServiceContextProps>({
  ownerId: 'demo',
  sessionId: null,
  userSessionId: null,
  backendServiceUrl: null,
  connectionId: null,
  clients: [],
  currentClient: undefined,
  currentSessionClient: undefined,
  servers: null,
  setSessionId: noop,
  setUserSessionId: noop,
  setOwnerId: noop,
  setConnectionId: noop,
  addClient: async () => {
  },
  createSessionClient: async (): Promise<any> => {
  },
  getSessionClientByConnectionId: (): any => {
  },
  setCurrentSessionClientByConnectionId: (): any => {
  },
  removeClient: noop,
  setServers: noop,
});

export type AddClientRequest = {
  ownerId: OwnerId;
  sessionId?: SessionId;
  serverId: ServerId;
}

export type TheClient = {
  sessionId: string;
  serverId: string;
  connectionId: ConnectionId;
};

export type ConnectionIdLike = number;
export type NewClientEntry = {
  connectionId: ConnectionId,
  serverId: ServerId,
  sessionId?: SessionId,
  sessionClient: SessionClient
};

export const BackendServiceProvider = ({ children }: { children: ReactNode }) => {
  OpenAPI.BASE = config.backendServiceUrl;
  const [backendServiceUrl] = useState<string>(OpenAPI.BASE);
  const [userSessionId, setUserSessionId] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [ownerId, setOwnerId] = useState<string>('demo');
  const [connectionId, setConnectionId] = useState<number | null>(null);
  const [servers, setServers] = useState<ServersResource | null>(null);
  /**
   * Web clients connected to public ws endpoint
   */
  const [clients, setClients] = useState<TheClient[]>([]);
  const [currentClient, setCurrentClient] = useState<TheClient | undefined>(undefined);
  /**
   * SessionClients to communicate with SyncServer/BackendService
   */
  const [sessionClients, setSessionClients] = useState<Record<ConnectionIdLike, NewClientEntry>>({});
  const [currentSessionClient, setCurrentSessionClient] = useState<NewClientEntry | undefined>(undefined);

  const { showMessage } = useMsg();

  const createSessionClient = async (
    ownerId: OwnerId,
    sessionId?: SessionId,
    serverId?: ServerId,
  ): Promise<SessionClient> => {
    const res = await SessionClient.create({
      ownerId,
      userSessionTag: `demo-${new Date().getTime()}`,
      backendServiceUrl,
      sessionId,
      serverId,
      maxRooms: 100,
      maxMembers: 100,
    });

    if (res.isLeft()) {
      throw res.value;
    }
    const sessionClient = res.value;
    sessionClient.events.on('roomHostChanged', (event: any) => {
      console.log('ROOM HOST CHANGED', event);
    });

    sessionClients[sessionClient.connectionId.value] = {
      sessionClient,
      serverId: sessionClient.serverId,
      sessionId: sessionClient.sessionId,
      connectionId: sessionClient.connectionId,
    };
    setSessionClients(sessionClients);
    return sessionClient;
  };

  const getSessionClientByConnectionId = (connectionId: ConnectionId): NewClientEntry | undefined => {
    return sessionClients[connectionId.value];
  };

  const setCurrentSessionClientByConnectionId = (connectionId: ConnectionId): void => {
    const entry = getSessionClientByConnectionId(connectionId);
    if (entry) {
      setCurrentSessionClient(entry);
    } else {
      setCurrentSessionClient(undefined);
      showMessage(`No SessionClient for ConnectionId ${connectionId.value} found`, 'warning');
    }
  };

  const removeSessionClient = (connectionId: ConnectionId) => {
    if (sessionClients[connectionId.value]) {
      sessionClients[connectionId.value].sessionClient.close();
    }
    delete sessionClients[connectionId.value];
    setSessionClients(sessionClients);
    setClients(clients.filter((x) => x.connectionId.value !== connectionId.value));
  };

  const addClient = async (req: AddClientRequest) => {
    const sessionClient = await createSessionClient(req.ownerId, req.sessionId, req.serverId);
    setClients([...clients, {
      serverId: sessionClient.serverId,
      sessionId: sessionClient.sessionId,
      connectionId: sessionClient.connectionId,
    }]);
  };

  useEffect(() => {
    OpenAPI.BASE = backendServiceUrl ?? '';
  }, [backendServiceUrl]);

  useEffect(() => {
    setCurrentClient(clients.find((x) => x.sessionId === sessionId));
  }, [sessionId, clients]);

  return (<BackendServiceContext.Provider
    value={{
      userSessionId,
      sessionId,
      backendServiceUrl,
      ownerId,
      connectionId,
      clients,
      currentClient,
      currentSessionClient,
      servers,
      setSessionId,
      setUserSessionId,
      setOwnerId,
      setConnectionId,
      addClient,
      createSessionClient,
      getSessionClientByConnectionId,
      setCurrentSessionClientByConnectionId,
      removeClient: removeSessionClient,
      setServers,
    }}
  >
    {children}
  </BackendServiceContext.Provider>);
};

export const useBackendServiceContext = () => useContext(BackendServiceContext);
