/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ServerStateResource = {
  $resourceType: ServerStateResource.$resourceType;
  /**
   * Int value >= 0
   */
  activeSessionsCount: number;
  /**
   * Int value >= 0
   */
  activeUserSessionsCount: number;
  /**
   * Int value >= 0
   */
  reservedUserSessionsCount: number;
  /**
   * Int value > 0
   */
  maxUserSessionsCount: number;
  currentUserSessionsUsagePercent: number;
  /**
   * ServerStatus
   */
  status: ServerStateResource.status;
};

export namespace ServerStateResource {

  export enum $resourceType {
    SERVER_STATE_RESOURCE = 'ServerStateResource',
  }

  /**
   * ServerStatus
   */
  export enum status {
    DISCONNECTED = 'disconnected',
    CONNECTING = 'connecting',
    CONNECTED = 'connected',
    ERROR = 'error',
  }


}

