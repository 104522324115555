/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateGroupCommand = {
  /**
   * GroupType
   */
  type: CreateGroupCommand.type;
  /**
   * GroupCode
   */
  code: string;
  /**
   * Int value > 0
   */
  maxUserSessions: number;
  /**
   * Int value > 0
   */
  maxSessions: number;
};

export namespace CreateGroupCommand {

  /**
   * GroupType
   */
  export enum type {
    AWS = 'aws',
  }


}

