import { Chip, Tooltip } from '@mui/material';
import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { useBackendServiceContext } from '../../contexts/backend-service-context';
import { ConnectionId } from '@own/pluto_client';
import { useMsg } from '../../contexts/msg-context';

export type ConnectionIdChipProps = {
  connectionId: number;
  small?: boolean;
}

const ConnectionIdChip: FC<ConnectionIdChipProps> = ({ connectionId, small }: ConnectionIdChipProps) => {
  const { clients, currentSessionClient, setCurrentSessionClientByConnectionId } = useBackendServiceContext();
  const [isCurrent, setIsCurrent] = useState(false);
  const [isLocalClient, setIsLocalClient] = useState(false);
  const [color, setColor] = useState('grey');
  const { showMessage } = useMsg();

  useEffect(() => {
    setIsCurrent(currentSessionClient?.connectionId.value === connectionId);
  }, [currentSessionClient, connectionId, setIsCurrent]);

  useEffect(() => {
    setIsLocalClient(!!clients.find((x) => x.connectionId.value === connectionId));
  }, [clients, connectionId, setIsLocalClient]);

  useEffect(() => {
    setColor(!isLocalClient ? 'grey' : isCurrent ? '#ffa726' : '#66bb6a');
  }, [isLocalClient, isCurrent]);

  const onDoubleClick = () => {
    if (isLocalClient) {
      setCurrentSessionClientByConnectionId(new ConnectionId(connectionId));
    } else {
      showMessage('Can not use remote client as current client', 'warning');
    }
  };

  return <Tooltip title={isLocalClient ? `ConnectionId. Status: ${currentSessionClient?.sessionClient.status}` : 'Remote client ConnectionId'}>
    {small ? <span
      style={{ userSelect: 'none', fontWeight: 'bold', color }}
      onDoubleClick={onDoubleClick}
    >
            {connectionId}
        </span> : <Chip
      label={connectionId}
      style={{ userSelect: 'none' }}
      color={isLocalClient ? isCurrent ? 'warning' : 'success' : 'default'}
      size="small"
      onDoubleClick={onDoubleClick}
    />}
  </Tooltip>;
};

export default React.memo(ConnectionIdChip);
