import { RoomResource, SessionResource } from '../../../generated/api-client';
import { Room } from './Room';
import { Fragment, useState, useEffect } from 'react';
import { Stack, Typography, Box, Chip, Tooltip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useBackendServiceContext } from '../../../contexts/backend-service-context';
import { useMsg } from '../../../contexts/msg-context';
import { AddRoomForm } from './AddRoomForm';


export type BackendServiceSessionRoomsProps = {
  rooms: RoomResource[];
  session: SessionResource;
}

export function Rooms({ rooms, session }: BackendServiceSessionRoomsProps) {
  const { currentSessionClient } = useBackendServiceContext();
  const { showMessage } = useMsg();
  const [stat, setStat] = useState<{ rooms: number; }>({ rooms: 0 });
  const [maxRooms, setMaxRooms] = useState<number | null>(null);
  const [canCreateRoom, setCanCreateRoom] = useState(false);

  useEffect(() => {
    setCanCreateRoom(!!currentSessionClient);
  }, [currentSessionClient, session.sessionId, setCanCreateRoom]);


  useEffect(() => {
    setStat({
      rooms: session.rooms.length,
    });
    setMaxRooms(session.config.maxRooms);
  }, [session, setStat, setMaxRooms]);

  const [isAddRoomFormOpen, setIsAddRoomFormOpen] = useState(false);
  const createRoom = () => {
    if (!currentSessionClient) {
      showMessage(`No SessionClient found to perform this request`, 'error');
      return;
    }
    setIsAddRoomFormOpen(true);
  };

  return <Fragment>
    <Stack
      flex={1}
      margin={2}
    >
      <Stack
        direction="row"
        alignItems="center"
      >
        <AddRoomForm
          session={session}
          isOpen={isAddRoomFormOpen}
          onClose={() => setIsAddRoomFormOpen(false)}
        />
        <Typography variant="subtitle2">
          Rooms
        </Typography>
        <Box flex={1}>
          <Chip
            size="small"
            label={`${stat.rooms}${maxRooms ? `/${maxRooms}` : ``}`}
          />
        </Box>
        <Box>
          <Tooltip title="Create a new Room">
                                            <span>
                                                <IconButton
                                                  disabled={!canCreateRoom}
                                                  size="small"
                                                  onClick={createRoom}
                                                >
                                                    <AddIcon fontSize="inherit"/>
                                                </IconButton>
                                            </span>
          </Tooltip>
        </Box>
      </Stack>
      {rooms.map((room) => (
        <Room
          key={room.roomId}
          session={session}
          room={room}
        />
      ))}
    </Stack>
  </Fragment>;
}
