import { ErrorCode } from '../enums';
import { AbstractSessionClientError } from './abstract-session-client.error';

export class CanNotFindServerForSessionPlacement extends AbstractSessionClientError {
  readonly $isCanNotFindServerForSessionPlacement = true;
  static code: ErrorCode = ErrorCode.SERVER_NOT_FOUND_FOR_SESSION_PLACEMENT;

  constructor(message?: string) {
    super(message ?? 'Can not find a server for the new session placement');
  }

  getCode() {
    return CanNotFindServerForSessionPlacement.code;
  }
}
