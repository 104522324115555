import {
  Card,
  CardContent,
  Modal,
  Typography,
  Button,
  Box,
  Stack,
} from '@mui/material';
import { useBackendServiceContext } from '../../../contexts/backend-service-context';
import Uuid from '../../Common/Uuid';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { formHelper } from '../../../helpers/form';
import { ServerId, SessionId } from "@own/pluto_client";

const validationSchema = yup.object({
  ownerId: yup.string().required(),
});

export type BackendServiceAddClientFormProps = {
  serverId: ServerId;
  sessionId: SessionId;
  isOpen: boolean;
  onClose: () => void;
}

const initialValues = {
  ownerId: 'demo',
};

export function AddClientForm({
                                              serverId,
                                              sessionId,
                                              isOpen,
                                              onClose,
                                            }: BackendServiceAddClientFormProps) {
  const { addClient } = useBackendServiceContext();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialValues },
    validationSchema,
    onSubmit: () => onAddClient(),
  });

  const handleOnClose = () => {
    onClose();
  };

  const onSubmit = () => {
    formik.submitForm().catch((e) => console.error('Submit error', e));
  };

  const onAddClient = async () => {
    await addClient({
      ownerId: formik.values.ownerId,
      sessionId,
      serverId,
    });
    handleOnClose();
  };

  const { textControl } = formHelper<typeof initialValues>(formik);

  return <Modal
    open={isOpen}
    onClose={handleOnClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Card
      style={{
        margin: 'auto',
        width: 600,
        maxHeight: 800,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          mb={2}
        >
          Add Client to the Session <Uuid
          color="inherit"
          id={sessionId}
        />
        </Typography>
        <Box
          component="form"
          onSubmit={(e) => {
            onSubmit();
            e.preventDefault();
          }}
        >
          {textControl('ownerId', 'OwnerId')}
          <Stack
            direction="row"
            justifyContent="space-between"
            mt={2}
          >
            <Button
              color="primary"
              variant="text"
              type="submit"
            >
              Submit
            </Button>
            <Button
              color="warning"
              variant="text"
              onClick={() => handleOnClose()}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  </Modal>;
}
