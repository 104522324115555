import React from 'react';
import { DI } from '@own/pluto_client';
import { ControlPanel } from './Components/ControlPanel';
import { Box } from '@mui/material';
import { BackendServiceProvider } from './contexts/backend-service-context';
import { MsgProvider } from './contexts/msg-context';

DI.setLogger(console);

function App() {
    return (
            <MsgProvider>
                <BackendServiceProvider>
                    <Box m={4}>
                        <ControlPanel />
                    </Box>
                </BackendServiceProvider>
            </MsgProvider>
    );
}

export default App;
