import {Numeric, Uint8, Uint32} from "../Binary";

export class ConnectionId extends Uint8 {}

export class RoomId extends Uint8 {}

export class QueueId extends Uint8 {}

export class MessageId extends Uint32 {}

export function idEqual<T extends Numeric>(a: { value: T }, b: { value: T }): boolean {
    return a.value === b.value;
}
export function idCompare<T extends Numeric>(a: { value: T }, b: { value: T }): number {
    // TODO: fix potential overflow
    return Number(a.value - b.value);
}
