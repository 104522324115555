/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ServerResource } from './ServerResource';

export type ServersResource = {
  $resourceType: ServersResource.$resourceType;
  /**
   * Current page
   */
  page: number;
  /**
   * Total entries
   */
  total: number;
  /**
   * Page size
   */
  limit: number;
  /**
   * Total pages
   */
  totalPages: number;
  data: Array<ServerResource>;
};

export namespace ServersResource {

  export enum $resourceType {
    SERVERS_RESOURCE = 'ServersResource',
  }


}

