/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateSessionCommand = {
  /**
   * SessionType
   */
  type: CreateSessionCommand.type;
  /**
   * ServerId
   */
  serverId?: string;
  /**
   * Int value >= 0
   */
  reservedUserSessions?: number;
  /**
   * Int value > 0
   */
  maxUserSessions?: number;
  /**
   * Int value > 0
   */
  maxRooms?: number;
};

export namespace CreateSessionCommand {

  /**
   * SessionType
   */
  export enum type {
    OWN = 'own',
    GAME_LIFT = 'game-lift',
  }


}

