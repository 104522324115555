import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";
import {SnackbarProvider} from "notistack";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const theme = createTheme({
                              palette: {
                                  mode: 'dark',
                              },
                              components: {
                                  MuiStack: {
                                      defaultProps: {
                                          spacing: 1,
                                      }
                                  }
                              }
                          });
root.render(
    // <React.StrictMode>
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <SnackbarProvider maxSnack={3}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                          }}>
            <App/>
        </SnackbarProvider>
    </ThemeProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
