import { Stack, Button, Box, Drawer, Typography, Alert } from '@mui/material';
import { NewClientEntry, useBackendServiceContext } from '../../contexts/backend-service-context';
import { useMessaging, msg2Binary } from '../../Hooks/Client/useMessaging';
import { ChannelType, QueueId } from '@own/pluto_client';
import { useState, useEffect } from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import ConnectionIdChip from '../Common/ConnectionIdChip';

export type ClientMessagingProps = {
  currentSessionClient: NewClientEntry;
};

export function ClientMessaging({ currentSessionClient: current }: ClientMessagingProps) {
  const {
    inbox,
    inboxSize,
    backendInbox,
    backendInboxSize,
  } = useMessaging(current.sessionClient.client, undefined, []);
  const { clients, currentSessionClient } = useBackendServiceContext();

  const [isInboxOpened, setIsInboxOpened] = useState(false);
  const [isBackendInboxOpened, setIsBackendInboxOpened] = useState(false);
  const [isSendActionAvailable, setIsSendActionAvailable] = useState(false);

  const sendPing = () => {
    const message = 'ping';
    const queueId = new QueueId(1);
    const connectionIds = [current.connectionId];
    current.sessionClient.client.message(
      ChannelType.websocket,
      msg2Binary(message),
      connectionIds,
      { queueId },
    );
  };

  useEffect(() => {
    setIsSendActionAvailable(currentSessionClient?.connectionId === current.connectionId);
  }, [current.connectionId, currentSessionClient]);

  const sendPingToAll = () => {
    const message = 'ping';
    const queueId = new QueueId(1);
    const connectionIds = clients.map((x) => x.connectionId);
    current.sessionClient.client.message(
      ChannelType.websocket,
      msg2Binary(message),
      connectionIds,
      { queueId },
    );
  };

  return <Stack>
    <Stack direction="row">
      <Button
        size="small"
        variant="text"
        onClick={sendPing}
        disabled={!isSendActionAvailable}
      >
        WS ping
      </Button>
      <Button
        size="small"
        variant="text"
        onClick={sendPingToAll}
        disabled={!isSendActionAvailable}
      >
        WS ping to all
      </Button>
      <Button
        variant="text"
        onClick={() => setIsInboxOpened(true)}
        startIcon={<ArchiveIcon />}
      >
        Inbox ({inboxSize})
      </Button>
      <Button
        variant="text"
        onClick={() => setIsBackendInboxOpened(true)}
        startIcon={<ArchiveIcon />}
      >
        BackendInbox ({backendInboxSize})
      </Button>
    </Stack>
    <Stack direction="row">
    </Stack>

    <Drawer
      anchor="bottom"
      open={isInboxOpened}
      onClose={() => setIsInboxOpened(false)}
    >
      <Box
        m={2}
        flex={1}
        maxHeight={400}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Typography
          variant="h5"
          mb={2}
        >
          Inbox for <ConnectionIdChip connectionId={current.connectionId.value} />
        </Typography>
        <Box
          flex={1}
          sx={{ overflow: 'auto' }}
        >
          <Stack
            mt={2}
            mb={2}
          >
            {inbox.length ? inbox.map((x, index) => <Box key={index}>
              <div>
                Message: {x.message}
              </div>
              <div>
                From: {x.sender.value}
              </div>
              <div>
                Method: {x.method}
              </div>
            </Box>) : <Alert severity="warning">Empty</Alert>}
          </Stack>
        </Box>
      </Box>
    </Drawer>

    <Drawer
      anchor="bottom"
      open={isBackendInboxOpened}
      onClose={() => setIsBackendInboxOpened(false)}
    >
      <Box
        m={2}
        flex={1}
        maxHeight={400}
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Typography
          variant="h5"
          mb={2}
        >
          BackendInbox for <ConnectionIdChip connectionId={current.connectionId.value} />
        </Typography>
        <Box
          flex={1}
          sx={{ overflow: 'auto' }}
        >
          <Stack
            mt={2}
            mb={2}
          >
            {backendInbox.length ? backendInbox.map((x, index) => <Box key={index}>
              <div>
                Message: {x.message}
              </div>
              <div>
                From: {x.sender.value}
              </div>
              <div>
                Method: {x.method}
              </div>
            </Box>) : <Alert severity="warning">Empty</Alert>}
          </Stack>
        </Box>
      </Box>
    </Drawer>
  </Stack>;
};
