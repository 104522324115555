/**
 * There is a subset of Route enum
 * supported by BackendService ws connection to the SyncServer
 */
export enum BalancerRoute {
  createRoom = 5,
  closeRoom = 6,
  joinRoom = 7,
  leaveRoom = 8,
  listRooms = 9,
  listRoomConnections = 10,
  message = 14,
  serverwideBroadcast = 15,
  roomwideBroadcast = 16,
  joinRoomThroughBackend = 19,
  leaveRoomThroughBackend = 20,
}
