import {
  Box,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Tooltip, IconButton, Alert,
} from '@mui/material';
import {
  ServerResource,
  ServerStateResource,
  SessionApi,
  SessionResource,
  SessionsResource
} from '../../../generated/api-client';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useMsg } from '../../../contexts/msg-context';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBackendServiceContext } from '../../../contexts/backend-service-context';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import type = ServerResource.type;
import status = ServerStateResource.status;
import { useServersContext } from "../../../contexts/servers-context";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Uuid from "../../Common/Uuid";
import ClearIcon from '@mui/icons-material/Clear';
import SessionStatus from './session-status';
import SessionsFilter, { SessionFilterValue } from "./sessions-filter";
import { useWs } from "../../../contexts/ws-context";
import { SessionId } from "@own/pluto_client";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export function Sessions() {
  const navigate = useNavigate();
  const { addClient } = useBackendServiceContext();
  const { selectedServer, selectedSession, setSelectedSession } = useServersContext();
  const [sessions, setSessions] = useState<SessionsResource | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [isCreateASessionActive, setIsCreateASessionActive] = useState(false);
  const [isCreateASessionOnThisServerAvailable, setIsCreateASessionOnThisServerAvailable] = useState(false);
  const { showMessage, showApiError } = useMsg();
  const [filter, setFilter] = useState<SessionFilterValue | undefined>(undefined);
  const { on } = useWs();
  const [createdSessionId, setCreatedSessionId] = useState<SessionId | null>(null);

  const loadSessions = useCallback(() => {
    if (!selectedServer) {
      return;
    }
    SessionApi.listSessions(1, 100, {
      serverId: [selectedServer.id],
      status: filter?.statuses,
    }).then(setSessions).catch(showApiError);
  }, [filter, selectedServer, setSessions, showApiError]);

  useEffect(() => on('session.created', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      setCreatedSessionId(payload.sessionId as SessionId);
      setTimeout(() => loadSessions(), 500);
    }
  }), [loadSessions, selectedServer, on]);

  useEffect(() => on('session.closed', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadSessions();
    }
  }), [loadSessions, selectedServer, on]);

  useEffect(() => on('session.removed', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadSessions();
    }
  }), [loadSessions, selectedServer, on]);

  useEffect(() => on('session.room.added', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadSessions();
    }
  }), [loadSessions, selectedServer, on]);

  useEffect(() => on('session.room.removed', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadSessions();
    }
  }), [loadSessions, selectedServer, on]);

  useEffect(() => on('session.user-session.created', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadSessions();
    }
  }), [loadSessions, selectedServer, on]);

  useEffect(() => on('session.user-session.removed', (payload: any) => {
    if (payload.serverId === selectedServer?.id) {
      loadSessions();
    }
  }), [loadSessions, selectedServer, on]);

  useEffect(() => {
    loadSessions();
  }, [selectedServer, setSessions, showApiError, filter, loadSessions]);

  useEffect(() => {
    setIsActive(selectedServer?.status === status.CONNECTED);
    setIsCreateASessionOnThisServerAvailable(selectedServer?.type === type.OWN);
  }, [selectedServer, setIsActive, setIsCreateASessionOnThisServerAvailable]);

  const createSessionOnThisServer = () => {
    if (!selectedServer) {
      return;
    }
    setIsCreateASessionActive(true);
    SessionApi.createSession({
      maxUserSessions: 2,
      maxRooms: 10,
      type: selectedServer.type,
      serverId: selectedServer.id,
    })
      .then(() => {
        showMessage('Session created', 'success');
        loadSessions();
      })
      .catch(showApiError)
      .finally(() => setIsCreateASessionActive(false));
  };

  const onAddClient = useCallback(() => {
    if (!selectedServer) {
      return;
    }
    addClient({
      ownerId: 'demo',
      sessionId: undefined, // to start a new Session
      serverId: selectedServer.id,
    }).catch(showApiError);
  }, [showApiError, addClient, selectedServer]);

  const onSessionSelect = (session: SessionResource) => {
    if (!selectedServer) {
      return;
    }
    if (session.status === SessionResource.status.CLOSED) {
      showMessage('Can not view closed session', 'warning');
      return;
    }
    setSelectedSession(session);
    navigate(`/servers/${selectedServer.id}/sessions/${session.sessionId}`);
  };

  const onSessionClose = (session: SessionResource) => {
    if (!window.confirm('Are you sure?')) {
      return;
    }
    SessionApi.closeSession(session.sessionId)
      .then(() => showMessage(`Session closed`, "success"))
      .catch(showApiError);
  };

  const getSessionRowBg = (session: SessionResource) => {
    return selectedSession?.sessionId === session.sessionId
      ? 'rgba(255, 255, 255, .1)'
      : 'none';
  };

  const onFilterChange = (value: SessionFilterValue) => {
    setFilter(value);
  };

  return <Fragment>
    {selectedServer ? <Fragment>
        <Stack
          gap={2}
          mb={2}
        >
          <Box>
            <LoadingButton
              variant="text"
              size="small"
              onClick={onAddClient}
              startIcon={<PersonAddIcon fontSize="inherit"/>}
              loadingPosition="start"
              loading={isCreateASessionActive}
              disabled={isCreateASessionActive}
            >
              Client with Session
            </LoadingButton>

            {isActive && isCreateASessionOnThisServerAvailable ?
              <LoadingButton
                variant="text"
                size="small"
                onClick={createSessionOnThisServer}
                startIcon={<AddIcon fontSize="inherit"/>}
                loadingPosition="start"
                loading={isCreateASessionActive}
                disabled={isCreateASessionActive}
              >
                Create a Session
              </LoadingButton>
              : null}
          </Box>
        </Stack>

        {isActive ? <Box>
          <Stack gap={2}>
            <SessionsFilter
              onChange={onFilterChange}
            />
            <TableContainer component={Paper}>
              <Table
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      SessionId
                    </TableCell>
                    <TableCell>
                      Status
                    </TableCell>
                    <TableCell>
                      UserSessions
                    </TableCell>
                    <TableCell>
                      Reserved UserSessions
                    </TableCell>
                    <TableCell>
                      Rooms
                    </TableCell>
                    <TableCell>
                      Created at
                    </TableCell>
                    <TableCell>
                      Empty from
                    </TableCell>
                    <TableCell width={100}/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessions?.data?.length ? sessions.data.map((session: SessionResource) => (
                    <TableRow
                      key={session.sessionId}
                      sx={{ background: getSessionRowBg(session) }}
                    >
                      <TableCell onDoubleClick={() => onSessionSelect(session)}>
                        <Stack
                          direction="row"
                          alignItems="center"
                        >
                          <Uuid id={session.sessionId}/>
                          {session.sessionId === createdSessionId ? <Tooltip title="Created session">
                            <AutoAwesomeIcon
                              fontSize="inherit"
                              color="primary"
                            />
                          </Tooltip> : null}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <SessionStatus status={session.status}/>
                      </TableCell>
                      <TableCell>
                        {session.userSessions.length} / {session.config.maxUserSessions}
                      </TableCell>
                      <TableCell>
                        {session.config.reservedUserSessions}
                      </TableCell>
                      <TableCell>
                        {session.rooms.length} / {session.config.maxRooms}
                      </TableCell>
                      <TableCell>
                        {moment(session.createdAt).fromNow()}
                      </TableCell>
                      <TableCell>
                        {session.emptyFrom ? moment(session.emptyFrom).fromNow() : null}
                      </TableCell>
                      <TableCell align="right">
                        {session.status === SessionResource.status.CREATED ?
                          <Tooltip title="View the session">
                            <IconButton size="small" onClick={() => onSessionSelect(session)}>
                              <VisibilityIcon fontSize="inherit"/>
                            </IconButton>
                          </Tooltip>
                          : null}
                        {session.status === SessionResource.status.CREATED ?
                          <Tooltip title="Close the session">
                            <IconButton size="small" onClick={() => onSessionClose(session)}>
                              <ClearIcon
                                color="error"
                                fontSize="inherit"
                              />
                            </IconButton>
                          </Tooltip>
                          : null}
                      </TableCell>
                    </TableRow>
                  )) : <TableRow>
                    <TableCell colSpan={8}>
                      <Alert
                        variant="standard"
                        severity="warning"
                      >
                        No sessions
                      </Alert>
                    </TableCell>
                  </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Box> : null}
      </Fragment>
      : null}
  </Fragment>;
}
