import * as React from 'react';
import { FC, ReactNode } from 'react';

export type GrayProps = {
  children: ReactNode;
}

const Gray: FC<GrayProps> = ({ children }: GrayProps) => {
  return <span style={{ color: 'grey' }}>{children}</span>;
};

export default React.memo(Gray);
