import { ErrorCode } from '../enums';
import { AbstractSessionClientError } from './abstract-session-client.error';

export class OperationNotPermittedError extends AbstractSessionClientError {
  readonly $isOperationNotPermittedError = true;
  static code: ErrorCode = ErrorCode.OPERATION_NOT_PERMITTED;

  constructor(message?: string) {
    super(message ?? 'Operation not permitted');
  }

  getCode() {
    return OperationNotPermittedError.code;
  }
}