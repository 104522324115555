/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmEmailResultResource } from '../models/ConfirmEmailResultResource';
import type { LoginQuery } from '../models/LoginQuery';
import type { LoginResultResource } from '../models/LoginResultResource';
import type { MeResource } from '../models/MeResource';
import type { RefreshAccessTokenCommand } from '../models/RefreshAccessTokenCommand';
import type { RegisterCommand } from '../models/RegisterCommand';
import type { ResetPasswordCommand } from '../models/ResetPasswordCommand';
import type { SendResetPasswordLinkCommand } from '../models/SendResetPasswordLinkCommand';
import type { UserIdResource } from '../models/UserIdResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthApi {

  /**
   * Send reset password link
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static sendResetPasswordLink(
    requestBody: SendResetPasswordLinkCommand,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/auth/send-reset-password-link',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `User not found`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Reset password
   * @param requestBody
   * @returns LoginResultResource Password reset succeed. User logged in automatically.
   * @throws ApiError
   */
  public static resetPassword(
    requestBody: ResetPasswordCommand,
  ): CancelablePromise<LoginResultResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/auth/reset-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        401: `Token expired; Wrong token`,
        403: `Operation not permitted`,
        404: `User not found`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * User login by email and password
   * @param requestBody
   * @returns LoginResultResource Auth tokens have been successfully received
   * @throws ApiError
   */
  public static login(
    requestBody: LoginQuery,
  ): CancelablePromise<LoginResultResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/auth/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Current user and profile information
   * @returns MeResource Current user and profile information
   * @throws ApiError
   */
  public static me(): CancelablePromise<MeResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/auth/me',
      errors: {
        400: `Invariant violation; Bad request`,
        404: `User not found`,
      },
    });
  }

  /**
   * User registration
   * @param requestBody
   * @returns UserIdResource The user has been successfully registered
   * @throws ApiError
   */
  public static register(
    requestBody: RegisterCommand,
  ): CancelablePromise<UserIdResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/auth/register',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Existed user does not have local authentication provider`,
        409: `User already exist`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Email address confirmation
   * @returns ConfirmEmailResultResource Email address successfully confirmed
   * @throws ApiError
   */
  public static confirmEmail(): CancelablePromise<ConfirmEmailResultResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/auth/email-confirmation',
      errors: {
        400: `Invariant violation; Bad request`,
        401: `Wrong token; Token expired`,
        403: `Operation not permitted`,
        404: `User not found; Existed user does not have local authentication provider`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Refresh access token
   * @param requestBody
   * @returns LoginResultResource Refreshed tokens
   * @throws ApiError
   */
  public static refreshAccessToken(
    requestBody: RefreshAccessTokenCommand,
  ): CancelablePromise<LoginResultResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/auth/refresh-access-token',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        401: `Wrong token; Token expired`,
        403: `Operation not permitted`,
        404: `User not found`,
        500: `Unexpected error`,
      },
    });
  }

}
