/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GroupInfoResource } from './GroupInfoResource';
import type { GroupResource } from './GroupResource';

export type GroupCardResource = {
  $resourceType: GroupCardResource.$resourceType;
  group: GroupResource;
  info: GroupInfoResource;
};

export namespace GroupCardResource {

  export enum $resourceType {
    GROUP_CARD_RESOURCE = 'GroupCardResource',
  }


}

