import {
  Button,
  Card, CardActions, CardContent,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useMsg } from "../../../contexts/msg-context";
import { BalancerApi, GroupResource, GroupsResource } from "../../../generated/api-client";
import Uuid from "../../Common/Uuid";
import moment from "moment/moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import { useWs } from "../../../contexts/ws-context";

export function Groups() {
  const navigate = useNavigate();
  const [groups, setGroups] = useState<GroupsResource | null>(null);
  const { showApiError } = useMsg();
  const { on } = useWs();

  const loadGroups = useCallback(() => {
    BalancerApi.listGroups().then(setGroups).catch(showApiError);
  }, [setGroups, showApiError]);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  useEffect(() => on('balancer.group.created', () => {
    loadGroups();
  }), [loadGroups, on]);

  useEffect(() => on('balancer.group.deleted', () => {
    loadGroups();
  }), [loadGroups, on]);

  const onGroupSelect = (group: GroupResource) => {
    navigate(`/groups/${group.id}`);
  };

  const onGroupDelete = (group: GroupResource) => {
    if (!window.confirm('Are you sure?')) {
      return;
    }
    BalancerApi.deleteGroup(group.id)
      .catch(showApiError);
  };

  const onDiscover = () => {
    BalancerApi.discoverGroups()
      .then(setGroups)
      .catch(showApiError);
  }

  return <Fragment>
    <Card>
      <CardContent>
        <TableContainer component={Paper}>
          <Table
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  Id
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
                <TableCell>
                  Code
                </TableCell>
                <TableCell>
                  Created at
                </TableCell>
                <TableCell width={100}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups ? groups.data.map((x) => <TableRow key={x.id}>
                <TableCell>
                  <Uuid id={x.id}/>
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    color="info"
                    label={x.type}
                  />
                </TableCell>
                <TableCell>
                  {x.code}
                </TableCell>
                <TableCell>
                  {moment(x.createdAt).fromNow()}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="View the Group">
                    <IconButton size="small" onClick={() => onGroupSelect(x)}>
                      <VisibilityIcon fontSize="inherit"/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete the Group">
                    <IconButton size="small" onClick={() => onGroupDelete(x)}>
                      <ClearIcon
                        color="error"
                        fontSize="inherit"
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions>
        <Button onClick={onDiscover}>
          Discover groups
        </Button>
      </CardActions>
    </Card>
  </Fragment>;
}
