/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BalancerAwsPayloadResource = {
  $resourceType: BalancerAwsPayloadResource.$resourceType;
  instanceId: Record<string, any>;
  /**
   * GroupCode
   */
  groupName: string;
};

export namespace BalancerAwsPayloadResource {

  export enum $resourceType {
    BALANCER_AWS_PAYLOAD_RESOURCE = 'BalancerAwsPayloadResource',
  }


}

