/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGroupCommand } from '../models/CreateGroupCommand';
import type { GroupCardResource } from '../models/GroupCardResource';
import type { GroupResource } from '../models/GroupResource';
import type { GroupsResource } from '../models/GroupsResource';
import type { GroupTasksResource } from '../models/GroupTasksResource';
import type { SetGroupCapacityCommand } from '../models/SetGroupCapacityCommand';
import type { SuccessResource } from '../models/SuccessResource';
import type { UpdateGroupCommand } from '../models/UpdateGroupCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BalancerApi {

  /**
   * Creates the new scaling group
   * @param requestBody
   * @returns GroupResource GroupResource
   * @throws ApiError
   */
  public static createGroup(
    requestBody: CreateGroupCommand,
  ): CancelablePromise<GroupResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/balancer/groups',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * List groups
   * @param page Page number
   * @param limit Number of elements per page
   * @returns GroupsResource GroupsResource
   * @throws ApiError
   */
  public static listGroups(
    page: number = 1,
    limit: number = 25,
  ): CancelablePromise<GroupsResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/balancer/groups',
      query: {
        'page': page,
        'limit': limit,
      },
      errors: {
        400: `Bad request`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Sets the group desired capacity
   * @param groupId
   * @param requestBody
   * @returns SuccessResource SuccessResource
   * @throws ApiError
   */
  public static setGroupCapacity(
    groupId: string,
    requestBody: SetGroupCapacityCommand,
  ): CancelablePromise<SuccessResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/balancer/groups/{groupId}/set-capacity',
      path: {
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Group not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * List group tasks
   * @param groupId
   * @param page Page number
   * @param limit Number of elements per page
   * @returns GroupTasksResource GroupTasksResource
   * @throws ApiError
   */
  public static listGroupTasks(
    groupId: string,
    page: number = 1,
    limit: number = 25,
  ): CancelablePromise<GroupTasksResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/balancer/groups/{groupId}/group-tasks',
      path: {
        'groupId': groupId,
      },
      query: {
        'page': page,
        'limit': limit,
      },
      errors: {
        400: `Bad request`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Discover groups
   * @returns GroupsResource Discovered groups
   * @throws ApiError
   */
  public static discoverGroups(): CancelablePromise<GroupsResource> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/balancer/groups/discover',
      errors: {
        400: `Bad request`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Creates the new scaling group
   * @param groupId
   * @returns GroupResource GroupResource
   * @throws ApiError
   */
  public static deleteGroup(
    groupId: string,
  ): CancelablePromise<GroupResource> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/balancer/groups/{groupId}',
      path: {
        'groupId': groupId,
      },
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Group not found`,
        409: `Conflict occurred`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Updates the scaling group
   * @param groupId
   * @param requestBody
   * @returns GroupResource GroupResource
   * @throws ApiError
   */
  public static updateGroup(
    groupId: string,
    requestBody: UpdateGroupCommand,
  ): CancelablePromise<GroupResource> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/balancer/groups/{groupId}',
      path: {
        'groupId': groupId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invariant violation; Bad request`,
        403: `Operation not permitted`,
        404: `Group not found`,
        500: `Unexpected error`,
      },
    });
  }

  /**
   * Show the group
   * @param groupId
   * @returns GroupCardResource GroupCardResource
   * @throws ApiError
   */
  public static showGroup(
    groupId: string,
  ): CancelablePromise<GroupCardResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/balancer/groups/{groupId}',
      path: {
        'groupId': groupId,
      },
      errors: {
        400: `Bad request`,
        404: `Group not found`,
      },
    });
  }

}
