import {ValueOutOfBounds} from "../PlutoError";
import {IByteDeserializable, IByteSerializable} from "./IByteSerializable";
import {iByteStream, oByteStream} from "./BinaryStream";

export type Numeric = number | bigint;

const USE_LITTLE_ENDIANNESS = true;

interface IBaseStrictNumber<T extends Numeric> {
    value: T;
}

export interface IStrictNumber<T extends Numeric> extends IBaseStrictNumber<T>, IByteSerializable {}

export type NumericUnderlyingType<T> =
    T extends IStrictNumber<number> ? number :
    T extends IStrictNumber<bigint> ? bigint :
    never;

export interface IStrictNumberBuilder<T extends IStrictNumber<Numeric>> extends IByteDeserializable<T> {
    new(value?: NumericUnderlyingType<T>): T;
    minVal: NumericUnderlyingType<T>;
    maxVal: NumericUnderlyingType<T>;
}

function makeStrictNumberT<TypeTag extends string, N extends Numeric>(byteLen: number, minVal: N, maxVal: N) {
    const zero: N = typeof (minVal) === "number"
                    ? (0 as N)
                    : (BigInt(0) as N);
    return class implements IBaseStrictNumber<N> {
        Brand!: TypeTag;

        static byteLen(): number { return byteLen; };
        static readonly minVal: N = minVal;
        static readonly maxVal: N = maxVal;

        value: N;

        constructor(value: N = zero) {
            if (value < minVal || maxVal < value) {
                throw new ValueOutOfBounds();
            }
            this.value = value;
        }
    };
}

class Uint8Base extends makeStrictNumberT<"Uint8", number>(1, 0, 255) {}

export class Uint8 extends Uint8Base implements IStrictNumber<number> {
    static readBytes(ibs: iByteStream): Uint8 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getUint8(offset);
        return new Uint8(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setUint8(offset, this.value as number);
    }
}

class Uint16Base extends makeStrictNumberT<"Uint16", number>(2, 0, 65535) {}

export class Uint16 extends Uint16Base implements IStrictNumber<number> {
    static readBytes(ibs: iByteStream): Uint16 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getUint16(offset, USE_LITTLE_ENDIANNESS);
        return new Uint16(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setUint16(offset, this.value as number, USE_LITTLE_ENDIANNESS);
    }
}

class Uint32Base extends makeStrictNumberT<"Uint32", number>(4, 0, 4294967295) {}

export class Uint32 extends Uint32Base implements IStrictNumber<number> {
    static readBytes(ibs: iByteStream): Uint32 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getUint32(offset, USE_LITTLE_ENDIANNESS);
        return new Uint32(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setUint32(offset, this.value as number, USE_LITTLE_ENDIANNESS);
    }
}

class Uint64Base extends makeStrictNumberT<"Uint64", bigint>(8, BigInt(0), BigInt("18446744073709551615")) {}

export class Uint64 extends Uint64Base implements IStrictNumber<bigint> {
    static readBytes(ibs: iByteStream): Uint64 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getBigUint64(offset, USE_LITTLE_ENDIANNESS);
        return new Uint64(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setBigUint64(offset, this.value as bigint, USE_LITTLE_ENDIANNESS);
    }
}

class Int8Base extends makeStrictNumberT<"Int8", number>(1, -128, 127) {}

export class Int8 extends Int8Base implements IStrictNumber<number> {
    static readBytes(ibs: iByteStream): Int8 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getInt8(offset);
        return new Int8(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setInt8(offset, this.value as number);
    }
}

class Int16Base extends makeStrictNumberT<"Int16", number>(2, -32768, 32767) {}

export class Int16 extends Int16Base implements IStrictNumber<number> {
    static readBytes(ibs: iByteStream): Int16 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getInt16(offset, USE_LITTLE_ENDIANNESS);
        return new Int16(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setInt16(offset, this.value as number, USE_LITTLE_ENDIANNESS);
    }
}

class Int32Base extends makeStrictNumberT<"Int32", number>(4, -2147483648, 2147483647) {}

export class Int32 extends Int32Base implements IStrictNumber<number> {
    static readBytes(ibs: iByteStream): Int32 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getInt32(offset, USE_LITTLE_ENDIANNESS);
        return new Int32(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setInt32(offset, this.value as number, USE_LITTLE_ENDIANNESS);
    }
}

class Int64Base extends makeStrictNumberT<"Int64", bigint>(8,
                                                           BigInt("-9223372036854775808"),
                                                           BigInt("9223372036854775807")) {}

export class Int64 extends Int64Base implements IStrictNumber<bigint> {
    static readBytes(ibs: iByteStream): Int64 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getBigInt64(offset, USE_LITTLE_ENDIANNESS);
        return new Int64(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setBigInt64(offset, this.value as bigint, USE_LITTLE_ENDIANNESS);
    }
}

class Float32Base extends makeStrictNumberT<"Float32", number>(4,
                                                               -340282346638528859811704183484516925440.0,
                                                               340282346638528859811704183484516925440.0) {}

export class Float32 extends Float32Base implements IStrictNumber<number> {
    static readBytes(ibs: iByteStream): Float32 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getFloat32(offset, USE_LITTLE_ENDIANNESS);
        return new Float32(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setFloat32(offset, this.value as number, USE_LITTLE_ENDIANNESS);
    }
}

class Float64Base extends makeStrictNumberT<"Float64", number>(8, -Number.MAX_VALUE, Number.MAX_VALUE) {}

export class Float64 extends Float64Base implements IStrictNumber<number> {
    static readBytes(ibs: iByteStream): Float64 {
        const {dv, offset} = ibs.currentNumberView();
        const value = dv.getFloat64(offset, USE_LITTLE_ENDIANNESS);
        return new Float64(value);
    }

    writeBytes(obs: oByteStream) {
        const {dv, offset} = obs.currentNumberView();
        dv.setFloat64(offset, this.value as number, USE_LITTLE_ENDIANNESS);
    }
}
