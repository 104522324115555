/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SessionConfigResource = {
  $resourceType: SessionConfigResource.$resourceType;
  /**
   * Int value >= 0
   */
  reservedUserSessions: number;
  /**
   * Int value > 0
   */
  maxUserSessions: number | null;
  /**
   * Int value > 0
   */
  maxRooms: number | null;
};

export namespace SessionConfigResource {

  export enum $resourceType {
    SESSION_CONFIG_RESOURCE = 'SessionConfigResource',
  }


}

