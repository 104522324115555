import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import {
  ServerApi,
  ServerResource,
  ServerStateResource,
} from "../../../generated/api-client";
import Gray from "../../Common/Gray";
import Uuid from "../../Common/Uuid";
import CachedIcon from "@mui/icons-material/Cached";
import ServerShutdownStatus from "./ServerShutdownStatus";
import BlockIcon from "@mui/icons-material/Block";
import { ServerTypePayload } from "./ServerTypePayload";
import { useMsg } from "../../../contexts/msg-context";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ClearIcon from '@mui/icons-material/Clear';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        {`${Math.round(props.value)}%`}
      </Box>
    </Box>
  );
}

export type ServerInfoProps = {
  server: ServerResource;
  onBack?: () => void;
};

export function ServerInfo({ server, onBack }: ServerInfoProps) {
  const navigate = useNavigate();
  const { showMessage, showApiError } = useMsg();

  const [serverState, setServerState] = useState<ServerStateResource | null>(null);


  useEffect(() => {
    ServerApi.getServerState(server.id).then((data) => {
      setServerState(data);
    }).catch(showApiError);
  }, [server.id, setServerState, showApiError]);

  const onReconnect = () => {
    ServerApi.reconnect(server.id)
      .then(() => showMessage('Done', 'success'))
      .catch(showApiError);
  };

  const onCancelShutdown = () => {
    ServerApi.cancelShutdown(server.id)
      .then(() => showMessage('Cancelled', 'success'))
      .catch(showApiError);
  };

  const onShutdown = () => {
    ServerApi.scheduleShutdown(server.id)
      .then(() => showMessage('Scheduled', 'success'))
      .catch(showApiError);
  };

  const onDelete = () => {
    ServerApi.deleteServer(server.id)
      .then(() => showMessage('Deleted', 'success'))
      .catch(showApiError);
  };

  const onGroupOpen = (groupId: string) => {
    navigate(`/groups/${groupId}`)
  }

  return <Box>
    <Stack
      direction="row"
      alignItems="center"
    >
      {onBack ? <Box>
        <Tooltip title="Open the Servers list">
          <IconButton size="small" onClick={onBack}>
            <ArrowBackIcon fontSize="inherit"/>
          </IconButton>
        </Tooltip>
      </Box> : null}
      <Box>
        <Typography variant="h6">
          {server.name || <Gray>Empty name</Gray>}
        </Typography>
      </Box>
      <Box>
        <Chip
          size="small"
          label={server.type}
        />
      </Box>
      <Box flex={1}>
        <Uuid id={server.id}/>
      </Box>
      <Tooltip title="Status of BackendService connection to the SyncServer">
        <Chip
          size="small"
          color={server.status === 'connected' ? 'success' : server.status === 'disconnected' ? 'warning' : 'default'}
          label={server.status}
        />
      </Tooltip>
      <Box>
        <Tooltip title="Queue reconnect task">
          <IconButton
            size="small"
            onClick={onReconnect}
          >
            <CachedIcon fontSize="inherit"/>
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
    <Stack
      style={{ fontSize: '.85em' }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
      >
        <div>
          Public: <Gray>{server.publicWsEndpoint}</Gray>
        </div>
        <div>
          Internal: <Gray>{server.internalWsEndpoint}</Gray>
        </div>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
      >
        <div>
          Sessions: <Gray>{server.activeSessionsCount}/{server.maxSessions}</Gray>
        </div>
        <div>
          Session slots: <Gray>{(server.maxSessions ?? 0) - server.activeSessionsCount}</Gray>
        </div>
        {serverState ?
          <Fragment>
            <Box flex={1}>
              UserSessions: <Gray>{serverState.activeUserSessionsCount}/{serverState.maxUserSessionsCount}</Gray>
            </Box>
            <LinearProgressWithLabel style={{ width: 64 }} value={serverState.currentUserSessionsUsagePercent}/>
          </Fragment>
          : null}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
      >
        <div>
          Shutdown status: <ServerShutdownStatus status={server.shutdownStatus}/>
        </div>
        {server.shutdownStatus !== ServerResource.shutdownStatus.NONE ?
          <Tooltip title="Cancel the shutdown">
            <IconButton
              color="error"
              size="small"
              onClick={onCancelShutdown}>
              <BlockIcon fontSize="inherit"/>
            </IconButton>
          </Tooltip>
          : null}
        {server.shutdownStatus === ServerResource.shutdownStatus.NONE ?
          <Tooltip title="Shutdown the Server">
            <IconButton
              color="error"
              size="small"
              onClick={onShutdown}>
              <PowerSettingsNewIcon fontSize="inherit"/>
            </IconButton>
          </Tooltip>
          : null}
        {server.shutdownStatus === ServerResource.shutdownStatus.SHUTDOWN ?
          <Tooltip title="Delete the Server">
            <IconButton
              color="error"
              size="small"
              onClick={onDelete}>
              <ClearIcon fontSize="inherit"/>
            </IconButton>
          </Tooltip>
          : null}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
      >
        <div>
          Balancing Type: <Gray>{server.balancerConfig.type}</Gray>
        </div>
        {server.balancerConfig.payload?.groupName ? <div>
          Balancing Group: <Gray>{server.balancerConfig.payload.groupName}</Gray>
          {server.balancerConfig.groupId ? <Tooltip title="View the Group">
            <IconButton size="small" onClick={() => onGroupOpen(server.balancerConfig.groupId as string)}>
              <VisibilityIcon fontSize="inherit"/>
            </IconButton>
          </Tooltip> : null}
        </div> : null}
      </Stack>
      <Box>
        <ServerTypePayload server={server}/>
      </Box>
    </Stack>
  </Box>
}
