import { ErrorCode } from '../../enums';
import { AbstractSessionClientError } from '../abstract-session-client.error';

export class SessionIsClosedError extends AbstractSessionClientError {
  readonly $isSessionIsClosedError = true;
  static code: ErrorCode = ErrorCode.SESSION_IS_CLOSED;

  constructor(message?: string) {
    super(message ?? 'Session is closed');
  }

  getCode() {
    return SessionIsClosedError.code;
  }
}