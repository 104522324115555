/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserRoleResource } from './UserRoleResource';

export type UserResource = {
  $resourceType: UserResource.$resourceType;
  /**
   * User id
   */
  id: string;
  name: string | null;
  /**
   * User roles
   */
  roles: Array<UserRoleResource>;
  permissions: Array<number>;
  /**
   * User status
   */
  status: UserResource.status;
};

export namespace UserResource {

  export enum $resourceType {
    USER_RESOURCE = 'UserResource',
  }

  /**
   * User status
   */
  export enum status {
    ACTIVE = 'active',
  }


}

