/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BalancerAwsPayloadResource } from './BalancerAwsPayloadResource';

export type BalancerConfigResource = {
  $resourceType: BalancerConfigResource.$resourceType;
  /**
   * BalancingType
   */
  type: BalancerConfigResource.type;
  /**
   * GroupId
   */
  groupId: string | null;
  payload: BalancerAwsPayloadResource | null;
};

export namespace BalancerConfigResource {

  export enum $resourceType {
    BALANCER_CONFIG_RESOURCE = 'BalancerConfigResource',
  }

  /**
   * BalancingType
   */
  export enum type {
    NONE = 'none',
    AWS = 'aws',
  }


}

