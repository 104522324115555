/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SessionLogResource = {
  $resourceType: SessionLogResource.$resourceType;
  /**
   * ServerId
   */
  id: string;
  /**
   * SessionId
   */
  sessionId: string;
  content: string;
  tag: SessionLogResource.tag | null;
  createdAt: string;
};

export namespace SessionLogResource {

  export enum $resourceType {
    SESSION_LOG_RESOURCE = 'SessionLogResource',
  }

  export enum tag {
    ROOM_ADDED = 'room.added',
    ROOM_REMOVED = 'room.removed',
    ROOM_JOINED = 'room.joined',
    ROOM_LEFT = 'room.left',
    USER_SESSION_CREATED = 'user-session.created',
    USER_SESSION_REMOVED = 'user-session.removed',
  }


}

