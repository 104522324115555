/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GroupTaskResource = {
  $resourceType: GroupTaskResource.$resourceType;
  /**
   * GroupTaskId
   */
  id: string;
  /**
   * GroupId
   */
  groupId: string;
  /**
   * GroupTaskType
   */
  type: GroupTaskResource.type;
  /**
   * GroupTaskStatus
   */
  status: GroupTaskResource.status;
  payload: Record<string, any> | null;
  resultPayload: Record<string, any> | null;
  createdAt: string;
  completedAt: string | null;
};

export namespace GroupTaskResource {

  export enum $resourceType {
    GROUP_TASK_RESOURCE = 'GroupTaskResource',
  }

  /**
   * GroupTaskType
   */
  export enum type {
    CAPACITY_SET = 'capacity.set',
    INSTANCE_TERMINATE = 'instance.terminate',
  }

  /**
   * GroupTaskStatus
   */
  export enum status {
    CREATED = 'created',
    DONE = 'done',
    CANCELLED = 'cancelled',
    ERROR = 'error',
  }


}

