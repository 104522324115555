import { ErrorCode } from '../enums';
import { AbstractSessionClientError } from './abstract-session-client.error';

export class ConflictError extends AbstractSessionClientError {
  readonly $isConflictError = true;
  static code: ErrorCode = ErrorCode.CONFLICT;

  constructor(message?: string) {
    super(message ?? 'A conflict occurred');
  }

  getCode() {
    return ConflictError.code;
  }
}