import {ApiError} from '../generated/api-client';
import {AbstractSessionClientError} from "@own/pluto_client";

export function apiErrorMessage(e: ApiError | AbstractSessionClientError, message?: string) {
  if (e instanceof ApiError) {
    if (e?.body) {
      return `[${e?.body?.code ?? 'unknown code'}] ${message ? message + ' ' : ''}${e?.body?.message ?? 'unknown error'}`;
    }
  }
  if (e instanceof AbstractSessionClientError) {
    return `[${e.getCode()}] ${message ? message + ' ' : ''}${e.message}`;
  }
  console.error(`Unknown api error`, e);
  return `Unknown api error`;
}
