import {
  Card,
  CardContent,
  Stack,
  Box,
  CardActions,
  Modal,
  Button,
  Typography,
  Chip,
  Tooltip,
  IconButton
} from '@mui/material';
import {
  ServerResource,
  SessionResource,
  UserSessionResource,
  UserSessionTypeGameLiftPayloadResource, UserSessionPayloadResource, SessionApi,
} from '../../../generated/api-client';
import moment from 'moment';
import { useEffect, useState } from 'react';
import type = UserSessionResource.type;
import ConnectionIdChip from '../../Common/ConnectionIdChip';
import RoomIdChip from '../../Common/RoomIdChip';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBackendServiceContext } from '../../../contexts/backend-service-context';
import { useMsg } from '../../../contexts/msg-context';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Uuid from '../../Common/Uuid';
import ClearIcon from "@mui/icons-material/Clear";
import { ConnectionId } from "@own/pluto_client";

export type BackendServiceUserSessionProps = {
  server: ServerResource;
  session: SessionResource;
  userSession: UserSessionResource;
}

export function UserSession({ session, userSession }: BackendServiceUserSessionProps) {
  const [rooms, setRooms] = useState<number[]>([]);
  const [payload, setPayload] = useState<[string, string][]>([]);
  const { currentSessionClient } = useBackendServiceContext();
  const { showMessage, showApiError } = useMsg();

  useEffect(() => {
    setRooms(session.rooms.reduce((acc: number[], x) => {
      if (userSession.rooms.includes(x.roomId)) {
        acc.push(x.remoteRoomId);
      }
      return acc;
    }, []));
    if (userSession.type === type.GAME_LIFT) {
      const typePayload = userSession.typePayload as UserSessionTypeGameLiftPayloadResource;
      setPayload([
        ['PlayerSessionId', typePayload.playerSessionId],
      ]);
    } else {
      setPayload([]);
    }
  }, [session, userSession]);

  const [isUserSessionPayloadLoading, setIsUserSessionPayloadLoading] = useState(false);
  const [isUserSessionPayloadBtnAvailable, setIsUserSessionPayloadBtnAvailable] = useState(false);
  const [isUserSessionUpdatePayloadBtnAvailable, setIsUserSessionUpdatePayloadBtnAvailable] = useState(false);
  const [isUserSessionPayloadModalOpened, setIsUserSessionPayloadModalOpened] = useState(false);
  const [isRemoveUserSessionBtnAvailable, setIsRemoveUserSessionBtnAvailable] = useState(false);
  const [userSessionPayload, setUserSessionPayload] = useState<UserSessionPayloadResource | null>(null);

  const onGetUserSessionPayload = () => {
    if (!currentSessionClient) {
      showMessage('No current SessionClient found', 'warning');
      return;
    }
    setIsUserSessionPayloadLoading(true);
    currentSessionClient.sessionClient.getUserSessionPayload(userSession.userSessionId)
      .then((x) => {
        if (x.isLeft()) {
          showApiError(x.value);
        } else {
          setUserSessionPayload(x.value);
          setIsUserSessionPayloadModalOpened(true);
        }
      })
      .catch(showApiError)
      .finally(() => setIsUserSessionPayloadLoading(false));
  };

  const handleUserSessionPayloadModalClose = () => {
    setIsUserSessionPayloadModalOpened(false);
  };

  useEffect(() => {
    setIsUserSessionPayloadBtnAvailable(!!currentSessionClient);
    setIsUserSessionUpdatePayloadBtnAvailable(!!currentSessionClient);
    setIsRemoveUserSessionBtnAvailable(!!currentSessionClient);
  }, [currentSessionClient, setIsUserSessionPayloadBtnAvailable, setIsUserSessionUpdatePayloadBtnAvailable]);

  const onUpdateUserSessionPayload = () => {
    if (!currentSessionClient) {
      showMessage('No current SessionClient found', 'warning');
      return;
    }
    currentSessionClient.sessionClient
      .updateUserSessionPayload(userSession.userSessionId, { value: new Date().getTime() })
      .then(() => showMessage('Updated!', 'success'))
      .catch(showApiError);
  };

  const onMakeHost = () => {
    SessionApi.transferHost(session.sessionId, { hostUserSessionId: userSession.userSessionId })
      .then(() => showMessage('Host updated', 'success'))
      .catch(showApiError);
  };

  const removeUserSession = () => {
    if (!currentSessionClient) {
      showMessage('No current SessionClient found', 'warning');
      return;
    }
    currentSessionClient.sessionClient
      .removeUserSession(new ConnectionId(userSession.connectionId))
      .then((x) => {
        if (x.isLeft()) {
          showApiError(x.value);
        } else {
          showMessage('Removed!', 'success')
        }
      })
      .catch(showApiError);
  }

  return <Card>
    <CardContent style={{ fontSize: '.85em' }}>
      <Stack direction="row">
        <Box>
          <ConnectionIdChip connectionId={userSession.connectionId}/>
        </Box>
        <Box flex={1}>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
            >
              <div>
                Id: <Uuid id={userSession.userSessionId}/>
              </div>
              <div>
                OwnerId: <span style={{ color: 'grey' }}>{userSession.ownerId}</span>
              </div>
              <Box flex={1}/>
              {userSession.isHost ? <Chip
                label="Host"
                size="small"
                sx={{ fontSize: 'inherit' }}
              /> : null}
              <Box>
                <Tooltip title="Remove this UserSession">
                  <span>
                  <IconButton
                    size="small"
                    color="error"
                    disabled={!isRemoveUserSessionBtnAvailable}
                    onClick={removeUserSession}
                  >
                    <ClearIcon fontSize="inherit"/>
                  </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
            >
              {userSession.tag ? <div>
                Tag: <span style={{ color: 'grey' }}>{userSession.tag}</span>
              </div> : null}
              <div>
                Updated
                at: <span style={{ color: 'grey' }}>{moment(userSession.updatedAt).fromNow()}</span>
              </div>
            </Stack>
            <Stack direction="row">
              {payload.map(([key, value]) => <div key={key}>
                {key}: <span style={{ color: 'gray' }}>{value}</span>
              </div>)}
            </Stack>
          </Stack>
          {rooms.length ? (
            <Stack
              direction="row"
              marginTop={1}
            >
              <span>Rooms:</span>
              {rooms.map((x) => (
                <RoomIdChip
                  small={true}
                  key={x}
                  remoteRoomId={x}
                />
              ))}
            </Stack>
          ) : null}
        </Box>
      </Stack>
    </CardContent>
    <CardActions>
      <LoadingButton
        variant="text"
        size="small"
        onClick={onGetUserSessionPayload}
        loadingPosition="start"
        startIcon={<DataObjectIcon fontSize="inherit"/>}
        loading={isUserSessionPayloadLoading}
        disabled={!isUserSessionPayloadBtnAvailable}
      >
        Show payload
      </LoadingButton>
      <Button
        size="small"
        variant="text"
        disabled={!isUserSessionUpdatePayloadBtnAvailable}
        onClick={onUpdateUserSessionPayload}
      >
        Update payload
      </Button>
      {userSession.isHost ? null : <Button
        size="small"
        variant="text"
        onClick={onMakeHost}
      >
        Make host
      </Button>}
    </CardActions>
    <Modal
      open={isUserSessionPayloadModalOpened}
      onClose={handleUserSessionPayloadModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        style={{
          margin: 'auto',
          width: 600,
          maxHeight: 800,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            mb={2}
          >
            UserSession <Uuid
            color="inherit"
            id={userSession.userSessionId}
          /> payload
          </Typography>
          <pre
            style={{
              maxHeight: 400,
              overflow: 'auto',
              fontSize: '0.85em',
            }}
          >{userSessionPayload ? JSON.stringify(userSessionPayload, undefined, ' ') : 'no payload'}</pre>
        </CardContent>
      </Card>
    </Modal>
  </Card>;
}
