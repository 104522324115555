import {iByteStream} from "../Binary";
import {ConnectionId} from "../Id";
import {
    FBRMessageFromWorker,
    FBRMessagesService,
    FBRMessageToInitWorker,
    FBRMessageToWorker,
    FBRMessageType
} from "../Services/FBRMessagesService";

export function startWorker() {
    if (!(typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope)) {
        return;
    }

    onmessage = (event) => {
        let {senderId, ...config} = event.data as FBRMessageToInitWorker;
        const senderIdParsed = (new iByteStream(senderId).read(ConnectionId));
        const service = new FBRMessagesService(senderIdParsed, {worker: null, ...config});
        service.onFilterBatchMsgReady((messages) => postMessage({
            messages,
            type: FBRMessageType.batch,
        } as FBRMessageFromWorker));
        service.onReceiveMsgReady((messages) => postMessage({
            messages,
            type: FBRMessageType.receive,
        } as FBRMessageFromWorker))


        onmessage = (ev) => {
            const {type, message, timeOffset} = ev.data as FBRMessageToWorker;
            service.queueMessage(type, message, timeOffset);
        };
    }
}