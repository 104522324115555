/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserSessionTypeOwnPayloadResource = {
  $resourceType: UserSessionTypeOwnPayloadResource.$resourceType;
};

export namespace UserSessionTypeOwnPayloadResource {

  export enum $resourceType {
    USER_SESSION_TYPE_OWN_PAYLOAD_RESOURCE = 'UserSessionTypeOwnPayloadResource',
  }


}

