export class PlutoError extends Error {}

export class ValueOutOfBounds extends PlutoError {
    constructor() { super("Value out of bounds"); }
}

export class BinaryStreamError extends PlutoError {}

export class TransportError extends PlutoError {}

export class PlutoServerError extends PlutoError {}

export class ClientError extends PlutoError {}
