import { ConnectionId } from '../Id';
import { RequestHeader } from '../Headers';
import { iByteStream } from '../Binary';
import { batchMessages } from '../Utils/BatchMessages';
import { HeaderMessage } from '../Utils/HeaderMessage';
import { filterMessages } from '../Utils/FilterMessages';

export class FilterBatchMessagesService {
  constructor(senderId: ConnectionId,
              maxMessageSize: number,
              processingRate: number,
              enableFiltering: boolean = true,
              enableBatching: boolean = true) {
    this.senderId = senderId;
    this.maxMessageSize = maxMessageSize;
    this.processingRate = processingRate;
    this.enableFiltering = enableFiltering;
    this.enableBatching = enableBatching;
    this.run();
  }

  run() {
    if (this.enableFiltering || this.enableBatching) {
      this.intervalId = setInterval(this.doFilterBatch.bind(this), this.processingRate);
    }
  }

  stop() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  queueMessage(message: ArrayBuffer, timeOffset: number = 0) {
    if (!this.enableFiltering && !this.enableBatching) {
      this.messagesReadyCallback?.([message]);
      return;
    }
    const ibs = new iByteStream(message);
    const header = ibs.read(RequestHeader);
    this.sendMessagesQueue.push({ header, data: message });
    this.timeOffsetAcc += timeOffset;
  }

  onMessagesReady(callback: (messages: ArrayBuffer[]) => any) {
    this.messagesReadyCallback = callback;
  }

  private readonly maxMessageSize: number;
  private readonly processingRate: number;
  private messagesReadyCallback?: (messages: ArrayBuffer[]) => any;

  private readonly enableFiltering: boolean;
  private readonly enableBatching: boolean;
  private readonly senderId: ConnectionId;
  private sendMessagesQueue: HeaderMessage[] = [];
  private timeOffsetAcc: number = 0;
  private intervalId: ReturnType<typeof setInterval> | null = null;

  private doFilterBatch() {
    if (this.sendMessagesQueue.length === 0) {
      return;
    }
    const timeOffset = this.timeOffsetAcc / this.sendMessagesQueue.length;
    const messages = this.enableFiltering
      ? filterMessages(this.sendMessagesQueue)
      : this.sendMessagesQueue;
    const buffers: ArrayBuffer[] = this.enableBatching
      ? batchMessages(messages, this.maxMessageSize, this.senderId, timeOffset)
      : messages.map((msg) => msg.data);
    this.messagesReadyCallback?.(buffers);
    this.sendMessagesQueue = [];
    this.timeOffsetAcc = 0;
  }
}
