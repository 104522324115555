import {Uint8, Uint16, Uint32, Uint64, IStrictNumberBuilder, NumericUnderlyingType} from "../Binary";

type AnyUint = Uint8 | Uint16 | Uint32 | Uint64;

export class IDGenerator<T extends AnyUint> {
    private state: NumericUnderlyingType<T>;
    private readonly zero: NumericUnderlyingType<T>;
    private readonly maxVal: NumericUnderlyingType<T>;
    private readonly builder: IStrictNumberBuilder<T>;

    constructor(unsigned: IStrictNumberBuilder<T>) {
        this.zero = new unsigned().value as NumericUnderlyingType<T>;
        this.state = new unsigned().value as NumericUnderlyingType<T>;
        this.maxVal = unsigned.maxVal;
        this.builder = unsigned;
    }

    next(): T {
        if (this.state === this.maxVal) {
            this.state = this.zero;
            return new this.builder(this.state);
        }
        ++this.state;
        return new this.builder(this.state);
    }
}