/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SessionLogsResource } from '../models/SessionLogsResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LogsApi {

  /**
   * Get Session logs
   * @param sessionId
   * @param page Page number
   * @param limit Number of elements per page
   * @returns SessionLogsResource Session logs
   * @throws ApiError
   */
  public static getSessionLogs(
    sessionId: string,
    page: number = 1,
    limit: number = 25,
  ): CancelablePromise<SessionLogsResource> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/logs/session/{sessionId}',
      path: {
        'sessionId': sessionId,
      },
      query: {
        'page': page,
        'limit': limit,
      },
    });
  }

}
