import { Stack } from '@mui/material';
import { ServerResource, TypeGameLiftPayloadResource } from '../../../generated/api-client';
import { useState, useEffect } from 'react';
import type = ServerResource.type;

export type BackendServiceServerTypePayloadProps = {
  server: ServerResource;
}

export function ServerTypePayload({ server }: BackendServiceServerTypePayloadProps) {
  const [payload, setPayload] = useState<[string, string][] | null>(null);
  useEffect(() => {
    if (server.type === type.GAME_LIFT) {
      const payload = server.typePayload as TypeGameLiftPayloadResource;
      setPayload([
        ['FleetId', payload.fleetId],
      ]);
    } else {
      setPayload(null);
    }
  }, [server]);

  return <Stack>
    {payload ? payload.map(([key, value]) => (
      <div key={key}>
        {key}: <span style={{ color: 'grey' }}>{value}</span>
      </div>
    )) : null}
  </Stack>;
};
