export enum ErrorCode {
  /**
   * Client errors
   */
  SESSION_IS_CLOSED= 100,
  /**
   * Backend errors
   */
  CONFLICT = 1004,
  /**
   * Common
   */
  INVARIANT_VIOLATION = 2000,
  VERSION_MISMATCH = 2001,
  OPERATION_NOT_PERMITTED = 2002,
  UNEXPECTED_ERROR = 2003,
  UNSUPPORTED_FEATURE = 2005,
  BAD_REQUEST = 2006,
  /**
   * Server
   */
  SERVER_NOT_FOUND = 2100,
  SERVER_CLIENT_TIMEOUT = 2101,
  SERVER_NOT_FOUND_FOR_SESSION_PLACEMENT = 2102,
  /**
   * Session
   */
  SESSION_NOT_FOUND = 2150,
  SESSION_ROOM_NOT_FOUND = 2151,
  SESSION_USER_SESSION_NOT_FOUND = 2152,
}
