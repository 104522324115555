/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RoomResource = {
  $resourceType: RoomResource.$resourceType;
  /**
   * RoomId
   */
  roomId: string;
  /**
   * ServerRoomId
   */
  remoteRoomId: number;
  /**
   * UserSessionId
   */
  hostUserSessionId: string | null;
  /**
   * The Room behavior on host loss
   */
  hostLossMode: RoomResource.hostLossMode;
  /**
   * String value
   */
  tag: string | null;
  /**
   * OwnerId
   */
  createdBy: string | null;
  lastActivityAt: string;
};

export namespace RoomResource {

  export enum $resourceType {
    ROOM_RESOURCE = 'RoomResource',
  }

  /**
   * The Room behavior on host loss
   */
  export enum hostLossMode {
    CLOSE = 'close',
    TRANSFER = 'transfer',
    TRANSFER_ALLOW_EMPTY = 'transfer-allow-empty',
  }


}

