/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserSessionPayloadResource = {
  $resourceType: UserSessionPayloadResource.$resourceType;
  content: Record<string, any> | null;
  /**
   * UserSessionId
   */
  userSessionId: string;
};

export namespace UserSessionPayloadResource {

  export enum $resourceType {
    USER_SESSION_PAYLOAD_RESOURCE = 'UserSessionPayloadResource',
  }


}

