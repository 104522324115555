import { FilterBatchMessagesService } from './FilterBatchMessagesService';
import { FilterReceivedMessagesService } from './FilterReceivedMessagesService';
import { ConnectionId } from '../Id';
import { FBRServiceConfig, FilteringAndBatchingConfig } from '../client/client-config';
import { oByteStream } from '../Binary';

export enum FBRMessageType {
    batch,
    receive,
}

export interface FBRMessageFromWorker {
    type: FBRMessageType;
    messages: ArrayBuffer[];
}

export interface FBRMessageToInitWorker extends FBRServiceConfig {
    senderId: ArrayBuffer;
}

export interface FBRMessageToWorker {
    type: FBRMessageType;
    message: ArrayBuffer;
    timeOffset: number;
}

/**
 * Combines FilterBatchMessagesService and FilterReceivedMessagesService into one service.
 * Can be used inside worker, outside worker or as communication layer with worker instance of itself.
 * FBR because:
 *      1) Filter & Batch & Receive
 *      2) It is too cruel in my [hy from Ivan M.] opinion to write FilterBatchReceiveMessagesService,
 *      especially without underscores
 */
export class FBRMessagesService {
    constructor(senderId: ConnectionId, { worker, ...config }: FilteringAndBatchingConfig) {
        this.worker = worker;
        if (this.worker !== null) {
            this.worker.onmessage = this.onWorkerMessage.bind(this);
            const initMessage: FBRMessageToInitWorker = { senderId: (new oByteStream().write(senderId).bytes()), ...config };
            this.worker.postMessage(initMessage);
        } else {
            this.filterBatchService = new FilterBatchMessagesService(senderId,
                    config.maxMessageSize,
                    config.processingRate,
                    config.enableFiltering,
                    config.enableBatching);
            this.filterBatchService.onMessagesReady((messages) => this.onFilterBatchMsgReadyFn?.(messages));

            this.filterReceivedService = new FilterReceivedMessagesService(config.incomingProcessingRate, config.enableIncomingFiltering);
            this.filterReceivedService.onMessagesReady((messages) => this.onReceiveMsgReadyFn?.(messages));
        }
    }

    onFilterBatchMsgReady(callback: (messages: ArrayBuffer[]) => any) {
        this.onFilterBatchMsgReadyFn = callback;
    }

    onReceiveMsgReady(callback: (messages: ArrayBuffer[]) => any) {
        this.onReceiveMsgReadyFn = callback;
    }

    queueMessage(queueType: FBRMessageType, message: ArrayBuffer, timeOffset: number = 0) {
        if (this.worker !== null) {
            this.worker.postMessage({ type: queueType, message, timeOffset }, [message]);
        } else {
            switch (queueType) {
                case FBRMessageType.batch:
                    if (this.filterBatchService) {
                        this.filterBatchService.queueMessage(message, timeOffset);
                    }
                    break;
                case FBRMessageType.receive:
                    if (this.filterReceivedService) {
                        this.filterReceivedService.queueMessage(message);
                    }
                    break;
                default:
                    // noinspection UnnecessaryLocalVariableJS
                    const unreachable: never = queueType;
                    throw new Error(`Unhandled worker message type ${unreachable}`);
            }
        }
    }

    private readonly worker: Worker | null = null;
    private filterBatchService: FilterBatchMessagesService | null = null;
    private filterReceivedService: FilterReceivedMessagesService | null = null;
    private onFilterBatchMsgReadyFn: ((messages: ArrayBuffer[]) => any) | null = null;
    private onReceiveMsgReadyFn: ((messages: ArrayBuffer[]) => any) | null = null;

    private onWorkerMessage(ev: MessageEvent) {
        const workerMessage = ev.data as FBRMessageFromWorker;
        switch (workerMessage.type) {
            case FBRMessageType.batch:
                if (this.onFilterBatchMsgReadyFn) {
                    this.onFilterBatchMsgReadyFn(workerMessage.messages);
                }
                break;
            case FBRMessageType.receive:
                if (this.onReceiveMsgReadyFn) {
                    this.onReceiveMsgReadyFn(workerMessage.messages);
                }
                break;
            default:
                const unreachable: never = workerMessage.type;
                throw new Error(`Unhandled worker message type ${unreachable}`);
        }
    }
}
