import {createContext, ReactNode, useContext} from 'react';
import Snack, {useSnack} from '../Components/Common/Snack';
import {AlertColor} from '@mui/material';
import {ApiError} from '../generated/api-client';
import {AbstractSessionClientError} from "@own/pluto_client";

const noop = () => {
};

export type MsgContextProps = {
  showMessage: (message: string, type: AlertColor) => void;
  showApiError: (e: ApiError | AbstractSessionClientError, message?: string) => void;
};

const MsgContext = createContext<MsgContextProps>({
  showMessage: noop,
  showApiError: noop,
});

export const MsgProvider = ({children}: { children: ReactNode }) => {
  const {isSnackActive, snackMessage, snackType, openSnack, showApiError} = useSnack();

  const showMessage = (message: string, type: AlertColor) => {
    openSnack(message, type);
  };

  return (<MsgContext.Provider
    value={{
      showMessage,
      showApiError,
    }}
  >
    {children}
    <Snack
      isActive={isSnackActive}
      message={snackMessage}
      type={snackType}
    />
  </MsgContext.Provider>);
};

export const useMsg = () => useContext(MsgContext);
