import { MedianShift } from './NoSTD/Median_shift';
import { TimeSyncConfig } from './client/client-config';
import { sleep } from './Time';

export interface TimeInfo {
    offset: number;
    rtt: number;
}

export interface PingTimings {
    clientSentTimestamp: number;
    serverReceivedTimestamp: number;
    serverSentTimestamp: number;
    clientReceivedTimestamp: number;
}

export class TimeSyncService {
    constructor(timeSyncConfig: TimeSyncConfig, getTimings: () => Promise<PingTimings>) {
        this.timeSyncConfig = timeSyncConfig;
        this.getTimings = getTimings;
    }

    close() {
        this.closed = true;
    }

    getTimeInfo(): TimeInfo {
        return this.timeInfo;
    }

    onTimeInfoUpdated(listener: (timeInfo: TimeInfo) => any) {
        this.onTimeInfoUpdatedHandler = listener;
    }

    async run() {
        return this.start();
    }

    private readonly getTimings: () => Promise<PingTimings>;
    private timeSyncConfig: TimeSyncConfig;
    private closed: boolean = false;
    private timeInfo: TimeInfo = { offset: 0.0, rtt: 0.0 };
    private onTimeInfoUpdatedHandler: ((timeInfo: TimeInfo) => any) | null = null;

    // https://en.wikipedia.org/wiki/NetworkTimeProtocol#ClockSynchronizationAlgorithm
    private async start() {
        const offsetSync = new MedianShift(this.timeSyncConfig.windowSize);
        const rttSync = new MedianShift(this.timeSyncConfig.windowSize);

        while (!closed) {
            const timings = await this.getTimings();

            const t0 = timings.clientSentTimestamp;
            const t1 = timings.serverReceivedTimestamp;
            const t2 = timings.serverSentTimestamp;
            const t3 = timings.clientReceivedTimestamp;

            const offset = 0.5 * ((t1 - t0) + (t2 - t3));
            const rtt = (t3 - t0) - (t2 - t1);

            offsetSync.update(offset);
            rttSync.update(rtt);

            this.timeInfo.offset = offsetSync.calcValueOr(this.timeInfo.offset);
            this.timeInfo.rtt = rttSync.calcValueOr(this.timeInfo.rtt);

            if (this.onTimeInfoUpdatedHandler) {
                this.onTimeInfoUpdatedHandler(this.timeInfo);
            }

            await sleep(this.timeSyncConfig.interval);
        }
    }
}
