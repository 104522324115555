/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserRoleResource = {
  $resourceType: UserRoleResource.$resourceType;
  /**
   * Role id
   */
  roleId: UserRoleResource.roleId;
};

export namespace UserRoleResource {

  export enum $resourceType {
    USER_ROLE_RESOURCE = 'UserRoleResource',
  }

  /**
   * Role id
   */
  export enum roleId {
    USER = 'user',
  }


}

