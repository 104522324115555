import React from "react";
import { Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LinkTabProps } from "./LinkTab";

export function samePageLinkNavigation(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

export interface NavProps {
  items: LinkTabProps[];
  current: number;
}

export function Nav({ items, current }: NavProps) {
  const navigate = useNavigate()

  return <Tabs value={current}>
    {items.map((item, index) => <Tab
      key={item.href}
      label={item.label}
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (samePageLinkNavigation(event)) {
          event.preventDefault();
        }
        navigate(item.href);
      }}
    />)}
  </Tabs>;
}