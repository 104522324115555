/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserSessionPayloadResource } from './UserSessionPayloadResource';

export type SessionPayloadResource = {
  $resourceType: SessionPayloadResource.$resourceType;
  content: Record<string, any> | null;
  userSessionPayloads: Array<UserSessionPayloadResource>;
};

export namespace SessionPayloadResource {

  export enum $resourceType {
    SESSION_PAYLOAD_RESOURCE = 'SessionPayloadResource',
  }


}

