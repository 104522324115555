/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BalancerAwsPayloadDto } from './BalancerAwsPayloadDto';

export type BalancerConfigDto = {
  /**
   * BalancingType
   */
  type: BalancerConfigDto.type;
  /**
   * Type specific balancer payload. Pass an empty object for the BalancerNonePayloadDto
   */
  payload: BalancerAwsPayloadDto;
};

export namespace BalancerConfigDto {

  /**
   * BalancingType
   */
  export enum type {
    NONE = 'none',
    AWS = 'aws',
  }


}

