import {
  SessionResource,
  UserSessionResource, ServerResource, SessionApi,
} from '../../../generated/api-client';
import { Fragment, useState, useEffect } from 'react';
import { Stack, Typography, Box, Chip, Tooltip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useBackendServiceContext } from '../../../contexts/backend-service-context';
import { useMsg } from '../../../contexts/msg-context';
import { UserSession } from './UserSession';
import EditIcon from '@mui/icons-material/Edit';

export type BackendServiceSessionUserSessionsProps = {
  session: SessionResource;
  server: ServerResource;
  onReload: () => void;
}

export function UserSessions({
                               session,
                               server,
                               onReload
                             }: BackendServiceSessionUserSessionsProps) {
  const { currentSessionClient, ownerId } = useBackendServiceContext();
  const { showMessage, showApiError } = useMsg();
  const [stat, setStat] = useState<{ members: number; }>({ members: 0 });
  const [maxUserSessions, setMaxUserSessions] = useState<number | null>(null);
  const [canCreateUserSession, setCanCreateUserSession] = useState(false);
  const [userSessions, setUserSessions] = useState<UserSessionResource[]>([]);

  useEffect(() => {
    setCanCreateUserSession(!!currentSessionClient);
  }, [currentSessionClient, session.sessionId, setCanCreateUserSession]);

  useEffect(() => {
    const x = session.userSessions;
    x.sort((a, b) => a.connectionId > b.connectionId ? 1 : -1);
    setUserSessions(x);
  }, [session.userSessions, setUserSessions]);


  useEffect(() => {
    setStat({
      members: session.userSessions.length,
    });
    setMaxUserSessions(session.config.maxUserSessions);
  }, [session, setStat, setMaxUserSessions]);

  const createUserSession = () => {
    if (!currentSessionClient) {
      showMessage(`No SessionClient found to perform this request`, 'error');
      return;
    }
    if (!ownerId) {
      showMessage(`Empty ownerId`, 'error');
      return;
    }
    currentSessionClient.sessionClient
      .createUserSession(currentSessionClient.connectionId, ownerId)
      .then((data) => {
        if (data.isLeft()) {
          showApiError(data.value);
        } else {
          showMessage('UserSession created', 'success');
        }
      })
      .catch(showApiError);
  };

  const onReserve = () => {
    const value = prompt('Enter the new value');
    if (!value) {
      return;
    }
    const reservedUserSessions = parseInt(value);
    if (reservedUserSessions < 1 || isNaN(reservedUserSessions)) {
      return;
    }
    SessionApi.updateSession(session.sessionId, { reservedUserSessions })
      .then(() => showMessage('Updated', 'success'))
      .catch(showApiError)
      .finally(onReload);
  };

  return <Fragment>
    <Stack flex={2}>
      <Stack margin={2}>
        <Stack
          direction="row"
          alignItems="center"
        >
          <Typography variant="subtitle2">
            User sessions
          </Typography>
          <Chip
            size="small"
            label={`${stat.members}${maxUserSessions ? `/${maxUserSessions}` : ``}`}
          />
          <div>
            Reserved: {session.config.reservedUserSessions}
          </div>
          <IconButton
            size="small"
            onClick={onReserve}
          >
            <EditIcon fontSize="inherit"/>
          </IconButton>
          <Box flex={1}>
          </Box>
          <Box>
            <Tooltip title="Create a new UserSession">
              <span>
                  <IconButton
                    disabled={!canCreateUserSession}
                    size="small"
                    onClick={createUserSession}
                  >
                      <AddIcon fontSize="inherit"/>
                  </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Stack>
        <Stack>
          {userSessions.map((x) => (
            <UserSession
              userSession={x}
              session={session}
              server={server}
              key={x.userSessionId}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  </Fragment>;
}
