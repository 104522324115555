/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ServersConfigResource = {
  $resourceType: ServersConfigResource.$resourceType;
  /**
   * Percent value
   */
  overallActiveUserSessionsThresholdBottom: number;
  /**
   * Percent value
   */
  overallActiveUserSessionsThresholdUp: number;
  /**
   * Int value > 0
   */
  closedSessionLifetimeMinutes: number;
  /**
   * Int value > 0
   */
  emptySessionLifetimeMinutes: number;
  /**
   * Int value > 0
   */
  emptyRoomLifetimeSeconds: number;
  /**
   * Int value > 0
   */
  userSessionExpirationTimeSeconds: number;
  extendedLoggingEnabled: boolean;
};

export namespace ServersConfigResource {

  export enum $resourceType {
    SERVERS_CONFIG_RESOURCE = 'ServersConfigResource',
  }


}

