/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BalancerConfigResource } from './BalancerConfigResource';
import type { TypeGameLiftPayloadResource } from './TypeGameLiftPayloadResource';
import type { TypeOwnPayloadResource } from './TypeOwnPayloadResource';

export type ServerResource = {
  $resourceType: ServerResource.$resourceType;
  /**
   * ServerId
   */
  id: string;
  /**
   * ServerStatus
   */
  status: ServerResource.status;
  /**
   * ServerShutdownStatus
   */
  shutdownStatus: ServerResource.shutdownStatus;
  /**
   * ServerName
   */
  name: string | null;
  /**
   * ServerType
   */
  type: ServerResource.type;
  typePayload: (TypeOwnPayloadResource | TypeGameLiftPayloadResource);
  internalWsEndpoint: string;
  publicWsEndpoint: string;
  /**
   * Int value > 0
   */
  maxSessions: number;
  /**
   * Int value > 0
   */
  maxUserSessions: number;
  /**
   * Int value >= 0
   */
  activeSessionsCount: number;
  /**
   * Int value >= 0
   */
  freeSessionSlotsCount: number;
  balancerConfig: BalancerConfigResource;
  createdAt: string;
};

export namespace ServerResource {

  export enum $resourceType {
    SERVER_RESOURCE = 'ServerResource',
  }

  /**
   * ServerStatus
   */
  export enum status {
    DISCONNECTED = 'disconnected',
    CONNECTING = 'connecting',
    CONNECTED = 'connected',
    ERROR = 'error',
  }

  /**
   * ServerShutdownStatus
   */
  export enum shutdownStatus {
    NONE = 'none',
    SCHEDULED_FOR_SHUTDOWN = 'scheduled-for-shutdown',
    SHUTDOWN = 'shutdown',
  }

  /**
   * ServerType
   */
  export enum type {
    OWN = 'own',
    GAME_LIFT = 'game-lift',
  }


}

