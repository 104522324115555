import { validationSchema } from './validation-schema';

console.log(process.env)

class Config {
  readonly backendServiceUrl: string;

  constructor(env: Record<string, string>) {
    this.backendServiceUrl = env.REACT_APP_BACKEND_SERVICE_URL;
  }
}

const env = {
  REACT_APP_BACKEND_SERVICE_URL: process.env.REACT_APP_BACKEND_SERVICE_URL,
};

export const config = new Config(env as Record<string, string>);

const result = validationSchema.validate(env, { allowUnknown: true });
if (result.error) {
  throw result.error;
}
