import { Box, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from "react";
import { OpenAPI } from "../../generated/api-client";

export function Auth() {
  const [token, setToken] = useState(localStorage.getItem('token') ?? '');

  useEffect(() => {
    OpenAPI.TOKEN = token;
    localStorage.setItem('token', token);
  }, [token]);

  return <Box>
    <TextField
      label="Token"
      variant="standard"
      value={token}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setToken(event.target.value);
      }}
    />
  </Box>;
}
