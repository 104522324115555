import { FormikProps } from 'formik';
import * as React from 'react';
import { ReactNode } from 'react';
import { FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';

export function formHelper<Fields>(formik: FormikProps<Fields>) {
  function errorText(name: keyof Fields): ReactNode {
    return formik.touched[name] && Boolean(formik.errors[name]) ? (
      <FormHelperText error>
        {(formik.errors[name] || '').toString()}
      </FormHelperText>
    ) : null;
  }

  function control(label: string | null, name: keyof Fields, input: ReactNode) {
    return (
      <FormControl
        variant="standard"
        sx={{ mt: 1, mb: 2 }}
        fullWidth
      >
        {
          label
            ? <InputLabel id={`${name.toString()}-label`}>{label}</InputLabel>
            : null
        }
        {input}
        {errorText(name)}
      </FormControl>
    );
  }

  function field(name: keyof Fields, error = true) {
    return {
      name: name.toString(),
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: formik.values[name],
      error: error ? Boolean(formik.touched[name] && Boolean(formik.errors[name])) : undefined,
    };
  }

  function numberControl(name: keyof Fields, label: string) {
    return control(
      null,
      name,
      <TextField
        fullWidth
        label={label}
        variant="standard"
        margin="dense"
        type="number"
        {...field(name)} />,
    );
  }

  function textControl(name: keyof Fields, label: string) {
    return control(
      null,
      name,
      <TextField
        fullWidth
        label={label}
        variant="standard"
        margin="dense"
        {...field(name)} />,
    );
  }

  function textareaControl(name: keyof Fields, label: string) {
    return control(
      null,
      name,
      <TextField
        fullWidth
        multiline
        label={label}
        variant="standard"
        margin="dense"
        {...field(name)} />,
    );
  }

  return {
    errorText,
    numberControl,
    textareaControl,
    textControl,
    field,
    control,
  };
}
