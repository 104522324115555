/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GroupServerConfigResource } from './GroupServerConfigResource';

export type GroupResource = {
  $resourceType: GroupResource.$resourceType;
  /**
   * ServerId
   */
  id: string;
  /**
   * String value
   */
  code: string;
  /**
   * GroupType
   */
  type: GroupResource.type;
  serverConfig: GroupServerConfigResource;
  createdAt: string;
};

export namespace GroupResource {

  export enum $resourceType {
    GROUP_RESOURCE = 'GroupResource',
  }

  /**
   * GroupType
   */
  export enum type {
    AWS = 'aws',
  }


}

