/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SetGroupCapacityCommand = {
  /**
   * Int value > 0
   */
  capacity: number;
  mode?: SetGroupCapacityCommand.mode;
};

export namespace SetGroupCapacityCommand {

  export enum mode {
    SET = 'set',
    INC = 'inc',
    DEC = 'dec',
  }


}

