import {adequateDefaultNumberCmp} from "./index";

export class MedianShift {
    private window: number[];
    private windowsSize: number;

    public constructor(windowSize: number) {
        this.windowsSize = windowSize;
        this.window = [];
    }
    update(value: number) {
        if (this.window.length === this.windowsSize) {
            this.window.splice(0, 1);
        }
        this.window.push(value);
    }
    calcValue() {
        if (this.window.length === 0) {
            throw new Error("No values provided");
        }
        // Really??? It sorts numbers in lexicographical order by default? What even is this shi...
        const values = [...this.window].sort(adequateDefaultNumberCmp);
        const middle = Math.floor(values.length / 2);
        if (values.length % 2 === 0) {
            return 0.5 * (values[middle - 1] + values[middle]);
        }
        return values[middle];
    }
    calcValueOr(defaultValue: number) {
        try {
            return this.calcValue();
        } catch {
            return defaultValue;
        }
    }
}
