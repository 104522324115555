/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SessionTypeGameLiftPayloadResource = {
  $resourceType: SessionTypeGameLiftPayloadResource.$resourceType;
  gameSessionId: string;
};

export namespace SessionTypeGameLiftPayloadResource {

  export enum $resourceType {
    SESSION_TYPE_GAME_LIFT_PAYLOAD_RESOURCE = 'SessionTypeGameLiftPayloadResource',
  }


}

