/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateRoomCommand = {
  /**
   * Slug
   */
  tag?: string | null;
  /**
   * The Room behavior on host loss
   */
  hostLossMode?: CreateRoomCommand.hostLossMode;
};

export namespace CreateRoomCommand {

  /**
   * The Room behavior on host loss
   */
  export enum hostLossMode {
    CLOSE = 'close',
    TRANSFER = 'transfer',
    TRANSFER_ALLOW_EMPTY = 'transfer-allow-empty',
  }


}

