import {
  Box,
  Card, CardContent, Slider,
  Stack, Tooltip, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ServersConfigResource,
  ServersStateResource,
} from "../../../generated/api-client";

export type ServersInfoProps = {
  serversState: ServersStateResource;
  config: ServersConfigResource;
};

export function ServersInfo({ serversState, config }: ServersInfoProps) {
  const [marks, setMarks] = useState<{ value: number; label: string }[]>([]);
  const [color, setColor] = useState<'success' | 'error'>('success');

  useEffect(() => {
    setMarks([
      {
        value: config.overallActiveUserSessionsThresholdBottom,
        label: `${config.overallActiveUserSessionsThresholdBottom}%`,
      },
      {
        value: config.overallActiveUserSessionsThresholdUp,
        label: `${config.overallActiveUserSessionsThresholdUp}%`,
      },
    ]);
    const isInSafeArea = serversState.currentUserSessionsUsagePercent > config.overallActiveUserSessionsThresholdBottom &&
      serversState.currentUserSessionsUsagePercent < config.overallActiveUserSessionsThresholdUp;
    setColor(isInSafeArea ? 'success' : 'error');
  }, [config, serversState]);

  return <Card>
    <CardContent>
      <Stack
        direction="row"
        alignItems="center"
        fontSize="0.85em"
      >
        <Box flex={1}>
          <Typography variant="body1">
            Overall servers state
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ userSelect: 'none' }}
          >
            <div>
              Sessions: {serversState.activeSessionsCount}
            </div>
            <div>
              Active UserSessions: {serversState.activeUserSessionsCount}
            </div>
            <Tooltip title="Sum of max(activeUserSessionsCount, reservedUserSessionsCount) per each Session">
              <div>
                Taken UserSessions: {serversState.takenUserSessionsCount}
              </div>
            </Tooltip>
          </Stack>
        </Box>
        <Box flex={1}>
          <br/>
          <Slider
            color={color as any}
            size="small"
            value={parseFloat(serversState.currentUserSessionsUsagePercent.toFixed(2))}
            marks={marks}
            valueLabelDisplay="auto"
          />
        </Box>
      </Stack>
    </CardContent>
  </Card>;
}
