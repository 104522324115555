import React, { Fragment, useState } from 'react';
import { IconButton, Stack, Tooltip } from "@mui/material";
import { BalancerApi, GroupCardResource, GroupResource } from "../../../generated/api-client";
import { useMsg } from "../../../contexts/msg-context";
import Gray from "../../Common/Gray";
import CachedIcon from "@mui/icons-material/Cached";

export type GroupInfoProps = {
  group: GroupResource;
};

export function GroupInfo({ group }: GroupInfoProps) {
  const [card, setCard] = useState<GroupCardResource | null>(null);
  const { showMessage, showApiError } = useMsg()

  const load = () => {
    BalancerApi.showGroup(group.id).then((x) => {
      showMessage('Loaded', 'success');
      setCard(x);
    }).catch(showApiError);
  };

  return <Stack
    mt={2}
    direction="row"
    alignItems="center"
    fontSize="0.85em"
  >
    <Tooltip title="Load the group info">
      <IconButton
        size="small"
        onClick={load}
      >
        <CachedIcon fontSize="inherit"/>
      </IconButton>
    </Tooltip>

    {card ? <Fragment>
      {card.info.status ? <div>
        Status: <Gray>{card.info.status}</Gray>
      </div> : null}
      <div>
        Max size: <Gray>{card.info.maxSize}</Gray>
      </div>
      <div>
        Min size: <Gray>{card.info.minSize}</Gray>
      </div>
      <div>
        Desired capacity: <Gray>{card.info.desiredCapacity}</Gray>
      </div>
    </Fragment> : <Gray><i>Group info is not loaded</i></Gray>}
  </Stack>;
}
