import {
  Button,
  Card,
  CardActions,
  CardContent,
} from '@mui/material';
import {
  ServerResource
} from '../../../generated/api-client';
import React from 'react';
import { ServerInfo } from "./ServerInfo";

export type ServerListItemProps = {
  server: ServerResource;
  onServerOpen: (server: ServerResource) => void;
}

export function ServerListItem({ server, onServerOpen }: ServerListItemProps) {
  return (
    <Card onDoubleClick={() => onServerOpen(server)}>
      <CardContent>
        <ServerInfo
          server={server}
        />
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          size="small"
          onClick={() => onServerOpen(server)}
        >
          Open
        </Button>
      </CardActions>
    </Card>
  );
}
