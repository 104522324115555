import { ErrorCode } from '../enums';
import { AbstractSessionClientError } from './abstract-session-client.error';

export class UnexpectedError extends AbstractSessionClientError {
  readonly $isUnexpectedError = true;
  static code: ErrorCode = ErrorCode.UNEXPECTED_ERROR;

  constructor(message?: string) {
    super(message ?? 'Unexpected error');
  }

  getCode() {
    return UnexpectedError.code;
  }
}