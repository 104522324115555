import { createContext, ReactNode, useContext, useState } from 'react';
import { ServerResource, SessionResource } from '../generated/api-client';

export type ServersContextProps = {
  selectedServer: ServerResource | null;
  setSelectedServer: (server: ServerResource | null) => void;
  selectedSession: SessionResource | null;
  setSelectedSession: (server: SessionResource | null) => void;
};

const ServersContext = createContext<ServersContextProps>({
  selectedServer: null,
  setSelectedServer: (): any => {
  },
  selectedSession: null,
  setSelectedSession: (): any => {
  },
});

export const ServersContextProvider = ({ children }: { children: ReactNode }) => {
  const [selectedServer, setSelectedServer] = useState<ServerResource | null>(null);
  const [selectedSession, setSelectedSession] = useState<SessionResource | null>(null);

  return (<ServersContext.Provider
    value={{
      selectedServer,
      setSelectedServer,
      selectedSession,
      setSelectedSession,
    }}
  >
    {children}
  </ServersContext.Provider>);
};

export const useServersContext = () => useContext(ServersContext);
