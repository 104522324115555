/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultApi {

  /**
   * @returns any
   * @throws ApiError
   */
  public static version(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/version',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static ping(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/ping',
    });
  }

}
