import {iByteStream, oByteStream} from "../Binary";
import {BatchMessageByteLengthType, RequestHeader, ResponseHeader} from "../Headers";
import {Route} from "../Route";
import {HeaderMessage} from "./HeaderMessage";
import {ConnectionId} from "../Id";

// Possibly better implementation requires fast array masking (marking that element should be skipped).
// Why it is better? It will possibly allow better messages compression by gluing non-consecutive messages.
// Why possibly? Because earlier statement is true iff messages size is rather non-uniform, which may or may not be true.
// Also, it requires sorting by message size beforehand and chunking itself is at least O(n log n),
// so it's overall complexity is O(n log n) vs O(n) of the current implementation.
// Why not test both?
// In c++ I would use boost::dynamic_bitset for this.
// While it is possible, with a lot of time spent, to implement similarish data structure in JS,
// it is unknown how inefficient it would be in JS to allocate a new array of booleans
// (or copy current array and add boolean field for all elements), hence requiring at least one more benchmarking.
// I am already fucking tired, so, even if it kinda bothers me (an open gestalt and stuff),
// at least for now I'll keep this simple implementation.
// Nobody reads this, so I can safely say that I benchmarked 30 different implementations of unique, lol.
export function batchMessages(messages: HeaderMessage[],
                              maxMessageSize: number,
                              senderId: ConnectionId,
                              timeOffset: number) {
    const batches: ArrayBuffer[] = [];
    const obs = new oByteStream(maxMessageSize);
    let messageFirst = 0;
    while (messageFirst < messages.length) {
        let messageSize = RequestHeader.byteLen() + BatchMessageByteLengthType.byteLen() + messages[messageFirst].data.byteLength;
        let messageLast = messageFirst + 1;
        while (messageLast < messages.length) {
            const newMessageSize = messageSize + BatchMessageByteLengthType.byteLen() + messages[messageLast].data.byteLength;
            if (newMessageSize > maxMessageSize) {
                break;
            }
            messageSize = newMessageSize;
            ++messageLast;
        }

        // if we can't glue current message with other messages without crossing message size limit
        if ((messageLast - messageFirst) === 1) {
            batches.push(messages[messageFirst++].data);
            continue;
        }

        const header = new RequestHeader(Route.batch, senderId, {timeOffset});
        obs.write(header);
        for (; messageFirst < messageLast; ++messageFirst) {
            const message = messages[messageFirst]; // hopefully won't copy...
            obs.write(new BatchMessageByteLengthType(message.data.byteLength));
            obs.writeArrayBuffer(message.data);
        }
        batches.push(obs.bytes());
        obs.clear();
    }
    return batches;
}

export function unbatchMessages(message: ArrayBuffer, callback: (message: ArrayBuffer) => any) {
    const ibs = new iByteStream(message);
    const header = ibs.read(ResponseHeader);
    if (header.route !== Route.batch) {
        callback(message);
        return;
    }
    while (!ibs.empty()) {
        const msgLen = ibs.read(BatchMessageByteLengthType).value;
        const msg = ibs.readArrayBuffer(msgLen);
        callback(msg);
    }
}