import { Chip, Tooltip } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

export type RoomIdChipProps = {
  remoteRoomId: number;
  small?: boolean;
}

const RoomIdChip: FC<RoomIdChipProps> = ({ remoteRoomId, small }: RoomIdChipProps) => {
  return <Tooltip title="Room id">
    {small ? <span
      style={{ userSelect: 'none', fontWeight: 'bold', color: '#90caf9' }}
    >
            {remoteRoomId}
        </span> : <Chip
      label={remoteRoomId}
      style={{ userSelect: 'none' }}
      color="primary"
      size="small"
    />}
  </Tooltip>;
};

export default React.memo(RoomIdChip);
