export type BinaryPredicate<T> = (a: T, b: T) => boolean;

export function adequateDefaultNumberCmp(a: number, b: number): number {
    return a - b;
}

export function defaultEqual<T>(a: T, b: T) {
    return a === b;
}

export function unique<T>(sortedArray: T[], p: BinaryPredicate<T> = defaultEqual<T>): T[] {
    return sortedArray.reduce((prev: T[], curr: T) => {
        if (prev.length !== 0 && p(prev.at(-1) as T, curr)) {
            return prev;
        }
        prev.push(curr);
        return prev;
    }, []);
}
