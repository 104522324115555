interface IBasicLogger {
    error(message: any): any;
    warn(message: any): any;
    info(message: any): any;
    debug(message: any): any;
}

class NoneLogger implements IBasicLogger {
    error(message: any) { return this; }
    warn(message: any) { return this; }
    info(message: any) { return this; }
    debug(message: any) { return this; }
}


class ConsoleLogger implements IBasicLogger {
    debug(message: any): any {
        console.debug(message);
    }

    error(message: any): any {
        console.error(message);
    }

    info(message: any): any {
        console.info(message);
    }

    warn(message: any): any {
        console.warn(message);
    }

}

let loggerInstance: IBasicLogger = new ConsoleLogger();

export function setLogger(logger: IBasicLogger) {
    loggerInstance = logger;
}

export function logger(): IBasicLogger {
    return loggerInstance;
}
