/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RoomResource } from './RoomResource';
import type { SessionConfigResource } from './SessionConfigResource';
import type { SessionTypeGameLiftPayloadResource } from './SessionTypeGameLiftPayloadResource';
import type { SessionTypeOwnPayloadResource } from './SessionTypeOwnPayloadResource';
import type { UserSessionResource } from './UserSessionResource';

export type SessionResource = {
  $resourceType: SessionResource.$resourceType;
  /**
   * SessionId
   */
  sessionId: string;
  /**
   * ServerId
   */
  serverId: string;
  /**
   * OwnerId
   */
  createdBy: string;
  /**
   * UserSessionId
   */
  hostUserSessionId: string;
  /**
   * SessionId
   */
  status: SessionResource.status;
  /**
   * SessionType
   */
  type: SessionResource.type;
  createdAt: string;
  emptyFrom: string | null;
  closedAt: string | null;
  typePayload: (SessionTypeGameLiftPayloadResource | SessionTypeOwnPayloadResource);
  config: SessionConfigResource;
  rooms: Array<RoomResource>;
  userSessions: Array<UserSessionResource>;
};

export namespace SessionResource {

  export enum $resourceType {
    SESSION_RESOURCE = 'SessionResource',
  }

  /**
   * SessionId
   */
  export enum status {
    CREATED = 'created',
    CLOSED = 'closed',
  }

  /**
   * SessionType
   */
  export enum type {
    OWN = 'own',
    GAME_LIFT = 'game-lift',
  }


}

