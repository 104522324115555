import { Stack } from '@mui/material';
import { useBackendServiceContext, TheClient } from '../../contexts/backend-service-context';
import { ClientCard } from './ClientCard';

export function Clients() {
  const { clients } = useBackendServiceContext();

  return <Stack>
    {clients.map((x: TheClient) => <div key={`${x.connectionId.value}-${x.sessionId}`}>
      <ClientCard client={x} />
    </div>)}
  </Stack>;
};
