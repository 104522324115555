import { Chip } from '@mui/material';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { SessionResource } from "../../../generated/api-client";

export type SessionStatusProps = {
  status: SessionResource.status;
}

const SessionStatus: FC<SessionStatusProps> = ({ status }: SessionStatusProps) => {
  const [color, setColor] = useState<any>('primary');

  useEffect(() => {
    setColor(status === 'closed' ? 'error' : 'success');
  }, [status]);

  return <Chip
    label={status}
    style={{ userSelect: 'none' }}
    color={color}
    size="small"
  />;
};

export default React.memo(SessionStatus);
