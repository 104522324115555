import {IByteDeserializable, IByteSerializable, IHasByteLen} from "./IByteSerializable";
import {Uint64} from "./StrictNumbers";
import {BinaryStreamError} from "../PlutoError";

export class iByteStream {
     readonly buffer: ArrayBuffer;
     readonly dataView: DataView;
     pos: number;

    constructor(buffer: ArrayBuffer, pos = 0) {
        this.buffer = buffer;
        this.dataView = new DataView(this.buffer);
        this.pos = pos;
    }

    empty(): boolean { return this.pos === this.buffer.byteLength; }

    currentNumberView() { return {dv: this.dataView, offset: this.pos}; }

    read<T>(type: IByteDeserializable<T>) {
        const expectedSize = type.byteLen();
        this.checkBoundaries(expectedSize);
        const posBefore = this.pos;
        const obj = type.readBytes(this);
        this.pos = posBefore + expectedSize;
        return obj;
    }

    readString(): string {
        this.checkBoundaries(Uint64.byteLen());
        const strSize = Number(this.read(Uint64).value);

        this.checkBoundaries(strSize);
        const strSpan = new Uint8Array(this.buffer, this.pos, strSize);
        const decoder = new TextDecoder();
        const value = decoder.decode(strSpan);

        this.pos += value.length;
        return value;
    }

    readArray<T>(type: IByteDeserializable<T>): T[] {
        this.checkBoundaries(Uint64.byteLen());
        const n = Number(this.read(Uint64).value);
        const result: T[] = [];
        for (let i = 0; i < n; i++) {
            result.push(this.read(type));
        }
        return result;
    }

    readArrayBuffer(byteLen: number): ArrayBuffer {
        this.checkBoundaries(byteLen);
        const startPos = this.pos;
        this.pos += byteLen;
        return this.buffer.slice(startPos, this.pos);
    }

    rest(): ArrayBuffer {
        return this.buffer.slice(this.pos);
    }

    private checkBoundaries(requiredByteLength: number) {
        if (this.buffer.byteLength - this.pos < requiredByteLength) {
            throw new BinaryStreamError("iByteStream: trying to read value out of bounds");
        }
    }
}

export class oByteStream {
    private buffer: ArrayBuffer;
    private dataView: DataView;
    private size_: number;

    constructor(capacity: number = 0) {
        this.buffer = new ArrayBuffer(capacity);
        this.dataView = new DataView(this.buffer);
        this.size_ = 0;
    }

    currentNumberView() { return {dv: this.dataView, offset: this.size_}; }

    size() { return this.size_; }

    clear() {
        this.size_ = 0;
    }

    write<T extends IByteSerializable>(obj: T): oByteStream {
        const requiredSize = (<IHasByteLen>obj.constructor).byteLen();
        this.resize(requiredSize);
        const sizeBefore = this.size_;
        obj.writeBytes(this);
        this.size_ = sizeBefore + requiredSize;
        return this;
    }

    writeString(str: string): oByteStream {
        const encoder = new TextEncoder();
        const strSpan = encoder.encode(str);
        const spanLen = new Uint64(BigInt(strSpan.byteLength));

        this.resize(Uint64.byteLen() + strSpan.byteLength);
        this.write(spanLen);
        const tgtStrSpan = new Uint8Array(this.buffer, this.size_, strSpan.byteLength);
        tgtStrSpan.set(strSpan);
        this.size_ += strSpan.byteLength;

        return this;
    }

    writeArray<T extends IByteSerializable>(arr: Array<T>): oByteStream {
        this.write(new Uint64(BigInt(arr.length)));
        for (const el of arr) {
            this.write(el);
        }
        return this;
    }

    writeArrayBuffer(buff: ArrayBuffer): oByteStream {
        this.resize(buff.byteLength);
        const srcArr = new Uint8Array(buff)
        const dstArr = new Uint8Array(this.buffer, this.size_, buff.byteLength);
        dstArr.set(srcArr);
        this.size_ += buff.byteLength;
        return this;
    }

    bytes(): ArrayBuffer {
        return this.buffer.slice(0, this.size_);
    }

    private resize(requiredSize: number) {
        const fullSize = this.size_ + requiredSize;

        if (fullSize <= this.buffer.byteLength) { return; }

        const buffer = new ArrayBuffer(2 * fullSize);
        (new Uint8Array(buffer)).set(new Uint8Array(this.buffer));
        this.buffer = buffer;
        this.dataView = new DataView(this.buffer);
    }
}