import { ErrorCode } from '../enums';
import { AbstractSessionClientError } from './abstract-session-client.error';

export class ServerClientTimeoutError extends AbstractSessionClientError {
  readonly $isServerClientTimeoutError = true;
  readonly code: ErrorCode = ErrorCode.SERVER_CLIENT_TIMEOUT;

  constructor(message?: string) {
    super(message ?? 'SyncServer client timed out');
  }

  getCode() {
    return ServerClientTimeoutError.code;
  }
}