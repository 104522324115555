import { ErrorCode } from '../enums';
import { AbstractSessionClientError } from './abstract-session-client.error';

export class UserSessionNotFoundError extends AbstractSessionClientError {
  readonly $isUserSessionNotFoundError = true;
  static code: ErrorCode = ErrorCode.SESSION_USER_SESSION_NOT_FOUND;

  constructor(message?: string) {
    super(message ?? 'User session not found');
  }

  getCode() {
    return UserSessionNotFoundError.code;
  }
}